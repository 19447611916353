import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import Downshift from 'downshift';
import Highlighter from 'react-highlight-words';
import { isString } from 'lodash-es';
import { withRouter } from 'react-router-dom';
import { createUrl } from '../../core/helpers';
import { history } from '../../core/services';
import { Http, Loading } from '../../core/components';
import { createCommunityUrl } from '../helpers';
import { INDEPENDENT_LIVING, ASSISTED_LIVING, MEMORY_CARE, COMMUNITY } from '../constants';
import {
  SearchGroup,
  SearchArrow,
  SearchInput,
  SearchButton,
  SearchButtonText,
  SearchButtonIcon,
  SearchSuggestions,
  SearchSuggestion,
  SearchSuggestionHighlight,
} from './styled';

const ARROW_POSITION_BY_SERVICE_TYPES = {
  [INDEPENDENT_LIVING.id]: '1/3',
  [ASSISTED_LIVING.id]: '2/3',
  [MEMORY_CARE.id]: '3/3',
};

class Search extends PureComponent {
  state = { inputValue: '', isLoading: false };

  onStartLoading = () => {
    this.setState({ isLoading: true });
  };

  onEndLoading = () => {
    this.setState({ isLoading: false });
  };

  onChange = item => {
    const { serviceCategory, embed, forOwner, location } = this.props;
    const { type, id, name } = item;

    if (item.type === COMMUNITY) {
      history.push(createCommunityUrl(id, name, forOwner));
      return;
    }

    const url = embed
      ? createUrl(location.pathname, { term: name, type })
      : createUrl('/search', { term: name, category: serviceCategory, type });

    history.push(url);
  };

  onInputValueChange = inputValue => {
    this.setState({ inputValue });
  };

  onSubmit = () => {
    const { inputValue } = this.state;
    const { serviceCategory, embed, location } = this.props;

    const url = embed
      ? createUrl(location.pathname, { term: inputValue })
      : createUrl('/search', { term: inputValue, category: serviceCategory });

    history.push(url);
  };

  itemToString = item => {
    if (isString(item)) return item;
    return item ? item.name : '';
  };

  renderHighlight = ({ children }) => <SearchSuggestionHighlight>{children}</SearchSuggestionHighlight>;

  render() {
    const { inputValue, isLoading } = this.state;
    const { showArrow, serviceCategory } = this.props;

    return (
      <Downshift
        inputValue={inputValue}
        selectedItem={inputValue}
        itemToString={this.itemToString}
        onInputValueChange={this.onInputValueChange}
        onChange={this.onChange}
      >
        {({
          inputValue,
          getRootProps,
          getInputProps,
          getMenuProps,
          getItemProps,
          selectedItem,
          highlightedIndex,
          isOpen,
        }) => {
          return (
            <SearchGroup {...getRootProps()}>
              {showArrow && <SearchArrow position={ARROW_POSITION_BY_SERVICE_TYPES[serviceCategory]} />}

              <SearchInput
                {...getInputProps({
                  isOpen,
                  placeholder: 'Enter an address, community name, city or zip code',
                })}
              />

              {isLoading && <Loading size="medium" margin="no small no no" />}

              <SearchButton onClick={this.onSubmit}>
                <SearchButtonText>Search</SearchButtonText>
                <SearchButtonIcon />
              </SearchButton>

              <SearchSuggestions {...getMenuProps({ isOpen })}>
                {(() => {
                  if (!isOpen) return null;

                  return (
                    <Http
                      url="/api/providers/search/suggestions"
                      params={{ category: serviceCategory, term: inputValue }}
                      onStartLoading={this.onStartLoading}
                      onEndLoading={this.onEndLoading}
                    >
                      {({ data = [] }) => {
                        return data.map((item, index) => (
                          <SearchSuggestion
                            key={index}
                            {...getItemProps({
                              item,
                              index,
                              isActive: highlightedIndex === index,
                              isSelected: selectedItem === item,
                            })}
                          >
                            <Highlighter
                              searchWords={[inputValue]}
                              autoEscape
                              textToHighlight={item.name}
                              highlightTag={this.renderHighlight}
                            />
                          </SearchSuggestion>
                        ));
                      }}
                    </Http>
                  );
                })()}
              </SearchSuggestions>
            </SearchGroup>
          );
        }}
      </Downshift>
    );
  }
}

Search.propTypes = {
  showArrow: PropTypes.bool,
  serviceCategory: PropTypes.string.isRequired,
  forOwner: PropTypes.bool,
  embed: PropTypes.bool,
  location: RouterPropTypes.location.isRequired,
};

Search.defaultProps = {
  showArrow: false,
  forOwner: false,
  embed: false,
};

export default withRouter(Search);
