import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darken, transparentize } from 'polished';
import { media } from '../../../core/styles';
import { Icon } from '../../../core/components';

export const FooterIconLinkIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`;

export const FooterIconLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  color: #fff;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: ${transparentize(0.9, '#000')};
  }
`;

export const FooterIconLinks = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
`;

export const FooterTextLink = styled(Link)`
  padding: 0 15px;
  border-right: 1px solid ${props => darken(0.1, props.theme.brandPrimary)};
  text-decoration: underline;
  color: #fff;

  &:last-child {
    border-right: none;
  }
`;

export const FooterTextLinks = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  ${media.phone`
    margin: 0 0 10px;
  `};
`;

export const FooterLinks = styled.div`
  display: flex;
  align-items: center;

  ${media.phone`
    flex-direction: column;
  `};
`;

export const FooterCopyright = styled.div`
  color: #fff;

  ${media.phone`
    margin-bottom: 20px;
  `};
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1100px;

  ${media.tablet`
    flex-direction: column;
  `};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px 30px;
  background-color: ${props => props.theme.brandPrimary};
  color: #fff;

  ${media.tablet`
    padding: 15px 10px;
  `};
`;
