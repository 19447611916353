import styled, { css } from 'styled-components';

import { transparentize } from 'polished';

export const inputStyle = props => `
  width: 100%;
  padding: 4px 8px;
  background-color: ${props.theme.grayLighter};
  outline: none;
  border: 1px solid ${props.theme.gray};
  border-radius: 0;
  line-height: 22px;
  font-size: 14px;

  &:focus {
    box-shadow: 0 1px 3px ${transparentize(0.9, '#000')};
  }

  &::placeholder {
    text-transform: capitalize;
    color: ${props.theme.grayDark};
  }
  `;

export const Input = styled.input`
  ${inputStyle};
  ${props =>
    props.resize &&
    css`
      resize: ${props.resize};
    `};
`;
