import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormError, Select } from './styled';

const FormSelect = ({ field, form, options, children, ...props }) => {
  const { touched, errors } = form;

  return (
    <FormGroup>
      <Select {...field} {...props} isEmpty={!field.value}>
        {children}

        {options.map(option => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </Select>

      {touched[field.name] && errors[field.name] && <FormError>{errors[field.name]}</FormError>}
    </FormGroup>
  );
};

FormSelect.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  options: PropTypes.array,
};

FormSelect.defaultProps = {
  options: [],
  children: undefined,
};

export default FormSelect;
