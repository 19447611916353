import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash-es';
import { connect } from 'react-redux';
import { Resolver } from '../../../core/components';
import { PageLoading } from '../../../common/components';
import { loadFeaturedResourceCategories, resetFeaturedResourceCategories } from '../../ducks';
import FeaturedResourceCategoriesPage from './FeaturedResourceCategoriesPage';

class FeaturedResourceCategoriesPageResolver extends PureComponent {
  componentWillUnmount() {
    const { resetFeaturedResourceCategories } = this.props;
    resetFeaturedResourceCategories();
  }

  resolve = async () => {
    const { loadFeaturedResourceCategories } = this.props;
    return loadFeaturedResourceCategories();
  };

  render() {
    const successProps = pick(this.props, 'featuredResourceCategories');

    return (
      <Resolver
        successComponent={FeaturedResourceCategoriesPage}
        successProps={successProps}
        loadingComponent={PageLoading}
        resolve={this.resolve}
        redirectOnError="/"
      />
    );
  }
}
FeaturedResourceCategoriesPageResolver.propTypes = {
  loadFeaturedResourceCategories: PropTypes.func.isRequired,
  resetFeaturedResourceCategories: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  featuredResourceCategories: state.resources.featuredResourceCategories.featuredResourceCategories,
});

const mapDispatchToProps = {
  loadFeaturedResourceCategories,
  resetFeaturedResourceCategories,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeaturedResourceCategoriesPageResolver);
