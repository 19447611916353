import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { sizeMapper, media } from '../../../core/styles';

export const PageSubSectionTitle = styled.h2`
  margin: ${props => sizeMapper(props.margin, 'no no sMedium')};
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 18px;

  ${media.desktop`
    font-size: 16px;
  `};

  ${media.tablet`
    font-size: 14px;
  `};
`;

export const PageSubSection = styled.div`
  margin: ${props => sizeMapper(props.margin, 'no no medium')};

  ${props =>
    props.marginTablet &&
    css`
      ${media.tablet`
        margin: ${props => sizeMapper(props.margin, 'no no sMedium')};
      `}
    `};

  ${props =>
    props.marginPhone &&
    css`
      ${media.tablet`
        margin: ${props => sizeMapper(props.marginPhone, 'no no small')};
      `}
    `};
`;

export const PageSectionTitle = styled.h1`
  margin: ${props => sizeMapper(props.margin, 'no no small')};
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 22px;

  ${media.desktop`
    font-size: 20px;
  `};

  ${media.tablet`
    font-size: 18px;
  `};
`;

export const PageSectionListItem = styled.li`
  margin: 0 0 8px 21px;
`;

export const PageSectionList = styled.ul`
  margin: 20px 0;
  padding: 0;
`;

export const PageSectionDescriptionParagraph = styled.p`
  margin: 0 0 10px;
`;

export const PageSectionDescription = styled.div`
  line-height: 28px;
  font-size: 16px;

  ${media.tablet`
    line-height: 27px;
    font-size: 15px;
  `};
`;

export const PageSection = styled.div`
  margin: ${props => sizeMapper(props.margin, 'no no large')};

  ${props =>
    props.marginTablet &&
    css`
      ${media.tablet`
        margin: ${props => sizeMapper(props.margin, 'no no lMedium')};
      `}
    `};

  ${props =>
    props.marginPhone &&
    css`
      ${media.tablet`
        margin: ${props => sizeMapper(props.marginPhone, 'no no medium')};
      `}
    `};
`;

export const PageContent = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 30px;

  ${media.tablet`
    padding: 0 15px;
  `};
`;

export const PageTitle = styled.h1`
  margin: 0;
  letter-spacing: 0.4px;
  line-height: 1.4;
  font-weight: ${props => props.theme.fontWeightNormal};
  font-size: 24px;

  ${media.desktop`
    font-size: 21px;
  `};

  ${media.tablet`
    font-size: 19px;
  `};

  ${media.phone`
    font-size: 17px;
  `};
`;

export const PageSubTitle = styled.h2`
  margin: 0;
  letter-spacing: 0.4px;
  line-height: 1.4;
  font-weight: ${props => props.theme.fontWeightNormal};
  font-size: 24px;

  ${media.desktop`
    font-size: 21px;
  `};

  ${media.tablet`
    font-size: 19px;
  `};

  ${media.phone`
    font-size: 17px;
  `};
`;

export const PageSubTitleCallPhoneNumberLink = styled.a`
  font-size: 20px;
  color: #fff;

  ${media.phone`
    font-size: 18px;
  `};
`;

export const PageMetaContent = styled.div`
  width: 100%;
  max-width: 1100px;
`;

export const PageMeta = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  padding: 25px 30px;
  background-color: ${props => props.theme.brandPrimary};
  color: #fff;

  ${media.desktop`
    margin-bottom: 40px;
  `};

  ${media.tablet`
    margin-bottom: 30px;
    padding: 20px 15px;
  `};

  ${media.phone`
    margin-bottom: 20px;
    padding: 18px 15px;
  `};
`;

export const PageLoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${transparentize(0.2, '#fff')};
`;

export const PageLoading = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageBody = styled.div`
  position: relative;
  flex: 1;
  padding-bottom: 40px;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 120px;
`;
