import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { sizeMapper } from '../../styles';

export const PaginationItem = styled(NavLink)`
  display: inline-block;
  text-align: center;
  line-height: 24px;
  min-width: 26px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.brandPrimary};
  font-size: 14px;
  color: ${props => props.theme.brandPrimary};

  &:last-child {
    margin-right: 0;
  }

  &.active {
    pointer-events: none;
    border-color: ${props => props.theme.grayDark};
    color: ${props => props.theme.grayDarker};
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  margin: ${props => sizeMapper(props.margin, 'medium no')};
  user-select: none;
`;
