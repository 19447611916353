import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { pick } from 'lodash-es';
import { connect } from 'react-redux';
import { Resolver } from '../../../core/components';
import { PageLoading } from '../../../common/components';
import { loadFeaturedResources, resetFeaturedResources } from '../../ducks';
import FeaturedResourcesPage from './FeaturedResourcesPage';

class FeaturedResourcesPageResolver extends PureComponent {
  componentWillUnmount() {
    const { resetFeaturedResources } = this.props;
    resetFeaturedResources();
  }

  resolve = async () => {
    const { match, loadFeaturedResources } = this.props;
    const { categoryName } = match.params;
    return loadFeaturedResources(categoryName);
  };

  render() {
    const successProps = pick(this.props, 'featuredResources');

    return (
      <Resolver
        successComponent={FeaturedResourcesPage}
        successProps={successProps}
        loadingComponent={PageLoading}
        resolve={this.resolve}
        redirectOnError="/"
      />
    );
  }
}

FeaturedResourcesPageResolver.propTypes = {
  loadFeaturedResources: PropTypes.func.isRequired,
  resetFeaturedResources: PropTypes.func.isRequired,
  match: RouterPropTypes.match.isRequired,
};

const mapStateToProps = state => ({
  featuredResources: state.resources.featuredResources.featuredResources,
});

const mapDispatchToProps = {
  loadFeaturedResources,
  resetFeaturedResources,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeaturedResourcesPageResolver);
