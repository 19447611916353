import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { ButtonSet, Button, Grid, GridColumn } from '../../../core/components/styled';
import { FormInput, RadioGroup } from '../../../core/components';
import { PageSection, PageSubSection, PageSubSectionTitle } from '../../../common/components/styled';
import { StateSelect } from '../../../common/components';

const communityTypeRadios = [
  { label: 'Active Adult', value: 'Active Adult' },
  { label: 'Independent Living', value: 'Independent Living' },
  { label: 'Assisted Living', value: 'Assisted Living' },
  { label: 'Memory Care', value: 'Memory Care' },
];

const paymentTypeRadios = [
  { label: 'Medicaid', value: 'Medicaid' },
  { label: 'Long term care insurance', value: 'Long term care insurance' },
  { label: 'Private pay', value: 'Private pay' },
];

const bestTimeToContactRadios = [
  { label: 'Morning', value: 'Morning' },
  { label: 'Afternoon', value: 'Afternoon' },
  { label: 'Evening', value: 'Evening' },
];

const howSoonForPlacementRadios = [
  { label: 'Now', value: 'Now' },
  { label: 'Less than a month', value: 'Less than a month' },
  { label: '1-2 months', value: '1-2 months' },
  { label: 'Over 2 months', value: 'Over 2 months' },
];

const ScheduleConsultationForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <PageSection margin="no">
      <Grid multiLine margin="no no medium">
        <GridColumn size="6/12" sizeTablet="12/12" padding="no medium no no" paddingTablet="no">
          <PageSubSection>
            <PageSubSectionTitle>Contact Information</PageSubSectionTitle>

            <Field name="firstName" placeholder="First Name" component={FormInput} />
            <Field name="lastName" placeholder="Last Name" component={FormInput} />
            <Field name="email" type="email" placeholder="Email" component={FormInput} />
            <Field name="phone" type="phone" placeholder="Phone" component={FormInput} />
            <Field name="relationshipToResident" placeholder="Relationship to resident" component={FormInput} />
          </PageSubSection>
        </GridColumn>

        <GridColumn size="6/12" sizeTablet="12/12" padding="no medium no no" paddingTablet="no">
          <PageSubSection>
            <PageSubSectionTitle>Prospective Resident Information</PageSubSectionTitle>

            <Field name="residentFirstName" placeholder="First Name" component={FormInput} />
            <Field name="residentLastName" placeholder="Last Name" component={FormInput} />
            <Field name="city" placeholder="City" component={FormInput} />
            <StateSelect />
            <Field name="zipCode" placeholder="Zip code" component={FormInput} />
          </PageSubSection>
        </GridColumn>
      </Grid>

      <Grid multiLine>
        <GridColumn size="3/12" sizeTablet="4/12" sizePhone="12/12" padding="no small no no">
          <PageSubSection>
            <PageSubSectionTitle>Community Type</PageSubSectionTitle>
            <Field block name="communityType" component={RadioGroup} radios={communityTypeRadios} />
          </PageSubSection>
        </GridColumn>

        <GridColumn size="3/12" sizeTablet="4/12" sizePhone="12/12" padding="no small no no">
          <PageSubSection>
            <PageSubSectionTitle>Payment Type</PageSubSectionTitle>
            <Field block name="paymentType" component={RadioGroup} radios={paymentTypeRadios} />
          </PageSubSection>
        </GridColumn>

        <GridColumn size="3/12" sizeTablet="4/12" sizePhone="12/12" padding="no small no no">
          <PageSubSection>
            <PageSubSectionTitle>Best Time to Contact</PageSubSectionTitle>
            <Field block name="bestTimeToContact" component={RadioGroup} radios={bestTimeToContactRadios} />
          </PageSubSection>
        </GridColumn>

        <GridColumn size="3/12" sizeTablet="4/12" sizePhone="12/12" padding="no small no no">
          <PageSubSection>
            <PageSubSectionTitle>How Soon for Placement</PageSubSectionTitle>
            <Field block name="howSoonForPlacement" component={RadioGroup} radios={howSoonForPlacementRadios} />
          </PageSubSection>
        </GridColumn>
      </Grid>

      <ButtonSet>
        <Button type="submit" color="primary">
          Submit my information
        </Button>
      </ButtonSet>
    </PageSection>
  </form>
);

ScheduleConsultationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default ScheduleConsultationForm;
