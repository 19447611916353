import styled from 'styled-components';
import { media } from '../../../core/styles';

export const ScheduleConsultationBannerText = styled.span`
  line-height: 21px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 15px;
  color: ${props => props.theme.brandPrimary};
`;

export const ScheduleConsultationBanner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.brandPrimary};

  ${media.phone`
    padding: 8px 10px;
  `};
`;
