import React from 'react';
import { PageLoadingOverlay as PageLoadingOverlayContainer } from './styled';
import { Loading } from '../../core/components';

const PageLoadingOverlay = () => (
  <PageLoadingOverlayContainer>
    <Loading size="large" />
  </PageLoadingOverlayContainer>
);

export default PageLoadingOverlay;
