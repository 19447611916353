import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import { initialize, history } from './core/services';
import { GlobalStyle } from './core/components/styled';
import { THEME } from './core/constants';
import { NotificationList } from './core/components';
import { Page } from './common/components';
import { HomePage } from './home/components/pages';
import { SearchPageResolver } from './search/components/pages';
import { IndependentLivingPageResolver } from './independentLiving/components/pages';
import { AssistedLivingPageResolver } from './assistedLiving/components/pages';
import { MemoryCarePageResolver } from './memoryCare/components/pages';
import { CommunityPageResolver } from './community/components/pages';
import { OwnersPageResolver } from './owners/components/pages';
import {
  AboutPage,
  OurProcessPage,
  FeaturedCommunitiesPageResolver,
  FeaturedResourceCategoriesPageResolver,
  FeaturedResourcesForCategoryPageResolver,
  ScheduleConsultationPage,
  ContactPage,
  ContactAnnaPage,
} from './resources/components/pages';
import { TermsOfUsePage, PrivacyPolicyPage, SubmissionPolicyPage } from './legal/components/pages';
import store from './store';

initialize();

const AppRouter = () => (
  <ConnectedRouter history={history}>
    <Page>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/search" component={SearchPageResolver} />
        <Route exact path="/independent-living" component={IndependentLivingPageResolver} />
        <Route exact path="/assisted-living" component={AssistedLivingPageResolver} />
        <Route exact path="/memory-care" component={MemoryCarePageResolver} />
        <Route exact path="/communities/:communityId/:communitySlug" component={CommunityPageResolver} />
        <Route exact path="/owners" component={OwnersPageResolver} />
        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/our-process" component={OurProcessPage} />
        <Route exact path="/featured-communities" component={FeaturedCommunitiesPageResolver} />
        <Route exact path="/featured-resources" component={FeaturedResourceCategoriesPageResolver} />
        <Route exact path="/featured-resources/:categoryName" component={FeaturedResourcesForCategoryPageResolver} />
        <Route exact path="/schedule-consultation" component={ScheduleConsultationPage} />
        <Route exact path="/contact-us" component={ContactPage} />
        <Route exact path="/contact-anna" component={ContactAnnaPage} />
        <Route exact path="/terms-of-use" component={TermsOfUsePage} />
        <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route exact path="/submission-policy" component={SubmissionPolicyPage} />
      </Switch>
    </Page>
  </ConnectedRouter>
);

render(
  <ThemeProvider theme={THEME}>
    <>
      <Normalize />
      <GlobalStyle />
      <Provider store={store}>
        <AppRouter />
        <NotificationList />
      </Provider>
    </>
  </ThemeProvider>,
  document.getElementById('root'),
);
