import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../core/components';
import { RESULTS_PER_PAGE } from '../constants';
import { CommunityList as CommunityListContainer, CommunityListItems, CommunityListMapContainer } from './styled';
import Map from './Map';
import CommunityListItem from './CommunityListItem';
import CommunityListHelp from './CommunityListHelp';

const CommunityList = ({ totalCount, communities, forOwner }) => (
  <CommunityListContainer>
    <CommunityListItems>
      {!communities.length && <CommunityListHelp />}

      {!!communities.length &&
        communities.map(({ id, name, address, imageUrl }) => (
          <CommunityListItem key={id} id={id} name={name} address={address} imageUrl={imageUrl} forOwner={forOwner} />
        ))}

      <Pagination totalCount={totalCount} limitPerPage={RESULTS_PER_PAGE} maxPaginationItems={10} />
    </CommunityListItems>

    <CommunityListMapContainer>
      <Map locations={communities} />
    </CommunityListMapContainer>
  </CommunityListContainer>
);

CommunityList.propTypes = {
  totalCount: PropTypes.number.isRequired,
  communities: PropTypes.array.isRequired,
  forOwner: PropTypes.bool,
};

CommunityList.defaultProps = {
  forOwner: false,
};

export default CommunityList;
