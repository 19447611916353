import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { Redirect } from 'react-router-dom';
import { pick } from 'lodash-es';
import { connect } from 'react-redux';
import { getQueryParams } from '../../../core/helpers';
import { Resolver } from '../../../core/components';
import { ASSISTED_LIVING } from '../../../common/constants';
import { PageLoading } from '../../../common/components';
import {
  search,
  resetSearch,
  loadFeaturedCommunities,
  resetFeaturedCommunities,
  getFeaturedAssistedLivingCommunities,
} from '../../../common/ducks';
import AssistedLivingPage from './AssistedLivingPage';

class AssistedLivingPageResolver extends PureComponent {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      this.resolve(true);
    }
  }

  componentWillUnmount() {
    const { resetSearch, resetFeaturedCommunities } = this.props;

    resetSearch();
    resetFeaturedCommunities();
  }

  resolve = async (skipLoadingFeaturedCommunities = false) => {
    const { location, search, resetSearch, loadFeaturedCommunities } = this.props;
    const { type, term, page = 1 } = getQueryParams(location.search);

    if (!skipLoadingFeaturedCommunities) await loadFeaturedCommunities(ASSISTED_LIVING.id);

    if (term) {
      await search(ASSISTED_LIVING.id, type, term, Number(page));
    } else {
      resetSearch();
    }
  };

  areQueryParamsValid = () => {
    const { location } = this.props;
    const { term } = getQueryParams(location.search);

    return term === undefined || !!term;
  };

  render() {
    const successProps = pick(
      this.props,
      'isSearching',
      'isSearchCompleted',
      'isLoadCompleted',
      'totalCount',
      'results',
      'featuredCommunities',
      'location',
    );

    if (!this.areQueryParamsValid()) return <Redirect to="/assisted-living" />;

    return (
      <Resolver
        successComponent={AssistedLivingPage}
        successProps={successProps}
        loadingComponent={PageLoading}
        resolve={this.resolve}
        redirectOnError="/assisted-living"
      />
    );
  }
}
AssistedLivingPageResolver.propTypes = {
  location: RouterPropTypes.location.isRequired,
  search: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  loadFeaturedCommunities: PropTypes.func.isRequired,
  resetFeaturedCommunities: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isSearching: state.common.search.isSearching,
  isSearchCompleted: state.common.search.isSearchCompleted,
  isLoadCompleted: state.common.featuredCommunities.isLoadCompleted,
  totalCount: state.common.search.totalCount,
  results: state.common.search.results,
  featuredCommunities: getFeaturedAssistedLivingCommunities(state.common.featuredCommunities),
});

const mapDispatchToProps = {
  search,
  resetSearch,
  loadFeaturedCommunities,
  resetFeaturedCommunities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssistedLivingPageResolver);
