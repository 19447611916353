import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { history, createSuccessNotification } from '../../../core/services';
import {
  PageMeta,
  PageContent,
  PageTitle,
  PageMetaContent,
  PageSection,
  PageSectionDescription,
  PageSectionDescriptionParagraph,
} from '../../../common/components/styled';
import { DocumentTitle, PageLoadingOverlay } from '../../../common/components';
import { scheduleConsultation } from '../../ducks';
import { scheduleConsultationSchema } from '../../schemas';
import { ScheduleConsultationForm } from '../forms';

const formInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  relationshipToResident: '',
  residentFirstName: '',
  residentLastName: '',
  state: '',
  city: '',
  zipCode: '',
  communityType: '',
  paymentType: '',
  bestTimeToContact: '',
  howSoonForPlacement: '',
};

class ScheduleConsultationPage extends PureComponent {
  handleSubmit = async values => {
    const { scheduleConsultation } = this.props;
    await scheduleConsultation(values);
    createSuccessNotification('Thank you for getting in touch. We will reach out shortly.');
    history.push('/');
  };

  render() {
    const { isScheduling } = this.props;

    return (
      <Fragment>
        <DocumentTitle>Schedule a Consultation</DocumentTitle>
        <PageMeta>
          <PageMetaContent>
            <PageTitle>Schedule a Consultation</PageTitle>
          </PageMetaContent>
        </PageMeta>

        <PageContent>
          <PageSection>
            <PageSectionDescription>
              <PageSectionDescriptionParagraph>
                Contact us at <a href="tel:888-888-3198">888-888-3198</a> for an in-depth personalized assessment of
                your loved one’s needs.
              </PageSectionDescriptionParagraph>

              <PageSectionDescriptionParagraph>
                If you would like us to contact you at a more convenient time, complete the form below to schedule your
                personalized assessment.
              </PageSectionDescriptionParagraph>
            </PageSectionDescription>
          </PageSection>

          <Formik
            onSubmit={this.handleSubmit}
            render={ScheduleConsultationForm}
            initialValues={formInitialValues}
            validationSchema={scheduleConsultationSchema}
          />
        </PageContent>

        {isScheduling && <PageLoadingOverlay />}
      </Fragment>
    );
  }
}

ScheduleConsultationPage.propTypes = {
  isScheduling: PropTypes.bool.isRequired,
  scheduleConsultation: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isScheduling: state.resources.scheduleConsultation.isScheduling,
});

const mapDispatchToProps = { scheduleConsultation };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScheduleConsultationPage);
