import { combineReducers } from 'redux';
import { reducer as communityReducer } from './community';
import { reducer as requestInformationReducer } from './requestInformation';

export { loadCommunity, resetCommunity } from './community';
export { requestInformation, resetRequestInformation } from './requestInformation';

export const reducer = combineReducers({
  community: communityReducer,
  requestInformation: requestInformationReducer,
});
