import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash-es';
import { connect } from 'react-redux';
import { Resolver } from '../../../core/components';
import { ASSISTED_LIVING, INDEPENDENT_LIVING, MEMORY_CARE } from '../../../common/constants';
import { PageLoading } from '../../../common/components';
import {
  loadFeaturedCommunities,
  resetFeaturedCommunities,
  getFeaturedIndependentLivingCommunities,
  getFeaturedAssistedLivingCommunities,
  getFeaturedMemoryCareCommunities,
} from '../../../common/ducks';
import FeaturedCommunitiesPage from './FeaturedCommunitiesPage';

class FeaturedCommunitiesPageResolver extends PureComponent {
  componentWillUnmount() {
    const { resetFeaturedCommunities } = this.props;
    resetFeaturedCommunities();
  }

  resolve = async () => {
    const { loadFeaturedCommunities } = this.props;
    return loadFeaturedCommunities([INDEPENDENT_LIVING, ASSISTED_LIVING, MEMORY_CARE]);
  };

  render() {
    const successProps = pick(
      this.props,
      'featuredIndependentLivingCommunities',
      'featuredAssistedLivingCommunities',
      'featuredMemoryCareCommunities',
    );

    return (
      <Resolver
        successComponent={FeaturedCommunitiesPage}
        successProps={successProps}
        loadingComponent={PageLoading}
        resolve={this.resolve}
        redirectOnError="/"
      />
    );
  }
}
FeaturedCommunitiesPageResolver.propTypes = {
  loadFeaturedCommunities: PropTypes.func.isRequired,
  resetFeaturedCommunities: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  featuredIndependentLivingCommunities: getFeaturedIndependentLivingCommunities(state.common.featuredCommunities),
  featuredAssistedLivingCommunities: getFeaturedAssistedLivingCommunities(state.common.featuredCommunities),
  featuredMemoryCareCommunities: getFeaturedMemoryCareCommunities(state.common.featuredCommunities),
});

const mapDispatchToProps = {
  loadFeaturedCommunities,
  resetFeaturedCommunities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeaturedCommunitiesPageResolver);
