import styled from 'styled-components';
import { darken, transparentize } from 'polished';
import { Link, NavLink } from 'react-router-dom';
import { modernImage, fadeAndSlideInDown, media } from '../../../core/styles';
import { bareButtonStyle } from '../../../core/components/styled';
import { Icon } from '../../../core/components';
import logo from '../../assets/img/logo.png';
import logo2x from '../../assets/img/logo@2x.png';
import logoWebP from '../../assets/img/logo.webp';
import logoWebP2x from '../../assets/img/logo@2x.webp';

export const HeaderButtonSet = styled.div`
  display: none;
  justify-content: flex-end;
  margin-top: 40px;

  ${media.desktop`
    display: flex;
  `}
`;

export const HeaderNavigationMenuItem = styled(Link)`
  display: block;
  padding: 14px 16px;
  background-color: #fff;
  text-align: left;
  font-weight: ${props => props.theme.fontWeightBold};
  font-size: 15px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme.grayLight};
  }
`;

export const HeaderNavigationMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  min-width: 240px;
  background-color: #fff;
  box-shadow: 0 4px 8px ${transparentize(0.9, '#000')};
`;

export const HeaderNavigationItem = styled(NavLink)`
  ${bareButtonStyle};
  display: inline-flex;
  align-items: center;
  height: 80px;
  padding: 0 18px;
  border-bottom: 2px solid transparent;
  text-transform: capitalize;
  font-weight: ${props => props.theme.fontWeightBold};
  font-size: 16px;
  color: ${props => props.theme.brandPrimary};
  transition: color 0.3s;

  &:hover {
    color: ${props => darken(0.3, props.theme.brandPrimary)};

    ${HeaderNavigationMenu} {
      display: block;
      animation: ${fadeAndSlideInDown} 0.3s ease-out;
    }
  }

  &:last-child {
    padding-right: 0;
  }

  &.active {
    color: ${props => props.theme.brandDefault};
    border-bottom-color: ${props => props.theme.brandSecondary};
  }
`;

export const HeaderNavigation = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  ${media.desktop`
    display: none;
  `}
`;

export const HeaderCallConsultantPhoneNumber = styled.a`
  font-size: 20px;
  color: ${props => props.theme.brandSecondary};
  animation: ${fadeAndSlideInDown} 0.3s ease-out 0.5s backwards;

  ${media.phone`
    font-size: 18px;
  `};
`;

export const HeaderCallConsultantIcon = styled(Icon).attrs({ icon: 'phone' })`
  width: 20px;
  height: 20px;
  margin: 0 10px 0 20px;
  color: ${props => props.theme.brandSecondary};
  animation: ${fadeAndSlideInDown} 0.3s ease-out 0.4s backwards;

  ${media.phone`
    width: 15px;
  `};
`;

export const HeaderCallConsultantText = styled.span`
  animation: ${fadeAndSlideInDown} 0.3s ease-out 0.3s backwards;

  ${media.custom(680)`
    display: none;
  `};
`;

export const HeaderCallConsultantContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const HeaderCallConsultant = styled.div`
  display: flex;
  justify-content: center;
`;

export const HeaderMeta = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const HeaderLogo = styled(Link)`
  display: block;
  width: 207px;
  height: 90px;
  ${modernImage(logo, logo2x, logoWebP, logoWebP2x, 'cover')};

  ${media.phone`
    width: 190px;
    height: 83px;
  `};
`;

export const HeaderContent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1100px;
`;

export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 120px;
  padding: 0 30px;
  background-color: #fff;
  box-shadow: 0 3px 6px ${transparentize(0.9, '#000')};

  ${media.phone`
    padding: 0 15px;
  `};
`;
