import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { size } from 'lodash-es';
import { date } from '../../core/helpers';
import { Button } from '../../core/components/styled';
import {
  Survey as SurveyContainer,
  SurveyDescription,
  SurveyCitations,
  SurveyCitation,
  SurveyCitationSection,
  SurveyCitationSectionDescription,
  SurveyCitationSectionTitle,
} from './styled';

class Survey extends PureComponent {
  state = { areCitationsShown: false };

  toggleCitations = () => {
    this.setState(({ areCitationsShown }) => ({ areCitationsShown: !areCitationsShown }));
  };

  render() {
    const { areCitationsShown } = this.state;
    const { survey } = this.props;

    return (
      <SurveyContainer>
        <SurveyDescription>{survey.description}</SurveyDescription>

        {!!size(survey.citations) && (
          <Button color="primary" margin="small no" onClick={this.toggleCitations}>
            {areCitationsShown ? 'Hide' : 'Show'}
          </Button>
        )}

        {!!size(survey.citations) && areCitationsShown && (
          <SurveyCitations>
            {survey.citations.map((citation, index) => (
              <SurveyCitation key={index}>
                {citation.dateCorrected && (
                  <SurveyCitationSection>
                    <SurveyCitationSectionTitle>Correction date</SurveyCitationSectionTitle>
                    <SurveyCitationSectionDescription>{date(citation.dateCorrected)}</SurveyCitationSectionDescription>
                  </SurveyCitationSection>
                )}

                <SurveyCitationSection>
                  <SurveyCitationSectionTitle>Description</SurveyCitationSectionTitle>
                  <SurveyCitationSectionDescription>{citation.description || '-'}</SurveyCitationSectionDescription>
                </SurveyCitationSection>

                <SurveyCitationSection>
                  <SurveyCitationSectionTitle>Statute</SurveyCitationSectionTitle>
                  <SurveyCitationSectionDescription>{citation.statute || '-'}</SurveyCitationSectionDescription>
                </SurveyCitationSection>
              </SurveyCitation>
            ))}
          </SurveyCitations>
        )}
      </SurveyContainer>
    );
  }
}

Survey.propTypes = {
  survey: PropTypes.object.isRequired,
};

export default Survey;
