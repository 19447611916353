export const INDEPENDENT_LIVING = {
  id: 'IndependentLiving',
  name: 'Independent Living',
};

export const ASSISTED_LIVING = {
  id: 'AssistedLiving',
  name: 'Assisted Living',
};

export const MEMORY_CARE = {
  id: 'MemoryCare',
  name: 'Memory Care',
};

export const ACTIVE_ADULT = {
  id: 'ActiveAdult',
  name: 'Active Adult',
};

export const SERVICE_CATEGORIES = {
  [INDEPENDENT_LIVING.id]: INDEPENDENT_LIVING,
  [ASSISTED_LIVING.id]: ASSISTED_LIVING,
  [MEMORY_CARE.id]: MEMORY_CARE,
  [ACTIVE_ADULT.id]: ACTIVE_ADULT,
};
