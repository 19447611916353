import produce from 'immer';
import { loadCommunity as doLoadCommunity } from '../services';

// Actions
const START_LOAD = 'community/community/START_LOAD';
const COMPLETE_LOAD = 'community/community/COMPLETE_LOAD';
const FAIL_LOAD = 'community/community/FAIL_LOAD';
const RESET = 'community/community/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isLoadCompleted: false,
  isLoadFailed: false,
  community: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return produce(state, nextState => {
        nextState.isLoading = true;
      });

    case COMPLETE_LOAD:
      return produce(state, nextState => {
        nextState.isLoading = false;
        nextState.isLoadCompleted = true;
        nextState.isLoadFailed = false;
        nextState.community = action.community;
      });

    case FAIL_LOAD:
      return produce(state, nextState => {
        nextState.isLoading = false;
        nextState.isLoadCompleted = false;
        nextState.isLoadFailed = true;
        nextState.community = undefined;
      });

    case RESET:
      return produce(state, nextState => {
        nextState.isLoading = false;
        nextState.isLoadCompleted = false;
        nextState.isLoadFailed = false;
        nextState.community = undefined;
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = community => ({
  type: COMPLETE_LOAD,
  community,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadCommunity = (communityId, isInPreviewMode) => dispatch => {
  dispatch(startLoad());

  const loadCommunityPromise = doLoadCommunity(communityId, isInPreviewMode);
  loadCommunityPromise.then(community => dispatch(completeLoad(community))).catch(() => dispatch(failLoad()));

  return loadCommunityPromise;
};

export const resetCommunity = () => ({
  type: RESET,
});
