import styled, { css } from 'styled-components';
import { darken, transparentize } from 'polished';
import { bareButtonStyle } from './Button';
import Icon from '../Icon';

export const ScrollToTopButtonIcon = styled(Icon).attrs({ icon: 'arrowUp' })`
  width: 12px;
  height: 12px;
`;

export const ScrollToTopButton = styled.button`
  ${bareButtonStyle};
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: none;
  width: 40px;
  height: 40px;
  background-color: ${props => props.theme.brandPrimary};
  box-shadow: 0 0 4px ${transparentize(0.8, '#000')};
  border-radius: 6px;
  color: #fff;

  &:active {
    background-color: ${props => darken(0.05, props.theme.brandPrimary)};
  }

  ${props =>
    props.isVisible &&
    css`
      display: block;
    `};

  ${props =>
    !props.isVisible &&
    css`
      display: none;
    `};
`;
