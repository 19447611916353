import React, { Fragment } from 'react';
import { PageMeta, PageContent, PageTitle, PageMetaContent } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { LegalDocument } from '../styled';

const TermsOfUsePage = () => (
  <Fragment>
    <DocumentTitle>Privacy Policy</DocumentTitle>
    <PageMeta>
      <PageMetaContent>
        <PageTitle>Privacy Policy</PageTitle>
      </PageMetaContent>
    </PageMeta>

    <PageContent>
      <LegalDocument>
        <h4>Last updated on February 24, 2019</h4>
        <p>
          Loved Ones First believes in protecting your privacy, especially because there are so many unsavory people out
          there these days looking to prey upon others, and especially our precious Elders. We want to help keep you
          safe, and we have created our Privacy Policy accordingly.&nbsp; The terms of this Privacy Policy are effective
          immediately and apply to all visitors and account holders at LovedOnesFirst.com.&nbsp; To view previous
          versions of this policy, click here.
        </p>
        <p>
          One of the things that makes this Website such a great resource is our ability to offer discounts, rebates,
          and other attractive financial incentives and rewards to our visitors.&nbsp; Naturally, to make these
          incentives and rewards work, we sometimes have to record and then disclose to third parties some basic
          information about visitors who express interest in the Websites or products we offer.&nbsp; When you email us
          or visit LovedOnesFirst.com, or use any of our apps, you consent to our collection, use, and disclosure of
          information about you as described in this Privacy Policy.
        </p>
        <p>
          From time to time we also may run into the need to disclose information about you to certain third parties if
          we believe that doing so is necessary to comply with governmental requests, subpoenas, and court orders, or to
          protect Loved Ones First’s Website, security, assets, employees, officers, members, customers, partners, legal
          interests, public relations, business, or anything else where your complete anonymity could present a danger
          to this company or other people or companies who trust us.&nbsp; Our decision to disclose or not disclose
          information about you shall be at our sole discretion.&nbsp; However, like we said earlier, we plan to help
          keep your privacy safe, and we are careful to do so, as we will detail below.
          <em>
            If after reading this policy you want to know anything more about it (or us), please let us know at
            comments@LovedOnesFirst.com.
          </em>
        </p>
        <h3>Information We May Collect</h3>
        <p>The information we sometimes collect includes:</p>
        <ol>
          <li>
            <strong>Your Account:</strong> When you create an account on Loved Ones First, we may keep a record of the
            information you provide us, such as your name, age, sex, email address, zip code, and anything else you
            share, including photos and other visual information.
          </li>
          <li>
            <strong>Submitted Content:</strong> Content that you share with or through the Website is subject to our
            User Agreement.&nbsp; For the most part, Submitted Content such as reviews, comments, and photos that you
            share or submit may be displayed on the Website or in other of our materials.&nbsp; We may also share this
            information with third parties, including businesses.&nbsp; If your Submitted Content relates to a business
            with which we have determined that you have interacted personally, we may disclose publicly that you have
            interacted with that business.
          </li>
          <li>
            <strong>Email:</strong> Your signing up for an account, or your otherwise giving us your email address,
            gives us permission to keep your email address and, occasionally, send you messages which we believe contain
            content relevant to you, or which you or someone you know may find helpful, such as information on discounts
            and new products, properties, or services.&nbsp; We may do this from time to time, or not at all, and we may
            keep track of whether you open the messages we send, delete them, forward them, click on links within them,
            or take other actions with them.
          </li>
          <li>
            <strong>Provided Information</strong>: When you communicate with us, including by phone calls, fax, email,
            letters, in person, or any other method, we may store information that you provide with us through these
            communications.&nbsp; If you are a Business User of the Website, we may contact you, using this information,
            or by using information you provide publically about your Business or which we otherwise have on record as
            being linked to your Business.
          </li>
          <li>
            <strong>Third Party Disclosures</strong>
            <strong>:</strong>&nbsp; Loved Ones First does not disclose your information to third parties unless you
            first agree to such disclosure.
          </li>
          <li>
            <strong>Log Files: </strong>
            <em>
              LovedOnesFirst.com uses log files.&nbsp; With log files we pick up and store certain data which help us
              figure out how you got here and what you did while you were here. We use this information to figure out
              what we’re doing right and which of our content is of the most interest to people. Our log files tell us
              when you visited, which pages you visited, how you got to those pages, what the last page was before you
              left the site, how many things you clicked on, your IP address, type of browser, type of computer or
              device, internet provider, search activity, date of visit, location, referring URLs, and sometimes more,
              like what country you’re visiting from. If you’re concerned about this, don’t be: none of this information
              lets us personally identify you. We’re not trying to figure out who you are, but merely what you like to
              read.
            </em>
          </li>
          <li>
            <strong>Cookies and Web Beacons:</strong>
            <strong>
              Pretty much every website uses cookies in some way, some of which stay on your computer or mobile device,
              on our Website, or in emails we send you. By “Cookies” we mean regular cookies, web beacons, scripts,
              flash cookies, tags, and similar technologies such as advertising identifiers.&nbsp; Also, be aware that
              Google, with whom we
            </strong>
            sometimes partner as a third party vendor, uses cookies, such as the DART cookie, to serve ads to users
            based on their visit to this and other sites. If you don’t want Google to do this, you can ask them to stop.
            Contact info and an opt-out provision are at
            <a href="http://www.google.com/privacy_ads.html">www.google.com/privacy_ads.html</a>. The same also may hold
            true for some of the other links we use to direct you to third party websites. These third-party ad servers
            or ad networks use technology that figures out how to send certain relevant links or ads directly to your
            browser. These third parties automatically receive your IP address. They also may use technologies like
            cookies, JavaScript, or web beacons to measure how well their ads are working, or to personalize the ads you
            see. LovedOnesFirst.com has no access to, or control of, these cookies. If you want to know how the other
            sites work, we recommend reading their own Privacy Policies for contact and opt-out information.&nbsp; If
            you’re completely against cookies and similar technologies, your browser may contain the option to disable
            them. Just click on the tab marked “Tools,” “Properties,” “Options,” or some such thing (check the browser’s
            official website if you can’t find the tab), and disable cookies and anything else it will allow you to
            disable.&nbsp; Be aware, however, that some functions of this Website may no longer work properly after you
            do this. If any of this is unsatisfactory to you, we recommend leaving <em>this Website.</em>
          </li>
        </ol>
        <h3>Information We May Share</h3>
        <p>
          <strong>
            Loved Ones First does not sell your information.&nbsp; However, we may from time to time share with others
            some of the information we obtain about you, as described below:
          </strong>
        </p>
        <ol>
          <li>
            <strong>Third Party Service Providers:</strong> We may use third parties to perform certain services,
            including but not limited to customer support, tech support, QA, processing payments, security, and Website
            hosting. Sometimes these providers need us to share information about you so they can perform their duties
            or services, and they also may share information about you with us, after you have been in contact with
            them.
          </li>
          <li>
            <strong>Sale, Merger, or Acquisition:</strong> If ever Loved Ones First is sold to or merges with another
            company, or we are in some other way acquired by another party, that company or other party will obtain all
            or substantially all information we have, including information detailed in this Privacy Policy.&nbsp; In
            such a case, we will do our best to ensure, but cannot guarantee, that the other company or party honors
            this Policy it its entirety.
          </li>
          <li>
            <strong>Legal Necessity or Internal Investigation</strong>: If ever subpoenaed by a court, or served a
            warrant, or asked in any other official legal or governmental capacity to share information about you with
            the investigators, we may do so, but only to the extent necessary to comply with the investigation.&nbsp; If
            we have a good faith reason to believe that you are engaged in unlawful activity through your use of the
            Website, or activity that goes against the Website’s User Agreement, we may share information about you with
            law enforcement or other investigators.&nbsp; Furthermore, if you complain about another user of this
            Website, we may share your complaint with that user as part of our investigation.
          </li>
        </ol>
        <h3>Changes</h3>
        <p>
          In the future, we expect to change or otherwise modify this Privacy Policy as our needs change and grow along
          with the services and products that we offer.&nbsp; If we do, we will note the date of each new version of
          this Privacy Policy at the top of the page, and that date will become the effective date of the revised
          policy.&nbsp; If any material changes are made to this Privacy Policy in the future, we will notify visitors
          through the posting of a notice on the Website.&nbsp; Please be sure to make a habit of periodically reviewing
          the most current version of this Privacy Policy each time you visit the Website.&nbsp; If you continue to use
          this Website after we make any changes to the Privacy Policy, you agree to be bound by the updated Privacy
          Policy.
        </p>
        <h3>California Residents: Your California Privacy Rights</h3>
        <p>
          In keeping with California Civil Code Section 1798.83, we do not disclose your personal information to third
          parties for the purpose of directly marketing their websites to you unless you first agree to such disclosure.
          If you have any questions regarding this policy, or would like to change your preferences, you may contact us
          by email at <em>comments@LovedOnesFirst.com</em>
        </p>
      </LegalDocument>
    </PageContent>
  </Fragment>
);

export default TermsOfUsePage;
