import { get } from 'lodash-es';
import { http } from '../../core/services';

export const loadFeaturedResourceCategories = () =>
  http.get('/api/providers/featured/resources/categories').then(response => response.data);

export const loadFeaturedResourcesForCategory = category =>
  http
    .get(`/api/providers/featured/resources?category=${encodeURIComponent(category)}`)
    .then(response => get(response.data, '0.companies') || []);
