import styled, { css } from 'styled-components';
import { bareButtonStyle } from '../../../core/components/styled';

export const VerticalNavigationToggleLine = styled.span`
  display: inline-block;
  width: 100%;
  height: 2px;
  background-color: ${props => props.theme.brandDefault};
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s;

  &:nth-child(2) {
    top: 7px;
  }

  &:nth-child(3) {
    top: 14px;
  }
`;

export const VerticalNavigationToggle = styled.button`
  ${bareButtonStyle};
  position: relative;
  z-index: 2;
  width: 22px;
  height: 22px;

  &:active {
    opacity: 0.8;
  }

  ${props =>
    props.isOpen &&
    css`
      ${VerticalNavigationToggleLine} {
        &:nth-child(1) {
          transform: translateY(7px) rotate(-45deg);
        }

        &:nth-child(2) {
          display: none;
        }

        &:nth-child(3) {
          transform: translateY(-7px) rotate(45deg);
        }
      }
    `};
`;
