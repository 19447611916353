import React from 'react';
import PropTypes from 'prop-types';
import { Loading as LoadingContainer, LoadingDot } from './styled';

const Loading = ({ size, margin }) => (
  <LoadingContainer size={size} margin={margin}>
    <LoadingDot />
    <LoadingDot />
    <LoadingDot />
  </LoadingContainer>
);

Loading.propTypes = {
  size: PropTypes.string,
  margin: PropTypes.string,
};

Loading.defaultProps = {
  size: undefined,
  margin: undefined,
};

export default Loading;
