import React from 'react';
import PropTypes from 'prop-types';
import { createCommunityUrl } from '../helpers';
import {
  FeaturedCommunityListItem as FeaturedCommunityListItemContainer,
  FeaturedCommunityListItemImageLink,
  FeaturedCommunityListItemImage,
  FeaturedCommunityListItemDetails,
  FeaturedCommunityListItemTitle,
  FeaturedCommunityListItemAddress,
} from './styled';
import communityPlaceholderImage from '../assets/img/communityPlaceholder.png';

const FeaturedCommunityListItem = ({ id, name, address, imageUrl }) => {
  const communityUrl = createCommunityUrl(id, name);
  return (
    <FeaturedCommunityListItemContainer>
      <FeaturedCommunityListItemImageLink to={communityUrl}>
        <FeaturedCommunityListItemImage src={imageUrl || communityPlaceholderImage} />
      </FeaturedCommunityListItemImageLink>
      <FeaturedCommunityListItemDetails>
        <FeaturedCommunityListItemTitle to={communityUrl}>{name}</FeaturedCommunityListItemTitle>
        <FeaturedCommunityListItemAddress>{address.formattedAddress}</FeaturedCommunityListItemAddress>
      </FeaturedCommunityListItemDetails>
    </FeaturedCommunityListItemContainer>
  );
};

FeaturedCommunityListItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default FeaturedCommunityListItem;
