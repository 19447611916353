import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import { Message } from '../../core/components/styled';
import { PageSection, PageSectionTitle, PageSectionDescription } from '../../common/components/styled';

const Overview = ({ name, description }) => (
  <PageSection margin="no">
    {!!name && <PageSectionTitle>{name}</PageSectionTitle>}

    {!!description && <PageSectionDescription>{renderHTML(description)}</PageSectionDescription>}
    {!description && <Message>No description available.</Message>}
  </PageSection>
);

Overview.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
};

Overview.defaultProps = {
  name: undefined,
  description: undefined,
};

export default Overview;
