import {
  COLOR_DEFAULT,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_SUCCESS,
  COLOR_INFO,
  COLOR_WARNING,
  COLOR_ALERT,
  COLOR_GRAY_DARKER,
  COLOR_GRAY_DARK,
  COLOR_GRAY,
  COLOR_GRAY_LIGHT,
  COLOR_GRAY_LIGHTER,
  COLOR_RED,
  COLOR_ORANGE,
  COLOR_YELLOW,
  COLOR_GREEN,
  COLOR_BLUE,
  COLOR_PURPLE,
} from '../constants';

const COLOR_MAP = {
  [COLOR_DEFAULT]: 'brandDefault',
  [COLOR_PRIMARY]: 'brandPrimary',
  [COLOR_SECONDARY]: 'brandSecondary',
  [COLOR_SUCCESS]: 'brandSuccess',
  [COLOR_INFO]: 'brandInfo',
  [COLOR_WARNING]: 'brandWarning',
  [COLOR_ALERT]: 'brandAlert',
  [COLOR_GRAY_DARKER]: 'grayDarker',
  [COLOR_GRAY_DARK]: 'grayDark',
  [COLOR_GRAY]: 'gray',
  [COLOR_GRAY_LIGHT]: 'grayLight',
  [COLOR_GRAY_LIGHTER]: 'grayLighter',
  [COLOR_RED]: 'red',
  [COLOR_ORANGE]: 'orange',
  [COLOR_YELLOW]: 'yellow',
  [COLOR_GREEN]: 'green',
  [COLOR_BLUE]: 'blue',
  [COLOR_PURPLE]: 'purple',
};

const colorMapper = (props, defaultColor = COLOR_DEFAULT) => props.theme[COLOR_MAP[props.color || defaultColor]];

export default colorMapper;
