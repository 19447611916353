import produce from 'immer';
import memoize from 'memoize-one';
import { assign, filter } from 'lodash-es';
import { INDEPENDENT_LIVING, ASSISTED_LIVING, MEMORY_CARE } from '../constants';
import { loadFeaturedCommunities as doLoadFeaturedCommunities } from '../services';

// Actions
const START_LOAD = 'common/featuredCommunities/START_LOAD';
const COMPLETE_LOAD = 'common/featuredCommunities/COMPLETE_LOAD';
const FAIL_LOAD = 'common/featuredCommunities/FAIL_LOAD';
const RESET = 'common/featuredCommunities/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isLoadCompleted: false,
  isLoadFailed: false,
  communities: [],
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return produce(state, nextState => {
        nextState.isLoading = true;
      });

    case COMPLETE_LOAD:
      return produce(state, nextState => {
        nextState.isLoading = false;
        nextState.isLoadCompleted = true;
        nextState.isLoadFailed = false;
        nextState.communities = action.communities;
      });

    case FAIL_LOAD:
      return produce(state, nextState => {
        nextState.isLoading = false;
        nextState.isLoadCompleted = false;
        nextState.isLoadFailed = true;
        nextState.communities = [];
      });

    case RESET:
      return produce(state, nextState => {
        assign(nextState, initialState);
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = communities => ({
  type: COMPLETE_LOAD,
  communities,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadFeaturedCommunities = categories => dispatch => {
  dispatch(startLoad());

  const loadFeaturedCommunitiesPromise = doLoadFeaturedCommunities(categories);

  loadFeaturedCommunitiesPromise
    .then(communities => dispatch(completeLoad(communities)))
    .catch(() => dispatch(failLoad()));

  return loadFeaturedCommunitiesPromise;
};

export const resetFeaturedCommunities = () => ({
  type: RESET,
});

// Selectors
export const getFeaturedIndependentLivingCommunities = memoize(featuredCommunitiesState =>
  filter(featuredCommunitiesState.communities, { category: INDEPENDENT_LIVING.id }),
);

export const getFeaturedAssistedLivingCommunities = memoize(featuredCommunitiesState =>
  filter(featuredCommunitiesState.communities, { category: ASSISTED_LIVING.id }),
);

export const getFeaturedMemoryCareCommunities = memoize(featuredCommunitiesState =>
  filter(featuredCommunitiesState.communities, { category: MEMORY_CARE.id }),
);
