import React from 'react';
import { Loading } from '../../core/components';
import { PageLoading as PageLoadingContainer } from './styled';

const PageLoading = () => (
  <PageLoadingContainer>
    <Loading size="large" />
  </PageLoadingContainer>
);

export default PageLoading;
