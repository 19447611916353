import React from 'react';
import PropTypes from 'prop-types';
import { map, size } from 'lodash-es';
import { Message } from '../../core/components/styled';
import { Amenities as AmenitiesContainer, Amenity, AmenityTitle, AmenityList, AmenityListItem } from './styled';

const Amenities = ({ amenities }) => {
  if (!size(amenities)) return <Message>No amenities available.</Message>;

  return (
    <AmenitiesContainer>
      {map(amenities, (amenityItems, amenityName) => (
        <Amenity key={amenityName}>
          <AmenityTitle>{amenityName}</AmenityTitle>
          <AmenityList>
            {map(amenityItems, (amenityItem, index) => (
              <AmenityListItem key={index}>{amenityItem}</AmenityListItem>
            ))}
          </AmenityList>
        </Amenity>
      ))}
    </AmenitiesContainer>
  );
};

Amenities.propTypes = {
  amenities: PropTypes.object.isRequired,
};

export default Amenities;
