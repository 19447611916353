import styled from 'styled-components';
import { transparentize } from 'polished';
import { Link } from 'react-router-dom';
import { media } from '../../../core/styles';

export const FeaturedResourceCategoriesDisclosure = styled.span`
  display: block;
  margin-top: 30px;
  line-height: 20px;
  font-size: 13px;
  color: ${props => props.theme.grayDark};
`;

export const FeaturedResourceCategoryListItem = styled(Link)`
  display: block;
  line-height: 25px;
  font-size: 15px;
  color: ${props => props.theme.brandPrimary};
`;

export const FeaturedResourceCategoryListItems = styled.div`
  ${media.desktop`
    column-count: 3;
  `}

  ${media.tablet`
    column-count: 2;
  `}

  ${media.phone`
    column-count: 1;
  `}
`;

export const FeaturedResourceCategoryListTitle = styled.span`
  display: block;
  margin-bottom: 8px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 16px;
`;

export const FeaturedResourceCategoryList = styled.div``;

export const FeaturedResourceCategoryGridImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const FeaturedResourceCategoryGridImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 58.67%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${transparentize(0.9, '#000')};
    transition: background-color 0.3s ease-out;
  }
`;

export const FeaturedResourceCategoryGridItemTitle = styled.span`
  display: block;
  margin-bottom: 6px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 15px;
`;

export const FeaturedResourceCategoryGridItem = styled(Link)`
  display: block;
  width: 32%;
  margin-bottom: 20px;

  &:hover {
    ${FeaturedResourceCategoryGridItemTitle} {
      color: ${props => props.theme.brandPrimary};
    }

    ${FeaturedResourceCategoryGridImageContainer} {
      &:after {
        background-color: transparent;
      }
    }
  }

  ${media.tablet`
    width: 48%;
  `}

  ${media.phone`
    width: 100%;
  `}
`;

export const FeaturedResourceCategoryGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const FeaturedResourceCategories = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.desktop`
    flex-direction: column;
  `};
`;
