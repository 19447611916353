import styled from 'styled-components';
import { media } from '../../../core/styles';

const MapContainer = styled.div`
  width: 100%;
  height: 400px;

  ${media.tablet`
    height: 50vh;
  `};
`;

export default MapContainer;
