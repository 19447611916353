import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { Link } from 'react-router-dom';
import { slideInRightFull, slideOutRightFull } from '../../../core/styles';
import { Icon } from '../../../core/components';
import { bareButtonStyle } from '../../../core/components/styled';

export const VerticalNavigationItem = styled(Link)`
  ${bareButtonStyle};
  width: 100%;
  padding: 15px 50px;
  text-align: left;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 16px;
  color: ${props => props.theme.brandPrimary};

  &:hover {
    background-color: ${props => props.theme.grayLight};
  }
`;

export const VerticalNavigationGroupItems = styled.div`
  ${VerticalNavigationItem} {
    padding-left: 60px;
    font-size: 14px;
  }
`;

export const VerticalNavigationGroupIcon = styled(Icon).attrs({ icon: 'arrowDown' })`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 12px;
  height: 12px;
  transition: transform 0.3s;
`;

export const VerticalNavigationGroup = styled.div`
  ${props =>
    props.isOpen &&
    css`
      background-color: ${props => props.theme.grayLighter};

      ${VerticalNavigationGroupIcon} {
        transform: rotate(-180deg);
      }
    `};
`;

export const VerticalNavigationItems = styled.div`
  flex: 1;
  max-height: 100vh;
  overflow-y: auto;
  text-transform: capitalize;
  font-size: 16px;
`;

export const VerticalNavigationContent = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  max-width: 400px;
  animation: ${slideInRightFull} 0.3s ease-out;
`;

export const VerticalNavigationDismiss = styled.div`
  flex: 1;
`;

export const VerticalNavigation = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background-color: ${transparentize(0.6, '#000')};

  ${props =>
    props.isClosing &&
    css`
      ${VerticalNavigationContent} {
        animation: ${slideOutRightFull} 0.3s ease-out;
      }
    `};
`;
