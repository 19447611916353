import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { INDEPENDENT_LIVING } from '../../../common/constants';
import {
  PageMeta,
  PageContent,
  PageTitle,
  PageMetaContent,
  PageSection,
  PageSectionTitle,
  PageSectionDescription,
  SearchArea,
} from '../../../common/components/styled';
import {
  DocumentTitle,
  PageLoadingOverlay,
  Search,
  FeaturedCommunityList,
  CommunityList,
} from '../../../common/components';

class SearchPage extends PureComponent {
  render() {
    const { isSearching, isSearchCompleted, totalCount, results, featuredCommunities } = this.props;

    return (
      <Fragment>
        <DocumentTitle>Independent Living</DocumentTitle>
        <PageMeta>
          <PageMetaContent>
            <PageTitle>Independent Living</PageTitle>
          </PageMetaContent>
        </PageMeta>

        {isSearching && <PageLoadingOverlay />}

        <PageContent>
          <PageSection marginPhone="no no lMedium">
            <PageSectionTitle>What is Independent Living?</PageSectionTitle>
            <PageSectionDescription>
              Independent Living is a type of senior community where residents are very capable individuals, who prefer
              personal assistance with a low maintenance way of life. While no care is available, assistance may be
              requested if needed. Services offered to residents may include scheduled activities, indoor maintenance,
              housekeeping, meal preparation, grounds-keeping, and scheduled transportation. Fees are typically all
              inclusive, including utilities, internet as well as a number of onsite social events.
            </PageSectionDescription>
          </PageSection>

          <PageSection marginPhone="no no lMedium">
            <PageSectionTitle margin="no no sMedium">Search for Independent Living communities</PageSectionTitle>
            <SearchArea>
              <Search serviceCategory={INDEPENDENT_LIVING.id} embed />
            </SearchArea>

            {isSearchCompleted && <CommunityList totalCount={totalCount} communities={results} />}
          </PageSection>

          {!!featuredCommunities.length && (
            <PageSection margin="no">
              <PageSectionTitle margin="no no sMedium">Featured Independent Living Communities</PageSectionTitle>
              <FeaturedCommunityList communities={featuredCommunities} />
            </PageSection>
          )}
        </PageContent>
      </Fragment>
    );
  }
}

SearchPage.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  isSearchCompleted: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  results: PropTypes.array.isRequired,
  featuredCommunities: PropTypes.array.isRequired,
};

export default SearchPage;
