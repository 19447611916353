import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { getQueryParams } from '../../../core/helpers';
import { createSuccessNotification } from '../../../core/services';
import {
  Grid,
  GridColumn,
  TabSet,
  Tabs,
  Tab,
  TabIcon,
  TabSection,
  TabSectionContent,
} from '../../../core/components/styled';
import {
  PageMeta,
  PageContent,
  PageTitle,
  PageSubTitle,
  PageSubTitleCallPhoneNumberLink,
  PageMetaContent,
} from '../../../common/components/styled';
import { DocumentTitle, PageLoadingOverlay } from '../../../common/components';
import { Overview, Amenities, Images, Location, Surveys, RequestInformation, ClaimProperty } from '..';

const TABS = {
  photos: 'photos',
  location: 'location',
  surveys: 'surveys',
  overview: 'overview',
  amenities: 'amenities',
};

class CommunityPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { selectedTab: props.community.description ? TABS.overview : TABS.location };
  }

  selectTab = selectedTab => {
    this.setState({ selectedTab });

    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 200);
  };

  handleSubmit = async (values, { resetForm }) => {
    const { requestInformation } = this.props;

    await requestInformation(values);
    createSuccessNotification('Thank you for getting in touch. We will reach out shortly.');
    resetForm();
  };

  render() {
    const { selectedTab } = this.state;
    const { community, isRequestingInformation, location, match } = this.props;
    const { name, description, images, surveys, amenities, address, isClaimed } = community;
    const { communityId } = match.params;
    const { forOwner } = getQueryParams(location.search);

    return (
      <Fragment>
        <DocumentTitle>{name}</DocumentTitle>
        <PageMeta>
          <PageMetaContent>
            <PageTitle>{name}</PageTitle>
            <PageSubTitle>{address.formattedAddress}</PageSubTitle>
            <PageSubTitle>
              Contact us at:{' '}
              <PageSubTitleCallPhoneNumberLink href="tel:888-888-3198">888-888-3198</PageSubTitleCallPhoneNumberLink>
            </PageSubTitle>
          </PageMetaContent>
        </PageMeta>

        {isRequestingInformation && <PageLoadingOverlay />}

        <PageContent>
          <Grid multiLine>
            <GridColumn size="9/12" padding="no medium no no" sizeDesktop="12/12" paddingDesktop="no no sMedium">
              <TabSet>
                <Tabs>
                  {!!description && (
                    <Tab isSelected={selectedTab === TABS.overview} onClick={() => this.selectTab(TABS.overview)}>
                      <TabIcon icon="pin" /> Overview
                    </Tab>
                  )}

                  <Tab isSelected={selectedTab === TABS.location} onClick={() => this.selectTab(TABS.location)}>
                    <TabIcon icon="pin" /> Location
                  </Tab>

                  {!!Object.keys(amenities).length && (
                    <Tab isSelected={selectedTab === TABS.amenities} onClick={() => this.selectTab(TABS.amenities)}>
                      <TabIcon icon="pin" /> Amenities
                    </Tab>
                  )}

                  {!!images.length && (
                    <Tab isSelected={selectedTab === TABS.photos} onClick={() => this.selectTab(TABS.photos)}>
                      <TabIcon icon="photo" /> Photos
                    </Tab>
                  )}

                  <Tab isSelected={selectedTab === TABS.surveys} onClick={() => this.selectTab(TABS.surveys)}>
                    <TabIcon icon="document" /> Citations
                  </Tab>
                </Tabs>

                <TabSection isSelected={selectedTab === TABS.overview}>
                  {!!description && (
                    <Tab
                      withinTabSection
                      isSelected={selectedTab === TABS.overview}
                      onClick={() => this.selectTab(TABS.overview)}
                    >
                      <TabIcon icon="info" /> Overview
                    </Tab>
                  )}

                  {selectedTab === TABS.overview && (
                    <TabSectionContent>
                      <Overview description={description} />
                    </TabSectionContent>
                  )}
                </TabSection>

                <TabSection isSelected={selectedTab === TABS.amenities}>
                  {!!Object.keys(amenities).length && (
                    <Tab
                      withinTabSection
                      isSelected={selectedTab === TABS.amenities}
                      onClick={() => this.selectTab(TABS.amenities)}
                    >
                      <TabIcon icon="flag" /> Amenities
                    </Tab>
                  )}

                  {selectedTab === TABS.amenities && (
                    <TabSectionContent>
                      <Amenities amenities={amenities} />
                    </TabSectionContent>
                  )}
                </TabSection>

                <TabSection isSelected={selectedTab === TABS.photos}>
                  {!!images.length && (
                    <Tab
                      withinTabSection
                      isSelected={selectedTab === TABS.photos}
                      onClick={() => this.selectTab(TABS.photos)}
                    >
                      <TabIcon icon="photo" /> Photos
                    </Tab>
                  )}

                  {selectedTab === TABS.photos && (
                    <TabSectionContent>
                      <Images images={images} />
                    </TabSectionContent>
                  )}
                </TabSection>

                <TabSection isSelected={selectedTab === TABS.location}>
                  <Tab
                    withinTabSection
                    isSelected={selectedTab === TABS.location}
                    onClick={() => this.selectTab(TABS.location)}
                  >
                    <TabIcon icon="pin" /> Location
                  </Tab>

                  {selectedTab === TABS.location && (
                    <TabSectionContent>
                      <Location location={community} />
                    </TabSectionContent>
                  )}
                </TabSection>

                <TabSection isSelected={selectedTab === TABS.surveys}>
                  <Tab
                    withinTabSection
                    isSelected={selectedTab === TABS.surveys}
                    onClick={() => this.selectTab(TABS.surveys)}
                  >
                    <TabIcon icon="document" /> Citations
                  </Tab>

                  {selectedTab === TABS.surveys && (
                    <TabSectionContent>
                      <Surveys surveys={surveys} />
                    </TabSectionContent>
                  )}
                </TabSection>
              </TabSet>
            </GridColumn>

            <GridColumn size="3/12" sizeDesktop="12/12">
              {!forOwner && <RequestInformation handleSubmit={this.handleSubmit} />}
              {forOwner && <ClaimProperty communityId={communityId} isClaimed={isClaimed} />}
            </GridColumn>
          </Grid>
        </PageContent>
      </Fragment>
    );
  }
}

CommunityPage.propTypes = {
  community: PropTypes.object.isRequired,
  isRequestingInformation: PropTypes.bool.isRequired,
  requestInformation: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
  match: RouterPropTypes.match.isRequired,
};

export default withRouter(CommunityPage);
