import React, { Fragment } from 'react';
import {
  PageMeta,
  PageContent,
  PageSection,
  PageSectionTitle,
  PageTitle,
  PageMetaContent,
} from '../../../common/components/styled';
import { DocumentTitle, ScheduleConsultationBanner } from '../../../common/components';
import {
  OurProcess,
  OurProcessStep,
  OurProcessStepIconContainer,
  OurProcessStepIcon,
  OurProcessStepTitle,
  OurProcessStepList,
  OurProcessStepListItem,
  OurProcessStepListItemCheck,
} from '../styled';

const OurProcessPage = () => (
  <Fragment>
    <DocumentTitle>Our Process</DocumentTitle>
    <PageMeta>
      <PageMetaContent>
        <PageTitle>Our Process</PageTitle>
      </PageMetaContent>
    </PageMeta>

    <PageContent>
      <PageSection>
        <PageSectionTitle>What to Expect With Loved Ones First</PageSectionTitle>

        <OurProcess>
          <OurProcessStep>
            <OurProcessStepIconContainer color="blue">
              <OurProcessStepIcon icon="notes" />
            </OurProcessStepIconContainer>

            <OurProcessStepTitle>Fully assess your needs</OurProcessStepTitle>
            <OurProcessStepList>
              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Can be done over the phone or in person
              </OurProcessStepListItem>

              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Review medical history, personal preferences
              </OurProcessStepListItem>

              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Take into account your financial capability
              </OurProcessStepListItem>
            </OurProcessStepList>
          </OurProcessStep>

          <OurProcessStep>
            <OurProcessStepIconContainer color="green">
              <OurProcessStepIcon icon="book" />
            </OurProcessStepIconContainer>

            <OurProcessStepTitle>Information & education</OurProcessStepTitle>
            <OurProcessStepList>
              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Provide answers to all your questions
              </OurProcessStepListItem>

              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Discuss all available senior living options
              </OurProcessStepListItem>

              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Work closely with multiple senior resources
              </OurProcessStepListItem>
            </OurProcessStepList>
          </OurProcessStep>

          <OurProcessStep>
            <OurProcessStepIconContainer color="red">
              <OurProcessStepIcon icon="group" />
            </OurProcessStepIconContainer>

            <OurProcessStepTitle>Relationship driven</OurProcessStepTitle>
            <OurProcessStepList>
              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Personalized community visits
              </OurProcessStepListItem>

              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Negotiate best terms on your behalf
              </OurProcessStepListItem>

              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Count on us to be your resource for life
              </OurProcessStepListItem>
            </OurProcessStepList>
          </OurProcessStep>

          <OurProcessStep>
            <OurProcessStepIconContainer color="purple">
              <OurProcessStepIcon icon="house" />
            </OurProcessStepIconContainer>

            <OurProcessStepTitle>Seamless process</OurProcessStepTitle>
            <OurProcessStepList>
              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Easy process selecting the best living environment
              </OurProcessStepListItem>

              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Manage transition
              </OurProcessStepListItem>

              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Coordinate with service providers
              </OurProcessStepListItem>
            </OurProcessStepList>
          </OurProcessStep>

          <OurProcessStep>
            <OurProcessStepIconContainer color="orange">
              <OurProcessStepIcon icon="time" />
            </OurProcessStepIconContainer>

            <OurProcessStepTitle>Timely service</OurProcessStepTitle>
            <OurProcessStepList>
              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Available 24 hours a day 7 days a week
              </OurProcessStepListItem>

              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Cater to your timetable
              </OurProcessStepListItem>

              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Same day meet and community visits, if requested
              </OurProcessStepListItem>
            </OurProcessStepList>
          </OurProcessStep>

          <OurProcessStep>
            <OurProcessStepIconContainer color="yellow">
              <OurProcessStepIcon icon="chat" />
            </OurProcessStepIconContainer>

            <OurProcessStepTitle>Follow up</OurProcessStepTitle>
            <OurProcessStepList>
              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                After move in, we check to make sure everything is perfect
              </OurProcessStepListItem>

              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Advocate on your behalf
              </OurProcessStepListItem>

              <OurProcessStepListItem>
                <OurProcessStepListItemCheck />
                Complete satisfaction is our ultimate goal
              </OurProcessStepListItem>
            </OurProcessStepList>
          </OurProcessStep>
        </OurProcess>

        <ScheduleConsultationBanner />
      </PageSection>
    </PageContent>
  </Fragment>
);

export default OurProcessPage;
