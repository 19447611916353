import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { media } from '../../../core/styles';
import { bareButtonStyle } from '../../../core/components/styled';

export const Tab = styled.button`
  ${bareButtonStyle};
  flex: 1 0;
  height: 50px;
  padding: 0 6px;
  background-color: ${transparentize(0.6, '#000')};
  border-right: 1px solid #fff;
  font-size: 18px;
  color: #fff;
  transition: background-color 0.3s;

  &:last-child {
    border-right: none;
  }

  &:hover {
    background-color: ${transparentize(0.3, '#000')};
  }

  ${props =>
    props.isSelected &&
    css`
      background-color: ${transparentize(0.2, props.theme.brandPrimary)};

      &:hover {
        background-color: ${props.theme.brandPrimary};
      }
    `};

  ${media.tablet`
    font-size: 16px;
  `};

  ${media.phone`
    font-size: 15px;
  `};
`;

export const TabList = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  border: 1px solid #fff;
`;
