import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { pick } from 'lodash-es';
import { connect } from 'react-redux';
import { getQueryParams } from '../../../core/helpers';
import { Resolver } from '../../../core/components';
import { PageLoading } from '../../../common/components';
import { loadCommunity, resetCommunity, requestInformation, resetRequestInformation } from '../../ducks';
import CommunityPage from './CommunityPage';

class CommunityPageResolver extends PureComponent {
  componentWillUnmount() {
    const { resetCommunity, resetRequestInformation } = this.props;
    resetCommunity();
    resetRequestInformation();
  }

  resolve = async () => {
    const { match, loadCommunity, location } = this.props;
    const { communityId } = match.params;
    const { preview: isInPreviewMode } = getQueryParams(location.search);

    return loadCommunity(communityId, isInPreviewMode);
  };

  render() {
    const successProps = pick(this.props, 'community', 'isRequestingInformation', 'requestInformation');

    return (
      <Resolver
        successComponent={CommunityPage}
        successProps={successProps}
        loadingComponent={PageLoading}
        resolve={this.resolve}
        redirectOnError="/"
      />
    );
  }
}
CommunityPageResolver.propTypes = {
  location: RouterPropTypes.location.isRequired,
  match: RouterPropTypes.match.isRequired,
  loadCommunity: PropTypes.func.isRequired,
  resetCommunity: PropTypes.func.isRequired,
  resetRequestInformation: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  community: state.community.community.community,
  isRequestingInformation: state.community.requestInformation.isRequesting,
});

const mapDispatchToProps = {
  loadCommunity,
  requestInformation,
  resetCommunity,
  resetRequestInformation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommunityPageResolver);
