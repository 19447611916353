import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { debounce, omit, isEqual } from 'lodash-es';
import { http } from '../services';

const MAKE_HTTP_REQUEST_DELAY = 200;

class Http extends React.Component {
  state = {
    isLoading: false,
    data: undefined,
    error: false,
  };

  cancelToken = undefined;

  makeHttpRequest = debounce(() => {
    const { url, method, params, data, onStartLoading, onEndLoading } = this.props;

    if (onStartLoading) onStartLoading();

    http({
      url,
      method,
      params,
      data,
      cancelToken: new axios.CancelToken(token => {
        this.cancelToken = token;
      }),
    })
      .then(result => {
        if (onEndLoading) onEndLoading();

        this.cancelToken = null;
        this.setState({
          isLoading: false,
          data: result.data,
          error: false,
        });
      })
      .catch(e => {
        if (axios.isCancel(e)) return;
        if (onEndLoading) onEndLoading();

        this.setState({ isLoading: false, data: undefined, error: e.message });
      });
  }, MAKE_HTTP_REQUEST_DELAY);

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(omit(prevProps, 'children'), omit(this.props, 'children'))) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    if (this.cancelToken) {
      this.cancelToken();
    }
  }

  fetchData = () => {
    if (this.cancelToken) {
      this.cancelToken();
    }

    this.setState({ isLoading: true, error: false });
    this.makeHttpRequest();
  };

  render() {
    const { children } = this.props;
    const { data, isLoading, error } = this.state;

    return children({
      isLoading,
      data,
      error,
      refetch: this.fetchData,
    });
  }
}

Http.propTypes = {
  children: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  method: PropTypes.string,
  params: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onStartLoading: PropTypes.func,
  onEndLoading: PropTypes.func,
};

Http.defaultProps = {
  method: 'get',
  params: undefined,
  data: undefined,
  onStartLoading: undefined,
  onEndLoading: undefined,
};

export default Http;
