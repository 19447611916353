import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { ButtonSet, Button } from '../../../core/components/styled';
import { FormInput } from '../../../core/components';

const RequestInformationForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <Field name="fullName" placeholder="Full Name" component={FormInput} />
    <Field name="email" type="email" placeholder="Email" component={FormInput} />
    <Field name="phone" type="phone" placeholder="Phone" component={FormInput} />

    <ButtonSet margin="sMedium no">
      <Button fluid type="submit" color="primary">
        Request Info
      </Button>
    </ButtonSet>
  </form>
);

RequestInformationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default RequestInformationForm;
