import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { ScrollToTopButton } from '../../core/components';
import { Page as PageContainer, PageBody } from './styled';
import Header from './Header';
import Footer from './Footer';

class Page extends PureComponent {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return (
      <PageContainer>
        <Header openSidebar={this.openSidebar} />
        <PageBody>
          {children}
          <ScrollToTopButton />
        </PageBody>
        <Footer />
      </PageContainer>
    );
  }
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default withRouter(Page);
