import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { mapper, sizeMapper, fadeAndSlideInUp, loadingOverlay } from '../../styles';
import { Icon } from '..';
import { bareButtonStyle } from './Button';

export const ModalTitle = styled.h2`
  margin: ${props => sizeMapper(props.margin, 'no no medium')};
  line-height: 29px;
  text-transform: capitalize;
  text-align: ${props => mapper(props.textAlign, { left: 'left', center: 'center', right: 'right' }, 'center')};
  font-size: 24px;
`;

export const ModalSubtitle = styled.span`
  display: block;
  line-height: 16px;
  text-align: ${props => mapper(props.textAlign, { left: 'left', center: 'center', right: 'right' }, 'center')};
  font-size: 16px;
  color: ${props => props.theme.grayDark};
`;

export const ModalDialog = styled.div`
  position: relative;
  width: ${props => mapper(props.size, { small: '400px', medium: '600px', large: '800px', xLarge: '1000px' })};
  padding: ${props => sizeMapper(props.padding)};
  overflow: auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 4px 8px ${props => transparentize(0.9, props.theme.brandDefault)};
  animation: ${fadeAndSlideInUp} 0.3s ease-out;
`;

export const ModalCloseIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 8px;
  height: 8px;
`;

export const ModalClose = styled.button.attrs({ type: 'button' })`
  ${bareButtonStyle};
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  background-color: ${props => props.theme.grayLight};
  border-radius: 4px;
  color: ${props => props.theme.grayDark};
  transtition: background-color 0.3s ease-out;

  &:active {
    background-color: ${props => props.theme.gray};
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-top: 5vh;
  background-color: ${props => transparentize(0.7, props.theme.brandDefault)};

  ${props =>
    props.isLoading &&
    css`
      ${ModalDialog} {
        ${loadingOverlay('24px')};
      }
    `};
`;
