import React from 'react';
import {
  CommunityListHelp as CommunityListHelpContainer,
  CommunityListHelpTitle,
  CommunityListHelpDescription,
  CommunityListHelpPhone,
  CommunityListHelpPhoneIcon,
  CommunityListHelpPhoneNumber,
} from './styled';

const CommunityListHelp = () => (
  <CommunityListHelpContainer>
    <CommunityListHelpTitle>No results found.</CommunityListHelpTitle>
    <CommunityListHelpDescription>
      But no worries. Call us now, and we will help you find the best option.
    </CommunityListHelpDescription>

    <CommunityListHelpPhone>
      <CommunityListHelpPhoneIcon />
      <CommunityListHelpPhoneNumber>888-888-3198</CommunityListHelpPhoneNumber>
    </CommunityListHelpPhone>
  </CommunityListHelpContainer>
);

export default CommunityListHelp;
