import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropypes from 'react-router-prop-types';
import { getQueryParams } from '../../../core/helpers';
import { SERVICE_CATEGORIES } from '../../../common/constants';
import { PageMeta, PageContent, PageTitle, PageMetaContent } from '../../../common/components/styled';
import { DocumentTitle, PageLoadingOverlay, CommunityList } from '../../../common/components';

class SearchPage extends PureComponent {
  render() {
    const { isSearching, totalCount, results, location } = this.props;
    const { category } = getQueryParams(location.search);

    return (
      <Fragment>
        <DocumentTitle>Search Results</DocumentTitle>
        <PageMeta>
          <PageMetaContent>
            <PageTitle>
              {totalCount} {SERVICE_CATEGORIES[category].name} communities found
            </PageTitle>
          </PageMetaContent>
        </PageMeta>

        {isSearching && <PageLoadingOverlay />}

        <PageContent>
          <CommunityList totalCount={totalCount} communities={results} />
        </PageContent>
      </Fragment>
    );
  }
}

SearchPage.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  results: PropTypes.array.isRequired,
  location: RouterPropypes.location.isRequired,
};

export default SearchPage;
