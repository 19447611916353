import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImageSlideshow from 'react-bnb-gallery';
import memoize from 'memoize-one';
import { transparentize } from 'polished';
import { map } from 'lodash-es';
import { ImageGallery, ImageGalleryButton, ImageGalleryImage, Message } from '../../core/components/styled';

const getSlideshowImages = memoize(images =>
  map(images, ({ originalSizeUrl, thumbnailSizeUrl }) => ({
    photo: originalSizeUrl,
    thumbnail: thumbnailSizeUrl,
  })),
);

class Images extends PureComponent {
  state = { isSlideshowOpen: false, activeImageIndex: 0 };

  openSlideshow = (activeImageIndex = 0) => {
    this.setState({ isSlideshowOpen: true, activeImageIndex });
  };

  closeSlideshow = () => {
    this.setState({ isSlideshowOpen: false });
  };

  render() {
    const { isSlideshowOpen, activeImageIndex } = this.state;
    const { images } = this.props;
    const slideShowImages = getSlideshowImages(images);

    return (
      <Fragment>
        {!!images.length && (
          <ImageGallery>
            {images.map((image, index) => (
              <ImageGalleryButton key={image.thumbnailSizeUrl} onClick={() => this.openSlideshow(index)}>
                <ImageGalleryImage src={image.thumbnailSizeUrl} />
              </ImageGalleryButton>
            ))}
          </ImageGallery>
        )}

        <ImageSlideshow
          show={isSlideshowOpen}
          photos={slideShowImages}
          activePhotoIndex={activeImageIndex}
          backgroundColor={transparentize(0.1, '#000')}
          onClose={this.closeSlideshow}
        />

        {!images.length && <Message>No images available.</Message>}
      </Fragment>
    );
  }
}

Images.propTypes = {
  images: PropTypes.array.isRequired,
};

export default Images;
