import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { transparentize } from 'polished';
import { modernImage, media } from '../../../core/styles';

export const ImageLinkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: ${transparentize(0.4, '#000')};
  text-align: center;
  line-height: 32px;
  font-size: 22px;
  color: #fff;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${transparentize(0.8, '#000')};
  }

  ${media.tablet`
    line-height: 30px;
    font-size: 20px;
  `};
`;

export const ImageLinkImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${props => modernImage(props.image, props.image2x, props.imageWebP, props.imageWebP2x, 'cover')};
`;

export const ImageLink = styled(Link)`
  position: relative;
  display: block;
  flex-basis: 31%;
  padding-top: 20.67%;

  ${media.phone`
    flex-basis: 100%;
    padding-top: 60%;
    margin-bottom: 15px;
  `};
`;

export const ImageLinkList = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 50px 0 70px;

  ${media.phone`
    flex-direction: column;
    margin: 30px 0 20px;
  `};
`;
