import React from 'react';
import PropTypes from 'prop-types';
import { date } from '../../core/helpers';
import { Container, Table, TableHead, TableBody, TableRow, TableCell, Message } from '../../core/components/styled';
import Survey from './Survey';

const Surveys = ({ surveys }) => {
  if (!surveys.length) return <Message>No surveys available.</Message>;

  return (
    <Container overflow="auto">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="15%">Date</TableCell>
            <TableCell width="85%">Description</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {surveys.map((survey, index) => (
            <TableRow key={index}>
              <TableCell>{date(survey.dateExecuted)}</TableCell>
              <TableCell>
                <Survey survey={survey} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

Surveys.propTypes = {
  surveys: PropTypes.array.isRequired,
};

export default Surveys;
