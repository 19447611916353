import React from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { range } from 'lodash-es';
import { getQueryParams, createUrl, calculatePaginationBoundaries } from '../helpers';
import { Pagination as PaginationContainer, PaginationItem } from './styled';

const Pagination = ({ totalCount, limitPerPage, maxPaginationItems, location }) => {
  const url = location.pathname;
  const urlParams = location.search;
  const currentPage = Number(getQueryParams(urlParams).page) || 1;

  const { firstPage, lastPage } = calculatePaginationBoundaries(
    totalCount,
    limitPerPage,
    maxPaginationItems,
    currentPage,
  );

  return (
    <PaginationContainer>
      {range(firstPage, lastPage + 1).map(page => (
        <PaginationItem key={page} exact to={createUrl(url, urlParams, { page })} isActive={() => currentPage === page}>
          {page}
        </PaginationItem>
      ))}
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  totalCount: PropTypes.number.isRequired,
  limitPerPage: PropTypes.number.isRequired,
  maxPaginationItems: PropTypes.number.isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default withRouter(Pagination);
