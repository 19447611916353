import React, { Fragment } from 'react';
import { PageMeta, PageContent, PageTitle, PageMetaContent } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { LegalDocument } from '../styled';

const TermsOfUsePage = () => (
  <Fragment>
    <DocumentTitle>Submission Policy</DocumentTitle>
    <PageMeta>
      <PageMetaContent>
        <PageTitle>Submission Policy</PageTitle>
      </PageMetaContent>
    </PageMeta>

    <PageContent>
      <LegalDocument>
        <h4>Last updated on November 01, 2019.</h4>
        <p>
          Before you submit your written, photographic, or other submission, protect yourself and your submission by
          making sure that:
        </p>
        <ul>
          <li>
            <p>
              <b>You own all rights to it</b> or have been given express permission to share it, without restriction, on
              LovedOnesFirst.com
            </p>
          </li>
          <li>
            <b>It is 100% true.</b> If anything you say is not entirely true, even if you sincerely believe it is, use
            language to show this, such as “In my opinion, ________” or “So-and-so told me _______”.
          </li>
          <li>
            Even if it’s a true statement or depiction, <b>it’s not misleading.</b>
          </li>
          <li>
            You aren’t pretending to be or suggesting that you are <b>someone you’re not</b>, or that you represent or
            work with someone you don’t.
          </li>
          <li>
            You wouldn’t be <b>embarrassed</b> if the whole world found out who you are and what you said or did.
          </li>
          <li>
            Your submission doesn’t:
            <ul type="circle">
              <li>
                Treat anyone <b>unfairly</b>
              </li>
              <li>
                <b>Harass</b> or <b>intimidate</b> anybody.
              </li>
              <li>
                <b>Encourage</b> someone else to do something mean, bad, unlawful, or otherwise objectionable.
              </li>
              <li>
                <b>Promote</b> anybody or anything, unless it’s as a result of your own praise. “I love this place!” is
                fine, but stuff like “Check out this place and get a free gift card!” is not. “Go to their competitor!”
                is also out.
              </li>
              <li>
                Contain or reveal something that is <b>protected</b> by patent, trademark, copyright, trade secret, or
                similar laws, or break any other laws. If you’re not sure, ask your lawyer, or else don’t share it here.
              </li>
              <li>
                Contain any <b>personal information</b>, whether public of private, about someone else, such as phone
                numbers, addresses, emails, contact information, and so on.
              </li>
            </ul>
          </li>
        </ul>
        <h4>Special note for visual and audio submissions:</h4>
        <p>
          In general, you have legal ownership of things you say and write, but when it comes to pictures and videos,
          you should be extra careful.
          <br />
          In general:
        </p>
        <ul>
          <li>
            If you take a picture, you own the rights to it. If <b>someone else takes a picture</b>,{' '}
            <i>even if the picture is of you</i>, <b>they own the rights</b>. Don’t submit a picture you don’t own
            unless the owner gives you permission.
          </li>
          <li>
            If someone in a picture or video is identifiable, you need that <b>person’s permission to share</b> the
            picture. The exception is when the picture was taken from a public place (now you know why paparazzi photos
            are always of celebrities out in public view, not inside private buildings).
          </li>
          <li>
            Likewise, if any <b>company logo</b> appears, including on someone’s clothing or accessories, it should be
            from a publicly viewable spot, or else you should have the company’s permission to use its logo.
          </li>
          <li>
            Finally, be careful of <b>music</b> playing in videos, even in the background. Avoid it, or have its owner’s
            permission to use it.
          </li>
        </ul>
      </LegalDocument>
    </PageContent>
  </Fragment>
);

export default TermsOfUsePage;
