import React, { PureComponent, Fragment } from 'react';
import { INDEPENDENT_LIVING } from '../../../common/constants';
import { PageContent, PageSection, PageSectionTitle, PageSectionDescription } from '../../../common/components/styled';
import { DocumentTitle, ScheduleConsultationBanner } from '../../../common/components';
import { Intro, ImageLinkList } from '..';

class HomePage extends PureComponent {
  state = { activeTab: INDEPENDENT_LIVING.id };

  setActiveTab = activeTab => {
    this.setState({ activeTab });
  };

  render() {
    const { activeTab } = this.state;
    return (
      <Fragment>
        <DocumentTitle>Home</DocumentTitle>
        <Intro activeTab={activeTab} setActiveTab={this.setActiveTab} />

        <PageContent>
          <PageSection>
            <PageSectionTitle>Who We Are</PageSectionTitle>
            <PageSectionDescription>
              Loved Ones First is your one-stop platform for searching senior housing options. We serve active adults
              searching retirement communities as well as individuals searching independent, assisted living or memory
              care communities. Our unique technology searches and sorts through public records of state health audits
              to provide transparency into state-regulated communities. Our purpose is to present this data to consumers
              so that they can easily make informed decisions based on facts when placing a loved one in a care
              facility.
            </PageSectionDescription>
          </PageSection>

          <ImageLinkList />
          <ScheduleConsultationBanner />
        </PageContent>
      </Fragment>
    );
  }
}

export default HomePage;
