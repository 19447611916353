import React from 'react';
import PropTypes from 'prop-types';
import { INDEPENDENT_LIVING, ASSISTED_LIVING, MEMORY_CARE, ACTIVE_ADULT, IDX_URL } from '../../common/constants';
import { TabList as TabListContainer, Tab } from './styled';

const TabList = ({ activeTab, setActiveTab }) => (
  <TabListContainer>
    <Tab
      isSelected={activeTab === ACTIVE_ADULT.id}
      onClick={() => {
        window.location = IDX_URL;
      }}
    >
      Active adult
    </Tab>

    <Tab isSelected={activeTab === INDEPENDENT_LIVING.id} onClick={() => setActiveTab(INDEPENDENT_LIVING.id)}>
      Independent living
    </Tab>

    <Tab isSelected={activeTab === ASSISTED_LIVING.id} onClick={() => setActiveTab(ASSISTED_LIVING.id)}>
      Assisted living
    </Tab>

    <Tab isSelected={activeTab === MEMORY_CARE.id} onClick={() => setActiveTab(MEMORY_CARE.id)}>
      Memory care
    </Tab>
  </TabListContainer>
);

TabList.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default TabList;
