import styled, { css } from 'styled-components';
import { media } from '../../styles';
import { Icon } from '..';
import { bareButtonStyle } from './Button';

export const TabIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

export const Tab = styled.button`
  ${bareButtonStyle};
  display: inline-flex;
  align-items: center;
  height: 40px;
  margin-right: 6px;
  padding: 0 12px;
  background: #fff;
  border-radius: 16px 16px 0 0;
  border: solid ${props => props.theme.gray};
  border-width: 1px 1px 0;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 16px;
  color: ${props => props.theme.grayDark};

  &:hover {
    background-color: ${props => props.theme.grayLight};
    color: ${props => props.theme.brandDefault};
  }

  ${props =>
    props.isSelected &&
    css`
      background-color: ${props => props.theme.grayLight};
      color: ${props => props.theme.brandDefault};
    `};

  ${props =>
    props.withinTabSection &&
    css`
      display: none;
      width: 100%;

      ${media.tablet`
        display: inline-flex;
      `};
    `};
`;

export const TabSectionContent = styled.div`
  padding: 20px 0;

  ${media.tablet`
    padding: 15px 0;
  `};
`;

export const TabSection = styled.div`
  display: none;

  ${props =>
    props.isSelected &&
    css`
      display: block;
    `};

  ${media.tablet`
    display: block;
  `};
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  border-bottom: 1px solid ${props => props.theme.gray};

  ${media.tablet`
    display: none;
  `};
`;

export const TabSet = styled.div``;
