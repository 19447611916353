import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { Redirect } from 'react-router-dom';
import { pick } from 'lodash-es';
import { connect } from 'react-redux';
import { getQueryParams } from '../../../core/helpers';
import { Resolver } from '../../../core/components';
import { SERVICE_CATEGORIES } from '../../../common/constants';
import { PageLoading } from '../../../common/components';
import { search, resetSearch } from '../../../common/ducks';
import SearchPage from './SearchPage';

class SearchPageResolver extends PureComponent {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      this.resolve();
    }
  }

  componentWillUnmount() {
    const { resetSearch } = this.props;
    resetSearch();
  }

  resolve = async () => {
    const { location, search } = this.props;
    const { category, type, term, page = 1 } = getQueryParams(location.search);
    return search(category, type, term, Number(page));
  };

  areQueryParamsValid = () => {
    const { location } = this.props;
    const { category, term } = getQueryParams(location.search);
    return !!term && !!category && !!SERVICE_CATEGORIES[category];
  };

  render() {
    const successProps = pick(this.props, 'isSearching', 'totalCount', 'results', 'location');

    if (!this.areQueryParamsValid()) return <Redirect to="/" />;

    return (
      <Resolver
        successComponent={SearchPage}
        successProps={successProps}
        loadingComponent={PageLoading}
        resolve={this.resolve}
        redirectOnError="/"
      />
    );
  }
}
SearchPageResolver.propTypes = {
  location: RouterPropTypes.location.isRequired,
  search: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isSearching: state.common.search.isSearching,
  totalCount: state.common.search.totalCount,
  results: state.common.search.results,
});

const mapDispatchToProps = { search, resetSearch };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchPageResolver);
