import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { media } from '../../../core/styles';

export const FeaturedCommunityListItemTitle = styled(Link)`
  display: block;
  margin: 0 0 12px;
  line-height: 24px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 20px;
  color: ${props => props.theme.brandDefault};

  ${media.tablet`
    line-height: 24px;
    font-size: 18px;
  `};

  ${media.phone`
    margin-bottom: 8px;
    line-height: 22px;
    font-size: 16px;
  `};
`;

export const FeaturedCommunityListItemAddress = styled.span`
  display: block;
  margin-bottom: 18px;
  line-height: 22px;
  font-size: 16px;
  color: ${props => props.theme.grayDarker};

  ${media.tablet`
    line-height: 21px;
    font-size: 15px;
  `};

  ${media.phone`
    margin-bottom: 12px;
    line-height: 20px;
    font-size: 14px;
  `};
`;

export const FeaturedCommunityListItemDetails = styled.div`
  padding-top: 20px;
`;

export const FeaturedCommunityListItemImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const FeaturedCommunityListItemImageLink = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 66.67%;
`;

export const FeaturedCommunityListItem = styled.div`
  flex-basis: 31%;

  ${media.tablet`
    flex-basis: 48%;
    margin-bottom: 20px;
  `};

  ${media.phone`
    flex-basis: auto;
  `};
`;

export const FeaturedCommunityList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${media.phone`
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
  `};
`;
