import produce from 'immer';
import { assign } from 'lodash-es';
import { loadFeaturedResourcesForCategory as doLoadFeaturedResources } from '../services';

// Actions
const START_LOAD = 'resources/featuredResources/START_LOAD';
const COMPLETE_LOAD = 'resources/featuredResources/COMPLETE_LOAD';
const FAIL_LOAD = 'resources/featuredResources/FAIL_LOAD';
const RESET = 'resources/featuredResources/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isLoadingCompleted: false,
  isLoadingFailed: false,
  featuredResources: [],
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return produce(state, nextState => {
        nextState.isLoading = true;
      });

    case COMPLETE_LOAD:
      return produce(state, nextState => {
        nextState.isLoading = false;
        nextState.isLoadingCompleted = true;
        nextState.isLoadingFailed = false;
        nextState.featuredResources = action.featuredResources;
      });

    case FAIL_LOAD:
      return produce(state, nextState => {
        nextState.isLoading = false;
        nextState.isLoadingCompleted = false;
        nextState.isLoadingFailed = true;
      });

    case RESET:
      return produce(state, nextState => {
        assign(nextState, initialState);
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = featuredResources => ({
  type: COMPLETE_LOAD,
  featuredResources,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadFeaturedResources = category => dispatch => {
  dispatch(startLoad());

  const loadFeaturedResourcesPromise = doLoadFeaturedResources(category);
  loadFeaturedResourcesPromise
    .then(featuredResources => dispatch(completeLoad(featuredResources)))
    .catch(() => dispatch(failLoad()));

  return loadFeaturedResourcesPromise;
};

export const resetFeaturedResources = () => ({
  type: RESET,
});
