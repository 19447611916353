import styled, { css } from 'styled-components';
import { sizeMapper } from '../../styles';

export const RadioCheck = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: middle;
  background-color: ${props => props.theme.gray};
  border-radius: 10px;
  cursor: pointer;
`;

export const RadioText = styled.span`
  vertical-align: middle;
  text-transform: capitalize;
  font-size: 14px;
`;

export const RadioInput = styled.input`
  display: none;

  &:checked + ${RadioCheck} {
    background-color: ${props => props.theme.brandPrimary};

    &:after {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background-color: #fff;
    }
  }
`;

export const Radio = styled.label`
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.block &&
    css`
      display: block;
    `};

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.5;
    `};
`;
