import React, { Fragment } from 'react';
import { Grid, GridColumn } from '../../../core/components/styled';
import { PageMeta, PageContent, PageTitle, PageMetaContent } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import {
  ContactDetail,
  ContactDetailIcon,
  ContactDetailContent,
  ContactDetailLabel,
  ContactDetailValue,
  ContactMap,
} from '../styled';

const AboutPage = () => (
  <Fragment>
    <DocumentTitle>Contact Us</DocumentTitle>
    <PageMeta>
      <PageMetaContent>
        <PageTitle>Contact Us</PageTitle>
      </PageMetaContent>
    </PageMeta>

    <PageContent>
      <ContactMap src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.1877834447173!2d-112.17313500590046!3d33.65229590449292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b68e9ca9cb8ab%3A0xb46e6207d33edda1!2s18205%20N%2051st%20Ave%2C%20Glendale%2C%20AZ%2085308%2C%20USA!5e0!3m2!1sen!2sro!4v1706081692371!5m2!1sen!2sro" />

      <Grid multiLine>
        <GridColumn size="2/12" sizeDesktop="6/12" sizeTablet="12/12">
          <ContactDetail>
            <ContactDetailIcon icon="phone" />
            <ContactDetailContent>
              <ContactDetailLabel>By Phone</ContactDetailLabel>
              <ContactDetailValue link as="a" href="tel:888-888-3198">
                888-888-3198
              </ContactDetailValue>
            </ContactDetailContent>
          </ContactDetail>
        </GridColumn>

        <GridColumn size="3/12" sizeDesktop="6/12" sizeTablet="12/12">
          <ContactDetail>
            <ContactDetailIcon icon="email" />
            <ContactDetailContent>
              <ContactDetailLabel>By Email</ContactDetailLabel>
              <ContactDetailValue link as="a" href="mailto:info@lovedonesfirst.com">
                info@lovedonesfirst.com
              </ContactDetailValue>
            </ContactDetailContent>
          </ContactDetail>
        </GridColumn>

        <GridColumn size="6/12" sizeDesktop="12/12">
          <ContactDetail>
            <ContactDetailIcon icon="pin" />
            <ContactDetailContent>
              <ContactDetailLabel>In Person</ContactDetailLabel>
              <ContactDetailValue>18205 N 51st Ave, Suite 115 Glendale, AZ 85308</ContactDetailValue>
            </ContactDetailContent>
          </ContactDetail>
        </GridColumn>
      </Grid>
    </PageContent>
  </Fragment>
);

export default AboutPage;
