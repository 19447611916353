import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { ButtonSet, Button } from '../../../core/components/styled';
import { FormInput, FormTextArea } from '../../../core/components';
import { PageSubSection, PageSubSectionTitle } from '../../../common/components/styled';

const ContactAnnaForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <PageSubSection>
      <PageSubSectionTitle>Your name</PageSubSectionTitle>
      <Field name="firstName" placeholder="First Name" component={FormInput} />
      <Field name="lastName" placeholder="Last Name" component={FormInput} />
    </PageSubSection>

    <PageSubSection>
      <PageSubSectionTitle>What are you looking for? (Optional)</PageSubSectionTitle>
      <Field
        name="description"
        maxRows={10}
        resize="vertical"
        placeholder="Ex. I’m looking for a 2 bedroom, 2 bath home in the next 3 months. Pool and social activities nearby a plus."
        component={FormTextArea}
      />
    </PageSubSection>

    <PageSubSection>
      <PageSubSectionTitle>Contact info</PageSubSectionTitle>
      <p>(This will only be used to respond to your request)</p>
      <Field name="email" type="email" placeholder="Email" component={FormInput} />
      <Field name="phone" type="phone" placeholder="Phone" component={FormInput} />
    </PageSubSection>

    <ButtonSet>
      <Button type="submit" color="primary" fluid>
        Send Anna your info
      </Button>
    </ButtonSet>
  </form>
);

ContactAnnaForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default ContactAnnaForm;
