import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { media } from '../../../core/styles';
import { Icon } from '../../../core/components';

export const CommunityListItemTitle = styled(Link)`
  display: block;
  margin: 0 0 12px;
  line-height: 24px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 20px;
  color: ${props => props.theme.brandDefault};

  ${media.tablet`
    line-height: 24px;
    font-size: 18px;
  `};

  ${media.phone`
    margin-bottom: 8px;
    line-height: 22px;
    font-size: 16px;
  `};
`;

export const CommunityListItemAddress = styled.span`
  display: block;
  margin-bottom: 18px;
  line-height: 22px;
  font-size: 16px;
  color: ${props => props.theme.grayDarker};

  ${media.tablet`
    line-height: 21px;
    font-size: 15px;
  `};

  ${media.phone`
    margin-bottom: 12px;
    line-height: 20px;
    font-size: 14px;
  `};
`;

export const CommunityListItemClaimLink = styled(Link)`
  padding: 6px 8px;
  background-color: ${props => props.theme.brandPrimary};
  border-radius: 3px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 11px;
  color: #fff;
`;

export const CommunityListItemClaim = styled.div`
  display: flex;
  align-items: center;
`;

export const CommunityListItemCallPhoneNumber = styled.span`
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 16px;
  color: ${props => props.theme.brandSecondary};

  ${media.phone`
    font-size: 14px;
  `};
`;

export const CommunityListItemCallLabel = styled.span`
  margin-right: 8px;
  color: ${props => props.theme.grayDarker};
`;

export const CommunityListItemCall = styled.div`
  display: flex;
  align-items: center;
`;

export const CommunityListItemDetails = styled.div`
  flex: 1;
  padding: 0 30px 0 20px;

  ${media.phone`
    padding: 15px 0 0 0;;
  `};
`;

export const CommunityListItemImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const CommunityListItemImageLink = styled(Link)`
  position: relative;
  display: block;
  flex-basis: 180px;
  height: 120px;

  ${media.phone`
    flex-basis: auto;
    height: calc(66.67vw - 10px);
  `};
`;

export const CommunityListItem = styled.div`
  display: flex;
  margin-bottom: 15px;
  padding-bottom: 15px;

  ${media.phone`
    flex-direction: column;
  `};
`;

export const CommunityListHelpTitle = styled.h2`
  margin: 0 0 15px;
  line-height: 28px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 22px;
`;

export const CommunityListHelpDescription = styled.span`
  display: block;
  margin-bottom: 25px;
  line-height: 22px;
  font-size: 16px;
  color: ${props => props.theme.grayDark};
`;

export const CommunityListHelpPhoneNumber = styled.span`
  font-size: 18px;
`;

export const CommunityListHelpPhoneIcon = styled(Icon).attrs({ icon: 'phone' })`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

export const CommunityListHelpPhone = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.brandSecondary};
`;

export const CommunityListHelp = styled.div``;

export const CommunityListMapContainer = styled.div`
  position: sticky;
  top: 150px;
  width: 45%;
  height: calc(100vh - 260px);

  ${media.desktop`
    position: static;
    width: 100%;
    height: 100vh;
    padding: 0;
  `};
`;

export const CommunityListItems = styled.div`
  width: 55%;
  padding-right: 20px;

  ${media.desktop`
    width: 100%;
    padding: 0;
  `};
`;

export const CommunityList = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.desktop`
    flex-direction: column;
  `};
`;
