import axios from 'axios';
import { getApiBaseURL } from './environment';

const defaultHttpSettings = {
  responseType: 'json',
};

const http = axios.create({
  ...defaultHttpSettings,
  baseURL: `${getApiBaseURL()}/`,
});

export default http;
