import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { MEMORY_CARE } from '../../../common/constants';
import {
  PageMeta,
  PageContent,
  PageTitle,
  PageMetaContent,
  PageSection,
  PageSectionTitle,
  PageSectionDescription,
  SearchArea,
} from '../../../common/components/styled';
import {
  DocumentTitle,
  PageLoadingOverlay,
  Search,
  FeaturedCommunityList,
  CommunityList,
} from '../../../common/components';

class SearchPage extends PureComponent {
  render() {
    const { isSearching, isSearchCompleted, totalCount, results, featuredCommunities } = this.props;

    return (
      <Fragment>
        <DocumentTitle>Memory Care</DocumentTitle>
        <PageMeta>
          <PageMetaContent>
            <PageTitle>Memory Care</PageTitle>
          </PageMetaContent>
        </PageMeta>

        {isSearching && <PageLoadingOverlay />}

        <PageContent>
          <PageSection marginPhone="no no lMedium">
            <PageSectionTitle>What is Memory Care?</PageSectionTitle>
            <PageSectionDescription>
              Memory care refers to specialized long-term care designed to meet the specific needs of a person affected
              by memory impairment, dementia or Alzheimer’s disease.
            </PageSectionDescription>
          </PageSection>

          <PageSection marginPhone="no no lMedium">
            <PageSectionTitle margin="no no sMedium">Search for Memory Care communities</PageSectionTitle>
            <SearchArea>
              <Search serviceCategory={MEMORY_CARE.id} embed />
            </SearchArea>

            {isSearchCompleted && <CommunityList totalCount={totalCount} communities={results} />}
          </PageSection>

          {!!featuredCommunities.length && (
            <PageSection margin="no">
              <PageSectionTitle margin="no no sMedium">Featured Memory Care Communities</PageSectionTitle>
              <FeaturedCommunityList communities={featuredCommunities} />
            </PageSection>
          )}
        </PageContent>
      </Fragment>
    );
  }
}

SearchPage.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  isSearchCompleted: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  results: PropTypes.array.isRequired,
  featuredCommunities: PropTypes.array.isRequired,
};

export default SearchPage;
