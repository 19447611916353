import React, { Fragment } from 'react';
import {
  PageMeta,
  PageContent,
  PageTitle,
  PageMetaContent,
  PageSection,
  PageSectionTitle,
  PageSectionDescription,
  PageSectionList,
  PageSectionListItem,
} from '../../../common/components/styled';
import { DocumentTitle, ScheduleConsultationBanner } from '../../../common/components';

const AboutPage = () => (
  <Fragment>
    <DocumentTitle>About Loved Ones First</DocumentTitle>
    <PageMeta>
      <PageMetaContent>
        <PageTitle>About Loved Ones First</PageTitle>
      </PageMetaContent>
    </PageMeta>

    <PageContent>
      <PageSection>
        <PageSectionTitle>Our Mission</PageSectionTitle>
        <PageSectionDescription>
          Our mission is to be the best quality senior referral company in the markets we serve. We provide
          extraordinary customer service and personalized professional placement for your loved ones while performing
          with integrity, care, and commitment. We strive to exceed your expectations!
        </PageSectionDescription>
      </PageSection>

      <PageSection>
        <PageSectionTitle>Who We Are</PageSectionTitle>
        <PageSectionDescription>
          We created Loved Ones First to provide individuals an intuitive search engine and a personalized approach to
          identify the most suitable senior housing options. We serve active adults searching retirement communities as
          well as individuals searching independent, assisted living or memory care communities. Our technology has been
          developed to search and sort through public records of state health audits and provide transparency into
          state-regulated communities. Having access to this information allows one to make informed decisions based on
          facts.
        </PageSectionDescription>
      </PageSection>

      <PageSection>
        <PageSectionTitle>How Are We Different?</PageSectionTitle>
        <PageSectionDescription>
          <PageSectionList>
            <PageSectionListItem>
              We provide listings of active adult real estate, licensed senior living communities which includes
              independent living, assisted living and memory care
            </PageSectionListItem>

            <PageSectionListItem>
              We facilitate the platform for providers to add their community photos, prices, meal menus, calendar of
              events, floorplans and real-time availability for all types of care offered
            </PageSectionListItem>

            <PageSectionListItem>
              We are pioneers in the Adult Care field, perhaps the only website offering the most up to date audit
              reports for state licensed and regulated communities
            </PageSectionListItem>

            <PageSectionListItem>
              We provide personalized 1 on 1 attention to each individual, by phone, chat or email
            </PageSectionListItem>

            <PageSectionListItem>
              We will gladly accompany you on community tours to ensure all your questions and concerns are addressed
            </PageSectionListItem>

            <PageSectionListItem>
              We will continue to follow up once your loved one has moved into the chosen community, to ensure all is
              going well and to be your advocate in case anything needs to be addressed
            </PageSectionListItem>

            <PageSectionListItem>We provide a list of local professional resources</PageSectionListItem>

            <PageSectionListItem>
              We’re constantly updating to keep the information fresh and accurate
            </PageSectionListItem>

            <PageSectionListItem>
              We provide extended live support hours, from 7 am to 7 pm MST, 7 days a week
            </PageSectionListItem>

            <PageSectionListItem>
              Lastly, we help you save Time, Money and Stress when you or a loved one uses LovedOnesFirst.com to find
              the most suitable senior living arrangement. Call us to find out more
            </PageSectionListItem>
          </PageSectionList>
        </PageSectionDescription>
      </PageSection>

      <PageSection>
        <PageSectionTitle>How Are We Compensated?</PageSectionTitle>
        <PageSectionDescription>
          We earn a referral fee by helping senior housing providers find residents and from advertisements of featured
          listings or resources.
        </PageSectionDescription>
      </PageSection>

      <ScheduleConsultationBanner />
    </PageContent>
  </Fragment>
);

export default AboutPage;
