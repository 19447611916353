import React from 'react';
import PropTypes from 'prop-types';
import { FeaturedCommunityList as FeaturedCommunityListContainer } from './styled';
import FeaturedCommunityListItem from './FeaturedCommunityListItem';

const FeaturedCommunityList = ({ communities }) => (
  <FeaturedCommunityListContainer>
    {!!communities.length &&
      communities.map(({ id, name, address, imageUrl }) => (
        <FeaturedCommunityListItem key={id} id={id} name={name} address={address} imageUrl={imageUrl} />
      ))}
  </FeaturedCommunityListContainer>
);

FeaturedCommunityList.propTypes = {
  communities: PropTypes.array.isRequired,
};

export default FeaturedCommunityList;
