/* global google */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, withGoogleMap } from 'react-google-maps';
import { MAP_DEFAULT_CENTER, MAP_DEFAULT_ZOOM } from '../constants';
import { googleMap } from './hoc';
import MapMarker from './MapMarker';

class Map extends PureComponent {
  state = {
    openedInfoWindows: {},
  };

  componentDidMount() {
    const { shouldFitBounds } = this.props;
    if (shouldFitBounds) {
      this.fitBounds();
    }
  }

  componentDidUpdate(prevProps) {
    const { locations, shouldFitBounds } = this.props;

    if (shouldFitBounds && locations !== prevProps.locations) {
      this.fitBounds();
    }
  }

  toggleInfoWindow = id => {
    this.setState(({ openedInfoWindows }) => ({
      openedInfoWindows: { [id]: !openedInfoWindows[id] },
    }));
  };

  setMapRef = map => {
    this.map = map;
  };

  fitBounds = () => {
    const { locations } = this.props;
    if (!locations.length) return;

    const bounds = new google.maps.LatLngBounds();

    locations.forEach(location => {
      const { latitude, longitude } = location.coordinates;
      bounds.extend(new google.maps.LatLng(latitude, longitude));
    });

    this.map.fitBounds(bounds);
  };

  render() {
    const { openedInfoWindows } = this.state;
    const { locations, defaultCenter, defaultZoom } = this.props;
    const defaultCenterAsLatLng = new google.maps.LatLng(defaultCenter.latitude, defaultCenter.longitude);

    return (
      <GoogleMap ref={this.setMapRef} defaultCenter={defaultCenterAsLatLng} defaultZoom={defaultZoom}>
        {locations.map((location, index) => (
          <MapMarker
            key={index}
            location={location}
            isInfoWindowOpen={!!openedInfoWindows[location.id]}
            toggleInfoWindow={this.toggleInfoWindow}
          />
        ))}
      </GoogleMap>
    );
  }
}

Map.propTypes = {
  locations: PropTypes.array.isRequired,
  shouldFitBounds: PropTypes.bool,
  defaultCenter: PropTypes.object,
  defaultZoom: PropTypes.number,
};

Map.defaultProps = {
  shouldFitBounds: true,
  defaultCenter: MAP_DEFAULT_CENTER,
  defaultZoom: MAP_DEFAULT_ZOOM,
};

export default googleMap(withGoogleMap(Map));
