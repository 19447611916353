import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonSet, Button } from '../../../core/components/styled';
import {
  PageMeta,
  PageContent,
  PageTitle,
  PageMetaContent,
  PageSection,
  PageSectionTitle,
} from '../../../common/components/styled';
import { DocumentTitle, FeaturedCommunityList } from '../../../common/components';

class FeaturedCommunitiesPage extends PureComponent {
  render() {
    const {
      featuredIndependentLivingCommunities,
      featuredAssistedLivingCommunities,
      featuredMemoryCareCommunities,
    } = this.props;

    return (
      <Fragment>
        <DocumentTitle>Featured Communities</DocumentTitle>
        <PageMeta>
          <PageMetaContent>
            <PageTitle>Featured Communities</PageTitle>
          </PageMetaContent>
        </PageMeta>

        <PageContent>
          {!!featuredIndependentLivingCommunities.length && (
            <PageSection margin="no no large">
              <PageSectionTitle margin="no no sMedium">Independent Living Communities</PageSectionTitle>
              <FeaturedCommunityList communities={featuredIndependentLivingCommunities} />

              <ButtonSet margin="small no no">
                <Button color="primary" as={Link} to="/independent-living">
                  See more independent communities
                </Button>
              </ButtonSet>
            </PageSection>
          )}

          {!!featuredAssistedLivingCommunities.length && (
            <PageSection margin="no no large">
              <PageSectionTitle margin="no no sMedium">Assisted Living Communities</PageSectionTitle>
              <FeaturedCommunityList communities={featuredAssistedLivingCommunities} />

              <ButtonSet margin="small no no">
                <Button color="primary" as={Link} to="/assisted-living">
                  See more assisted communities
                </Button>
              </ButtonSet>
            </PageSection>
          )}

          {!!featuredMemoryCareCommunities.length && (
            <PageSection margin="no no large">
              <PageSectionTitle margin="no no sMedium">Memory Care Communities</PageSectionTitle>
              <FeaturedCommunityList communities={featuredMemoryCareCommunities} />

              <ButtonSet margin="small no no">
                <Button color="primary" as={Link} to="/memory-care">
                  See more memory care communities
                </Button>
              </ButtonSet>
            </PageSection>
          )}
        </PageContent>
      </Fragment>
    );
  }
}

FeaturedCommunitiesPage.propTypes = {
  featuredIndependentLivingCommunities: PropTypes.array.isRequired,
  featuredAssistedLivingCommunities: PropTypes.array.isRequired,
  featuredMemoryCareCommunities: PropTypes.array.isRequired,
};

export default FeaturedCommunitiesPage;
