import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { reducer as coreReducer } from './core/ducks';
import { reducer as commonReducer } from './common/ducks';
import { reducer as communityReducer } from './community/ducks';
import { reducer as resourcesReducer } from './resources/ducks';
import { gaMiddleware } from './core/middlewares';
import history from './core/services/history';

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable no-underscore-dangle */

const rootReducer = combineReducers({
  router: connectRouter(history),
  core: coreReducer,
  common: commonReducer,
  community: communityReducer,
  resources: resourcesReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, routerMiddleware(history), gaMiddleware)),
);

export default store;
