import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormError, Input } from './styled';

const FormInput = ({ field, form, ...props }) => {
  const { touched, errors } = form;

  return (
    <FormGroup>
      <Input {...field} {...props} />
      {touched[field.name] && errors[field.name] && <FormError>{errors[field.name]}</FormError>}
    </FormGroup>
  );
};

FormInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default FormInput;
