import styled from 'styled-components';
import { transparentize } from 'polished';
import { media, colorMapper, fadeAndSlideInUp, iterator } from '../../../core/styles';
import { Icon } from '../../../core/components';

export const OurProcessStepTitle = styled.span`
  display: block;
  margin-bottom: 15px;
  text-transform: capitalize;
  line-height: 26px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 18px;
`;

export const OurProcessStepListItemCheck = styled(Icon).attrs({ icon: 'check' })`
  position: absolute;
  top: 4px;
  left: -20px;
  width: 10px;
  height: 10px;
`;

export const OurProcessStepListItem = styled.li`
  position: relative;
  margin: 0 0 8px 0;
  line-height: 22px;
  font-size: 16px;
`;

export const OurProcessStepList = styled.ul`
  list-style: none;
`;

export const OurProcessStepIcon = styled(Icon)`
  width: 24px;
  height: 24px;
`;

export const OurProcessStepIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin-bottom: 25px;
  border-radius: 50px;
  background-color: ${props => transparentize(0.9, colorMapper(props))};
  color: ${colorMapper};
`;

export const OurProcessStep = styled.div`
  width: 30%;
  margin-bottom: 40px;
  padding: 0 40px 0 20px;
  animation: ${fadeAndSlideInUp} 0.3s ease-out backwards;
  ${iterator(6, step => `&:nth-child(${step + 1}) {animation-delay: ${(step + 1) * 0.05}s;}`)};

  ${media.tablet`
    width: 50%;
    margin-bottom: 30px;
  `}

  ${media.phone`
    width: 100%;
    margin-bottom: 20px;
  `}
`;

export const OurProcess = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 50px 0 60px;

  ${media.phone`
    margin: 30px 0 40px;
  `}
`;
