import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeNotification } from '../ducks';
import {
  NotificationList as NotificationListContainer,
  Notification as NotificationContainer,
  NotificationClose,
  NotificationCloseIcon,
} from './styled';

const Notification = ({ id, message, color, removeNotification }) => (
  <NotificationContainer color={color}>
    <NotificationClose onClick={() => removeNotification(id)}>
      <NotificationCloseIcon />
    </NotificationClose>
    {message}
  </NotificationContainer>
);

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  removeNotification: PropTypes.func.isRequired,
};

const NotificationList = ({ notifications, removeNotification }) => (
  <NotificationListContainer>
    {notifications.map(({ id, message, color, autoClose }) => (
      <Notification
        id={id}
        key={id}
        message={message}
        color={color}
        autoClose={autoClose}
        removeNotification={removeNotification}
      />
    ))}
  </NotificationListContainer>
);

NotificationList.propTypes = {
  notifications: PropTypes.array.isRequired,
  removeNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notifications: state.core.notifications,
});

const mapDispatchToProps = { removeNotification };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationList);
