import styled from 'styled-components';
import { transparentize } from 'polished';
import { slideInDown } from '../../styles';

export const ConfirmationTitle = styled.span`
  display: block;
  margin-bottom: 8px;
  line-height: 24px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 18px;
`;

export const ConfirmationMessage = styled.span`
  display: block;
  margin-bottom: 3px;
  line-height: 18px;
  font-weight: ${props => props.theme.fontWeighLight};
  font-size: 14px;
  color: ${props => props.theme.grayDark};
`;

export const ConfirmationDialog = styled.div`
  width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px ${transparentize(0.9, '#000')};
  line-height: 20px;
  font-size: 15px;
  animation: ${slideInDown} 0.2s ease-out;
`;

export const Confirmation = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding-top: 20vh;
  background-color: ${transparentize(0.4, '#000')};
`;
