/* eslint-disable no-undef */
import React from 'react';
import { ImageLinkList as ImageLinkListContainer, ImageLink, ImageLinkImage, ImageLinkOverlay } from './styled';
import aboutImage from '../assets/img/about.jpg';
import aboutImage2x from '../assets/img/about@2x.jpg';
import aboutImageWebP from '../assets/img/about.webp';
import aboutImageWebP2x from '../assets/img/about@2x.webp';
import ourProcessImage from '../assets/img/ourProcess.jpg';
import ourProcessImage2x from '../assets/img/ourProcess@2x.jpg';
import ourProcessImageWebP from '../assets/img/ourProcess.webp';
import ourProcessImageWebP2x from '../assets/img/ourProcess@2x.webp';
import scheduleConsultationImage from '../assets/img/scheduleConsultation.jpg';
import scheduleConsultationImage2x from '../assets/img/scheduleConsultation@2x.jpg';
import scheduleConsultationImageWebP from '../assets/img/scheduleConsultation.webp';
import scheduleConsultationImageWebP2x from '../assets/img/scheduleConsultation@2x.webp';

const ImageLinkList = () => (
  <ImageLinkListContainer>
    <ImageLink to="/about">
      <ImageLinkImage
        image={aboutImage}
        image2x={aboutImage2x}
        imageWebP={aboutImageWebP}
        imageWebP2x={aboutImageWebP2x}
      />
      <ImageLinkOverlay>About us</ImageLinkOverlay>
    </ImageLink>

    <ImageLink to="/our-process">
      <ImageLinkImage
        image={ourProcessImage}
        image2x={ourProcessImage2x}
        imageWebP={ourProcessImageWebP}
        imageWebP2x={ourProcessImageWebP2x}
      />
      <ImageLinkOverlay>Our process</ImageLinkOverlay>
    </ImageLink>

    <ImageLink to="/schedule-consultation">
      <ImageLinkImage
        image={scheduleConsultationImage}
        image2x={scheduleConsultationImage2x}
        imageWebP={scheduleConsultationImageWebP}
        imageWebP2x={scheduleConsultationImageWebP2x}
      />
      <ImageLinkOverlay>Individualized Assessment</ImageLinkOverlay>
    </ImageLink>
  </ImageLinkListContainer>
);

export default ImageLinkList;
