import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Collapsible as CollapsibleContainer, CollapsibleContent } from './styled';

class Collapsible extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: props.isOpen ? 'auto' : 0,
    };
  }

  componentDidUpdate() {
    const { isOpen } = this.props;
    this.setState({ height: isOpen ? this.contentRef.offsetHeight : 0 });
  }

  onTransitionEnd = () => {
    const { isOpen } = this.props;

    this.setState({
      height: isOpen ? 'auto' : 0,
    });
  };

  setContentRef = element => {
    this.contentRef = element;
  };

  render() {
    const { height } = this.state;
    const { children } = this.props;

    return (
      <CollapsibleContainer style={{ height }} onTransitionEnd={this.onTransitionEnd}>
        <CollapsibleContent ref={this.setContentRef}>{children}</CollapsibleContent>
      </CollapsibleContainer>
    );
  }
}

Collapsible.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Collapsible;
