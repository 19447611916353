import styled, { css } from 'styled-components';
import { mapper, sizeMapper, media } from '../../styles';

export const COLUMN_SIZES = {
  '0/12': '0',
  '1/12': '8.33%',
  '2/12': '16.66%',
  '3/12': '25%',
  '4/12': '33.33%',
  '5/12': '41.66%',
  '6/12': '50%',
  '7/12': '58.33%',
  '8/12': '66.66%',
  '9/12': '75%',
  '10/12': '83.33%',
  '11/12': '91.66%',
  '12/12': '100%',
};

export const GridColumn = styled.div`
  position: relative;
  text-align: ${props => mapper(props.align, { left: 'left', center: 'center', right: 'right' }, 'left')};

  ${props =>
    props.size &&
    css`
      width: ${mapper(props.size, COLUMN_SIZES)};
    `};

  ${props =>
    props.sizeDesktop &&
    css`
      ${media.desktop`
        width: ${mapper(props.sizeDesktop, COLUMN_SIZES)};
      `};
    `};

  ${props =>
    props.sizeTablet &&
    css`
      ${media.tablet`
        width: ${mapper(props.sizeTablet, COLUMN_SIZES)};
      `};
    `};

  ${props =>
    props.sizePhone &&
    css`
      ${media.phone`
        width: ${mapper(props.sizePhone, COLUMN_SIZES)};
      `};
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.widthDesktop &&
    css`
      ${media.desktop`
        width: ${props.widthDesktop};
      `};
    `};

  ${props =>
    props.widthTablet &&
    css`
      ${media.tablet`
        width: ${props.widthTablet};
      `};
    `};

  ${props =>
    props.widthPhone &&
    css`
      ${media.phone`
        width: ${props.width};
      `};
    `};

  ${props =>
    props.shift &&
    css`
      margin-left: ${mapper(props.shift, COLUMN_SIZES)};
    `};

  ${props =>
    props.shiftDesktop &&
    css`
      ${media.desktop`
        margin-left: ${mapper(props.shiftDesktop, COLUMN_SIZES)};
      `};
    `};

  ${props =>
    props.shiftTablet &&
    css`
      ${media.tablet`
        margin-left: ${mapper(props.shiftTablet, COLUMN_SIZES)};
      `};
    `};

  ${props =>
    props.shiftPhone &&
    css`
      ${media.phone`
        margin-left: ${mapper(props.shiftPhone, COLUMN_SIZES)};
      `};
    `};

  ${props =>
    props.padding &&
    css`
      padding: ${sizeMapper(props.padding)};
    `};

  ${props =>
    props.paddingDesktop &&
    css`
      ${media.desktop`
        padding: ${sizeMapper(props.paddingDesktop)};
      `};
    `};

  ${props =>
    props.paddingTablet &&
    css`
      ${media.tablet`
        padding: ${sizeMapper(props.paddingTablet)};
      `};
    `};

  ${props =>
    props.paddingPhone &&
    css`
      ${media.tablet`
        padding: ${sizeMapper(props.paddingPhone)};
      `};
    `};
`;

export const Grid = styled.div`
  display: flex;
  width: 100%;
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.centered &&
    css`
      justify-content: center;
    `};

  ${props =>
    props.multiLine &&
    css`
      flex-wrap: wrap;
    `};
`;
