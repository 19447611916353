import produce from 'immer';
import { assign } from 'lodash-es';
import { scheduleConsultation as doScheduleConsultation } from '../services';

// Actions
const START_SCHEDULE = 'resources/scheduleConsultation/START_SCHEDULE';
const COMPLETE_SCHEDULE = 'resources/scheduleConsultation/COMPLETE_SCHEDULE';
const FAIL_SCHEDULE = 'resources/scheduleConsultation/FAIL_SCHEDULE';
const RESET = 'resources/scheduleConsultation/RESET';

// Initial state
const initialState = {
  isScheduling: false,
  isScheduleCompleted: false,
  isScheduleFailed: false,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_SCHEDULE:
      return produce(state, nextState => {
        nextState.isScheduling = true;
      });

    case COMPLETE_SCHEDULE:
      return produce(state, nextState => {
        nextState.isScheduling = false;
        nextState.isScheduleCompleted = true;
        nextState.isScheduleFauled = false;
      });

    case FAIL_SCHEDULE:
      return produce(state, nextState => {
        nextState.isScheduling = false;
        nextState.isScheduleCompleted = false;
        nextState.isScheduleFailed = true;
      });

    case RESET:
      return produce(state, nextState => {
        assign(nextState, initialState);
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_SCHEDULE,
});

const completeLoad = () => ({
  type: COMPLETE_SCHEDULE,
});

const failLoad = () => ({
  type: FAIL_SCHEDULE,
});

export const scheduleConsultation = values => dispatch => {
  dispatch(startLoad());

  const loadFeaturedCommunitiesPromise = doScheduleConsultation(values);
  loadFeaturedCommunitiesPromise.then(() => dispatch(completeLoad())).catch(() => dispatch(failLoad()));
  return loadFeaturedCommunitiesPromise;
};

export const resetScheduleConsultation = () => ({
  type: RESET,
});
