import React from 'react';
import PropTypes from 'prop-types';
import { Map } from '../../common/components';
import { MapContainer } from './styled';

const Overview = ({ location }) => (
  <MapContainer>
    <Map shouldFitBounds={false} locations={[location]} defaultCenter={location.coordinates} defaultZoom={15} />
  </MapContainer>
);

Overview.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Overview;
