import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  PageMeta,
  PageContent,
  PageTitle,
  PageMetaContent,
  PageSection,
  PageSectionTitle,
  PageSectionDescription,
  SearchArea,
} from '../../../common/components/styled';
import { DocumentTitle, PageLoadingOverlay, Search, CommunityList } from '../../../common/components';

class OwnersPage extends PureComponent {
  render() {
    const { isSearching, isSearchCompleted, totalCount, results } = this.props;

    return (
      <Fragment>
        <DocumentTitle>For Owners</DocumentTitle>
        <PageMeta>
          <PageMetaContent>
            <PageTitle>For Owners</PageTitle>
          </PageMetaContent>
        </PageMeta>

        {isSearching && <PageLoadingOverlay />}

        <PageContent>
          <PageSection marginPhone="no no lMedium">
            <PageSectionTitle margin="no no sMedium">Search for Your Community</PageSectionTitle>
            <SearchArea>
              <Search serviceCategory={undefined} forOwner embed />
            </SearchArea>

            {isSearchCompleted && <CommunityList totalCount={totalCount} communities={results} forOwner />}
          </PageSection>

          <PageSection marginPhone="no no lMedium">
            <PageSectionTitle>What you can do as an Owner</PageSectionTitle>
            <PageSectionDescription>
              As an owner, you will be able to claim your community and manage your online presence on the Loved Ones
              First platform. Once you claim your community, we will review your request and grant access so you can
              edit the community details such as amenities and services offered as well as upload photos.
            </PageSectionDescription>
          </PageSection>
        </PageContent>
      </Fragment>
    );
  }
}

OwnersPage.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  isSearchCompleted: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  results: PropTypes.array.isRequired,
};

export default OwnersPage;
