import React from 'react';
import PropTypes from 'prop-types';
import { ButtonSet, ButtonLink, Text, Link } from '../../core/components/styled';
import {
  ClaimProperty as ClaimPropertyContainer,
  ClaimPropertyTitle,
  ClaimPropertyDescription,
  ClaimPropertyDisclosure,
  ClaimPropertyDisclosureImportant,
} from './styled';
import { getBackofficeBaseURL } from '../../core/services/environment';

const ClaimProperty = ({ communityId, isClaimed }) => (
  <ClaimPropertyContainer>
    <ClaimPropertyTitle>Is this your community?</ClaimPropertyTitle>
    <ClaimPropertyDescription>Claim to manage details. </ClaimPropertyDescription>

    <ButtonSet margin="sMedium no">
      <ButtonLink as="a" fluid color="primary" href={`${getBackofficeBaseURL()}/providers/${communityId}/claim`}>
        Claim Community
      </ButtonLink>
    </ButtonSet>

    {isClaimed && (
      <React.Fragment>
        <ClaimPropertyDisclosureImportant>
          This community has already been claimed, please email admin@lovedonesfirst.com with any change requests.
        </ClaimPropertyDisclosureImportant>
        <br />
      </React.Fragment>
    )}
    <ClaimPropertyDisclosure>
      By clicking on <Text italic>Claim Community</Text> above, you agree to our{' '}
      <Link to="/terms-of-use" target="_blank">
        Terms of Use
      </Link>
      {', '}
      <Link to="/submission-policy" target="_blank">
        Submission Policy
      </Link>
      {', '}
      and to our{' '}
      <Link to="/privacy-policy" target="_blank">
        Privacy Policy
      </Link>{' '}
      regarding the information relating to you.
    </ClaimPropertyDisclosure>
  </ClaimPropertyContainer>
);

ClaimProperty.propTypes = {
  communityId: PropTypes.number.isRequired,
  isClaimed: PropTypes.bool.isRequired,
};

export default ClaimProperty;
