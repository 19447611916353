import React from 'react';
import PropTypes from 'prop-types';
import { Radio as RadioContainer, RadioInput, RadioCheck, RadioText, FormGroup, FormError } from './styled';

const RadioGroup = ({ field, form, radios, size, disabled, margin, ...props }) => {
  const { name, value, onChange, onBlur } = field;
  const { touched, errors } = form;

  return (
    <FormGroup margin={margin}>
      {radios.map(radio => (
        <RadioContainer key={radio.value} isDisabled={disabled} block margin="no no small">
          <RadioInput
            name={name}
            id={radio.value}
            type="radio"
            value={radio.value}
            checked={value === radio.value}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            {...props}
          />

          <RadioCheck />
          <RadioText>{radio.label}</RadioText>
        </RadioContainer>
      ))}

      {touched[field.name] && errors[field.name] && <FormError>{errors[field.name]}</FormError>}
    </FormGroup>
  );
};

RadioGroup.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  radios: PropTypes.array.isRequired,
  size: PropTypes.string,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
};

RadioGroup.defaultProps = {
  size: undefined,
  block: false,
  disabled: undefined,
  margin: undefined,
};

export default RadioGroup;
