import * as Yup from 'yup';
import { phoneRegex } from '../../common/regexps';

const scheduleConsultationSchema = Yup.object().shape({
  firstName: Yup.string().required('Please provide your first name'),
  lastName: Yup.string().required('Please provide your last name'),

  email: Yup.string()
    .email('Please provide a valid email')
    .required('Please provide your email'),

  phone: Yup.string()
    .matches(phoneRegex, 'Please provide a valid phone number')
    .required('Please provide your phone number'),
});

export default scheduleConsultationSchema;
