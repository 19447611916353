import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Text, Link } from '../../core/components/styled';
import { requestInformationSchema } from '../schemas';
import {
  RequestInformation as RequestInformationContainer,
  RequestInformationTitle,
  RequestInformationDescription,
  RequestInformationDescriptionLink,
  RequestInformationDisclosure,
} from './styled';
import { RequestInformationForm } from './forms';

const formInitialValues = {
  fullName: '',
  email: '',
  phone: '',
};

const RequestInformation = ({ handleSubmit }) => (
  <RequestInformationContainer>
    <RequestInformationTitle>Want more personalized service?</RequestInformationTitle>
    <RequestInformationDescription>
      Please call us at{' '}
      <RequestInformationDescriptionLink href="tel:888-888-3198">888-888-3198</RequestInformationDescriptionLink> or
      fill out the form below and we will reach out to you.
    </RequestInformationDescription>

    <Formik
      onSubmit={handleSubmit}
      render={RequestInformationForm}
      initialValues={formInitialValues}
      validationSchema={requestInformationSchema}
    />

    <RequestInformationDisclosure>
      By clicking on <Text italic>Request Info</Text> above, you agree to our{' '}
      <Link to="/terms-of-use" target="_blank">
        Terms of Use
      </Link>{' '}
      and to our{' '}
      <Link to="/privacy-policy" target="_blank">
        Privacy Policy
      </Link>{' '}
      regarding the information relating to you. You also consent to a Lifelong Senior Care Consultant reaching out to
      you with information by email and/or telephone
    </RequestInformationDisclosure>
  </RequestInformationContainer>
);

RequestInformation.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default RequestInformation;
