import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../core/components/styled';
import {
  ScheduleConsultationBanner as ScheduleConsultationBannerContainer,
  ScheduleConsultationBannerText,
} from './styled';

const ScheduleConsultationBanner = () => (
  <ScheduleConsultationBannerContainer>
    <ScheduleConsultationBannerText>Available now to schedule your consultation</ScheduleConsultationBannerText>
    <Button as={Link} to="/schedule-consultation" color="primary">
      Schedule a consultation
    </Button>
  </ScheduleConsultationBannerContainer>
);

export default ScheduleConsultationBanner;
