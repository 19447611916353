import styled from 'styled-components';

const LegalDocument = styled.div`
  line-height: 26px;
  font-size: 16px;

  h3 {
    margin: 0 0 15px;
    line-height: 28px;
    font-size: 18px;
  }

  h4 {
    margin: 0 0 15px;
    line-height: 26px;
    font-size: 16px;
  }

  p {
    margin: 0 0 10px;
  }

  ol,
  ul {
    margin: 0 0 20px;
    padding-left: 30px;
  }

  li {
    margin-bottom: 10px;
  }
`;

export default LegalDocument;
