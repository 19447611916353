import styled from 'styled-components';
import { mapper, sizeMapper, pulse } from '../../styles';

export const LoadingDot = styled.span`
  display: inline-block;
  width: 25%;
  padding-top: 25%;
  background-color: ${props => props.theme.brandDefault};
  border-radius: 50%;
  animation: ${pulse} ease-out 1s infinite;

  &:nth-child(2) {
    animation-delay: 0.1s;
  }

  &:nth-child(3) {
    animation-delay: 0.2s;
  }
`;

export const Loading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => mapper(props.size, { small: '15px', medium: '20px', large: '25px' }, 'small')};
  margin: ${props => sizeMapper(props.margin, 'no')};
`;
