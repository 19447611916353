import React from 'react';
import PropTypes from 'prop-types';
import { VerticalNavigationToggle as VerticalNavigationToggleContainer, VerticalNavigationToggleLine } from './styled';

const VerticalNavigationToggle = ({ toggle, isOpen }) => (
  <VerticalNavigationToggleContainer isOpen={isOpen} onClick={toggle}>
    <VerticalNavigationToggleLine />
    <VerticalNavigationToggleLine />
    <VerticalNavigationToggleLine />
  </VerticalNavigationToggleContainer>
);

VerticalNavigationToggle.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default VerticalNavigationToggle;
