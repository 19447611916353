import React from 'react';
import PropTypes from 'prop-types';
import { createCommunityUrl } from '../helpers';
import {
  CommunityListItem as CommunityListItemContainer,
  CommunityListItemImageLink,
  CommunityListItemImage,
  CommunityListItemDetails,
  CommunityListItemTitle,
  CommunityListItemAddress,
  CommunityListItemCall,
  CommunityListItemCallLabel,
  CommunityListItemCallPhoneNumber,
  CommunityListItemClaim,
  CommunityListItemClaimLink,
} from './styled';
import communityPlaceholderImage from '../assets/img/communityPlaceholder.png';

const CommunityListItem = ({ id, name, address, imageUrl, forOwner }) => {
  const communityUrl = createCommunityUrl(id, name, forOwner);

  return (
    <CommunityListItemContainer>
      <CommunityListItemImageLink to={communityUrl}>
        <CommunityListItemImage src={imageUrl || communityPlaceholderImage} />
      </CommunityListItemImageLink>
      <CommunityListItemDetails>
        <CommunityListItemTitle to={communityUrl}>{name}</CommunityListItemTitle>
        <CommunityListItemAddress>{address.formattedAddress}</CommunityListItemAddress>

        {!forOwner && (
          <CommunityListItemCall>
            <CommunityListItemCallLabel>Call for pricing</CommunityListItemCallLabel>
            <CommunityListItemCallPhoneNumber>888-888-3198</CommunityListItemCallPhoneNumber>
          </CommunityListItemCall>
        )}

        {forOwner && (
          <CommunityListItemClaim>
            <CommunityListItemClaimLink to={communityUrl}>Claim community</CommunityListItemClaimLink>
          </CommunityListItemClaim>
        )}
      </CommunityListItemDetails>
    </CommunityListItemContainer>
  );
};

CommunityListItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
  imageUrl: PropTypes.string,
  forOwner: PropTypes.bool,
};

CommunityListItem.defaultProps = {
  imageUrl: undefined,
  forOwner: false,
};

export default CommunityListItem;
