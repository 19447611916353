import React, { PureComponent } from 'react';

const containerElementStyle = { height: '100%' };
const mapElementStyle = { height: '100%' };

const containerElement = <div style={containerElementStyle} />;
const mapElement = <div style={mapElementStyle} />;

const googleMap = Component =>
  class extends PureComponent {
    render() {
      return <Component {...this.props} containerElement={containerElement} mapElement={mapElement} />;
    }
  };

export default googleMap;
