const THEME = {
  grayBase: '#242833',
  grayDarker: '#717179',
  grayDark: '#a4a4b4',
  gray: '#d9d9e1',
  grayLight: '#f2f2f5',
  grayLighter: '#fafafc',

  red: '#c31f1f',
  orange: '#ff8154',
  yellow: '#dfb416',
  green: '#5c8445',
  blue: '#2d5781',
  purple: '#be286e',

  brandDefault: '#242833',
  brandPrimary: '#2d5781',
  brandSecondary: '#5c8445',
  brandSuccess: '#5c8445',
  brandInfo: '#0066a6',
  brandWarning: '#ffa452',
  brandAlert: '#ff5252',

  bodyBackgroundColor: '#fff',

  fontFamilyBase: 'Open Sans, Helvetica Neue, Helvetica, sans-serif',
  fontSizeBase: '14px',

  fontWeightLight: 300,
  fontWeightNormal: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
};

export default THEME;
