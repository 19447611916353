import produce from 'immer';
import { assign } from 'lodash-es';
import { requestInformation as doRequestInformation } from '../services';

// Actions
const START_REQUEST = 'community/requestInformation/START_REQUEST';
const COMPLETE_REQUEST = 'community/requestInformation/COMPLETE_REQUEST';
const FAIL_REQUEST = 'community/requestInformation/FAIL_REQUEST';
const RESET = 'community/requestInformation/RESET';

// Initial state
const initialState = {
  isRequesting: false,
  isRequestCompleted: false,
  isRequestFailed: false,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return produce(state, nextState => {
        nextState.isRequesting = true;
      });

    case COMPLETE_REQUEST:
      return produce(state, nextState => {
        nextState.isRequesting = false;
        nextState.isRequestCompleted = true;
        nextState.isRequestFailed = false;
      });

    case FAIL_REQUEST:
      return produce(state, nextState => {
        nextState.isRequesting = false;
        nextState.isRequestCompleted = false;
        nextState.isRequestFailed = true;
      });

    case RESET:
      return produce(state, nextState => {
        assign(nextState, indexedDB);
      });

    default:
      return state;
  }
};

// Action creators
const startRequest = () => ({
  type: START_REQUEST,
});

const completeRequest = () => ({
  type: COMPLETE_REQUEST,
});

const failRequest = () => ({
  type: FAIL_REQUEST,
});

export const requestInformation = values => dispatch => {
  dispatch(startRequest());

  const requestInformationPromise = doRequestInformation(values);
  requestInformationPromise.then(() => dispatch(completeRequest())).catch(() => dispatch(failRequest()));
  return requestInformationPromise;
};

export const resetRequestInformation = () => ({
  type: RESET,
});
