import { keyframes } from 'styled-components';

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const zoomIn = keyframes`
  from {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  to {
    transform: none;
  }
`;

export const fadeAndZoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

export const zoomOut = keyframes`
  from {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    transform: none;
  }
`;

export const fadeAndZoomOut = keyframes`
  from {
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

export const pulse = keyframes`
  0% {
    transform: scale3d(0, 0, 0)
  }

  50% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform: scale3d(0, 0, 0)
  }
`;

export const slideInUp = keyframes`
  from {
    transform: translate3d(0, 15px, 0);
  }

  to {
    transform: none;
  }
`;

export const fadeAndSlideInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

export const slideInDown = keyframes`
  from {
    transform: translate3d(0, -15px, 0);
  }

  to {
    transform: none;
  }
`;

export const fadeAndSlideInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -15px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

export const slideOutDown = keyframes`
  from {
    transform: none;
  }

  to {
    transform: translate3d(0, -15px, 0);
  }
`;

export const fadeAndSlideOutDown = keyframes`
  from {
    transform: none;
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -15px, 0);
  }
`;

export const slideInRightFull = keyframes`
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: none;
  }
`;

export const fadeAndSlideInRightFull = keyframes`
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

export const slideOutRightFull = keyframes`
  from {
    transform: none;
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
`;

export const fadeAndSlideOutRightFull = keyframes`
  from {
    opacity: 1;
    transform: none;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
`;
