import styled from 'styled-components';

export const SurveyCitationSectionTitle = styled.span`
  display: block;
  margin-bottom: 2px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 14px;
`;

export const SurveyCitationSectionDescription = styled.span`
  display: block;
  line-height: 24px;
  font-size: 15px;
`;

export const SurveyCitationSection = styled.div`
  display: block;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SurveyCitation = styled.div`
  display: block;
  padding: 20px 0;
  border-bottom: 1px solid ${props => props.theme.gray};

  &:last-child {
    border-bottom: none;
  }
`;

export const SurveyCitations = styled.div`
  display: block;
  border: solid ${props => props.theme.gray};
  border-width: 1px 0;

  &:last-child {
    border-bottom: none;
  }
`;

export const SurveyDescription = styled.span`
  display: block;
  font-size: 16px;
`;

export const Survey = styled.div``;
