const modernImage = (image, image2x, imageWebP, imageWebP2x, backgroundSize) => {
  const isWebPSupport = window.Modernizr && window.Modernizr.webp;
  return `
  background-image: url(${isWebPSupport ? imageWebP : image});
  background-size: ${backgroundSize};

  @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
    only screen and (min-resolution: 124.8dpi){ 
      background-image: url(${isWebPSupport ? imageWebP2x : image2x});
  }
`;
};

export default modernImage;
