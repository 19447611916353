import GoogleAnalyticsGtag, { trackPageView } from '@redux-beacon/google-analytics-gtag';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createMiddleware } from 'redux-beacon';
import { GOOGLE_TRACKING_ID } from '../constants';
import { getBaseUrl } from '../services';

const eventsMap = {
  [LOCATION_CHANGE]: trackPageView(action => ({
    path: action.payload.location.pathname,
    location: `${getBaseUrl()}${action.payload.location.pathname}`,
    title: '',
  })),
};

const ga = GoogleAnalyticsGtag(GOOGLE_TRACKING_ID);

const gaMiddleware = createMiddleware(eventsMap, ga);

export default gaMiddleware;
