import styled, { css } from 'styled-components';
import { Icon } from '../../../core/components';

export const ContactDetailValue = styled.span`
  display: block;
  font-size: 16px;
  color: ${props => props.theme.grayDarker};

  ${props =>
    props.link &&
    css`
      color: ${props => props.theme.brandPrimary};
    `};
`;

export const ContactDetailLabel = styled.span`
  display: block;
  margin-bottom: 8px;
  font-weight: ${props => props.theme.fontWeightMedium};
`;

export const ContactDetailContent = styled.div`
  flex: 1;
`;

export const ContactDetailIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-right: 15px;
  color: ${props => props.theme.brandPrimary};
`;

export const ContactDetail = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
`;
