import * as Yup from 'yup';
import { phoneRegex } from '../../common/regexps';

const scheduleConsultationSchema = Yup.object().shape({
  firstName: Yup.string().required('Please provide your first name'),
  lastName: Yup.string().required('Please provide your last name'),

  email: Yup.string()
    .email('Please provide a valid email')
    .required('Please provide your email'),

  phone: Yup.string()
    .matches(phoneRegex, 'Please provide a valid phone number')
    .required('Please provide your phone number'),

  relationshipToResident: Yup.string().required('Please provide your relationship to the resident'),
  residentFirstName: Yup.string().required("Please provide resident's first name"),
  residentLastName: Yup.string().required("Please provide resident's last name"),
  communityType: Yup.string().required('Please choose an option'),
  paymentType: Yup.string().required('Please choose an option'),
  bestTimeToContact: Yup.string().required('Please choose an option'),
  howSoonForPlacement: Yup.string().required('Please choose an option'),
});

export default scheduleConsultationSchema;
