/* global google */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Marker, InfoWindow } from 'react-google-maps';
import { MapInfoWindowContent, MapInfoWindowTitle, MapInfoWindowText } from './styled';
import mapPin from '../assets/img/mapPin.svg';

class MapMarker extends PureComponent {
  toggleInfoWindow = () => {
    const { location, toggleInfoWindow } = this.props;
    toggleInfoWindow(location.id);
  };

  render() {
    const { isInfoWindowOpen, location } = this.props;
    const { name, address, coordinates } = location;
    const { latitude, longitude } = coordinates;

    const markerPosition = { lat: latitude, lng: longitude };
    const icon = { url: mapPin, scaledSize: new google.maps.Size(34, 40) };

    return (
      <Marker icon={icon} position={markerPosition} onClick={this.toggleInfoWindow} noRedraw>
        {isInfoWindowOpen && (
          <InfoWindow onCloseClick={this.toggleInfoWindow}>
            <MapInfoWindowContent>
              <MapInfoWindowTitle>{name}</MapInfoWindowTitle>
              <MapInfoWindowText>{address.formattedAddress}</MapInfoWindowText>
            </MapInfoWindowContent>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

MapMarker.propTypes = {
  location: PropTypes.object.isRequired,
  isInfoWindowOpen: PropTypes.bool.isRequired,
  toggleInfoWindow: PropTypes.func.isRequired,
};

export default MapMarker;
