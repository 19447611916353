import React, { Fragment } from 'react';
import { PageMeta, PageContent, PageTitle, PageMetaContent } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { LegalDocument } from '../styled';

const TermsOfUsePage = () => (
  <Fragment>
    <DocumentTitle>Terms of Use</DocumentTitle>
    <PageMeta>
      <PageMetaContent>
        <PageTitle>Terms of Use</PageTitle>
      </PageMetaContent>
    </PageMeta>

    <PageContent>
      <LegalDocument>
        <h4>Last updated on September 16, 2016.</h4>
        <p>
          The terms and conditions of the Terms of Use are effective immediately and apply to all visitors to and
          account holders at LovedOnesFirst.com. To view previous versions of the Terms of Use,&nbsp;
          <a href="/Loved Ones First/previous-terms-of-use-agreements">click here</a>.
        </p>
        <ol type="1">
          <li>
            <h3>RECITALS</h3>
            <p>
              Welcome to the LovedOnesFirst.com Website and related applications (collectively,&nbsp;
              <strong>“Website”</strong>). This Website is provided solely to assist our visitors in gathering elder
              care information, posting opinions of elder care and senior living related issues, and searching for and
              finding elder care services, products, housing, and other things from time to time that we believe may be
              of use to the retirement-age community in general. The terms&nbsp;<strong>“we”</strong>,&nbsp;
              <strong>“us”</strong>
              ,&nbsp;<strong>“our”</strong>&nbsp;and&nbsp;<strong>“Loved Ones First”</strong>&nbsp;refer to Loved Ones
              First, LLC., an Arizona corporation, and our subsidiaries and affiliates. The term&nbsp;
              <strong>“you”</strong>
              &nbsp;refers to any visitor, customer, or provider of goods, services, advertising, or any other form of
              interaction who visits, accesses, or contributes content to this Website. The term&nbsp;
              <strong>“Affiliate”</strong>&nbsp;means, with respect to any party, any other corporation, partnership, or
              other entity that directly or indirectly controls, is controlled by, or is under common control with such
              party.
            </p>
          </li>
          <li>
            <h3>TO USE THE WEBSITE YOU MUST AGREE TO THE TERMS OF USE</h3>
            <p>
              Your use of this Website is offered solely on the condition that you accept and agree to be bound by all
              terms, conditions, and any other notices, documents or texts referenced below (collectively, the&nbsp;
              <strong>“Terms of Use”</strong>). By using this Website in any manner, you agree to be bound by the Term
              of Use and represent that you have read and understood its terms.&nbsp;
              <strong>
                Please read the Terms of Use carefully, as it contains information concerning your legal rights and
                limitations on these rights
              </strong>
              . If you do not accept all terms and conditions found within the Terms of Use, please leave this Website
              immediately. We hope you come back when you are feeling more agreeable.
            </p>
          </li>
          <li>
            <h3>CHANGES</h3>
            <p>
              In the future, we expect to change or otherwise modify the Terms of Use as our needs change and grow along
              with the services and products that we offer. You understand, accept, and agree that any future use of
              this Website after we have made such changes or modifications means that you will have accepted the
              revised Terms of Use. We will be sure to note the date of each new version of the Terms of Use at the top
              of the page, and that date will become the effective date of the revised Terms of Use. If any material
              changes are made to the Terms of Use in the future, we will notify visitors through the posting of a
              notice on the Website. Please be sure to make a habit of periodically reviewing the most current version
              of the Terms of Use each time you visit the Website.
            </p>
          </li>
          <li>
            <h3>USE OF THE WEBSITE</h3>
            <p>
              Anyone may freely browse through this Website, subject to the terms and conditions in the Terms of Use.
              However, to access some features offered through this Website, such as making comments, leaving reviews,
              uploading content, taking advantage of promotions, and of course listing or describing your own Business,
              you must create an account and provide certain information about yourself, as described below.
            </p>
          </li>
          <li>
            <h3>WARRANTIES</h3>
            <p>
              To “warrant” something is a technical legal term that basically means that you promise that you will do or
              not do something (the thing warranted), or that anything you say or assert is entirely true and reliable.
              Accordingly, by your use of this Website, you warrant that (i) all information supplied by you on this
              Website is true, accurate, current and complete; (ii) if you have a Loved Ones First account, you will
              safeguard your account information and will supervise and be completely responsible for any use of your
              account by anyone other than you, and that you will update the information in your account within a
              reasonable time after any such information changes; and (iv) you are 18 years of age or older in order to
              register for an account and contribute to our Website. Loved Ones First does not knowingly collect the
              information of anyone under the age of 18.
            </p>
          </li>
          <li>
            <h3>TERMINATION</h3>
            <p>
              Our offering of this Website to any person is solely at our own discretion, and we reserve the right to
              deny access to this Website to anyone for any reason, especially for but not limited to violation of the
              Terms of Use, without notice or liability of any kind. Please just be nice, and we will, too. If you do
              not agree with the Terms of Use and desire to terminate this agreement, you may do so by sending us sixty
              (60) day’s written notice of termination&nbsp;
              <a href="/Loved Ones First/partners/cancel-contract">here</a>. Upon termination of this agreement by
              either you or us, we may choose to keep and use any, all, or no information acquired from or about you,
              subject to our Privacy Policy,&nbsp;
              <a href="/Loved Ones First/privacy-policy">here</a>, and also any, all, or none of your Submitted Content,
              indefinitely. Moreover, Sections 1 and 5 through 24, inclusively, shall continue in full force and effect
              for as long as the law permits.
            </p>
          </li>
          <li>
            <h3>PROHIBITED ACTIVITIES</h3>
            <p>
              This Website relies upon user-generated and user-uploaded content in addition to information supplied by
              third parties. We have designed this Website to allow you and others to share information liberally and
              honestly, so that others may benefit from your and their experiences and knowledge. We have also put a ton
              of work into making this Website as great as it is. Accordingly, we have quite a few rules regarding what
              type of information you may share with us, how we may use it, and also which things you may obtain from
              this Website. Please note, however, that if you have a problem with another user or the content that he or
              she submits, we are under no obligation to help you enforce the terms of the Terms of Use against that
              user. If you report something to us, we may or may not investigate it or take any action to remedy it, and
              this decision is at our sole discretion.
            </p>
            <p>
              All of the content on this Website either belongs to us or we have the legal right to use it and share it.
              You, however, own none of it except for that which you create for and submit to us, and even then, by
              submitting any content or information to us, you give us unlimited permission to use it however we want.
              Therefore, by visiting this Website, you agree that anything you see on this Website (including but not
              limited to information, text, data, pictures, graphics, icons, videos, charts, statistics, music, sounds,
              and even the infrastructure used to store and share this content such as software, apps, code, and
              virtually if not literally anything you can lay your eyes on, click on, copy, or download) is proprietary
              to us. You also agree that you will not copy, download, modify, publish, distribute, transmit, broadcast,
              perform, reproduce, display, sell, re-sell, license, create derivative works from, or in any way interfere
              with our legal rights to own or use anything obtained from or through this Website, including information,
              services, goods, products, apps, software, or any other type of content found on this Website, even if it
              is in a format that has not as of this date been invented or brought to market. In short, you agree to
              keep your hands off our stuff.
            </p>
            <p>
              Further, by visiting this Website you also agree that you will not do or participate in, or even watch
              someone else do, or coach someone to do, any of the following:
            </p>
            <ol type="i">
              <li>
                use the Website or any of its contents for any commercial purpose, unless we and you have entered into a
                separate written contract agreeing in particular to that commercial purpose and use, in which case you
                will refrain from any commercial use outside of the scope of such a contract or contracts and the Terms
                of Use;
              </li>
              <li>
                link to any page, segment, or other part of this Website, other than our home page, without our prior
                express written permission in the form of another document that is not the Terms of Use;
              </li>
              <li>
                cause any part of this Website to load in another website or otherwise cause or allow someone to see the
                content on this Website by accessing any other website but this, including through any type of
                incorporation of our content through methods known as framing, mirroring, or any similar method, without
                our prior express written permission in the form of another document that is not the Terms of Use;
              </li>
              <li>
                use robots, spiders, scrapers, or any automated process or even human workers or volunteers to access
                this Website and monitor, copy, or index content from it for any purpose, without our prior express
                written permission in the form of another document that is not the Terms of Use;
              </li>
              <li>
                attempt to circumvent, bypass, violate, or dismantle any measures used on this Website to control access
                by others, such as robot exclusion measures, account protection measures, spam filters, server access
                restriction, IP address blocking, or any other method of accessing this Website for any purpose other
                than for your individual education or to contribute constructively to the Website as delineated
                specifically in the Terms of Use;
              </li>
              <li>
                attempt to hack, attack, overload, overwhelm, modify, translate, reverse engineer, deny service to, or
                utilize any other method that a reasonable person in a reasonable society would deem immoral, shady, or
                just plain lame of you, to interrupt or interfere with Loved Ones First’s offering this Website to
                decent people and to keep to ourselves all information and content that we have had to produce in order
                to do just that. You already agreed to keep your hands off our stuff, and that includes not making it
                harder for ordinary people to read and enjoy our stuff.
              </li>
            </ol>
          </li>
          <li>
            <h3>PRIVACY POLICY AND DISCLOSURES</h3>
            <p>
              Loved Ones First believes in protecting your privacy, especially because there are so many unsavory people
              out there these days looking to prey upon our Elders. Our current Privacy Policy, which also governs your
              use of this Website, is found&nbsp;<a href="/Loved Ones First/privacy-policy">here</a>.
            </p>
          </li>
          <li>
            <h3>SUBMITTED CONTENT</h3>
            <p>
              We could not do what we do without the greatly appreciated input from our visitors, vendors, and
              third-party suppliers. We thank you in advance for taking the time to share your experiences, opinions,
              knowledge, and data. Of course, for us to be able to share all this great information and content with
              others, we need to set a few guidelines on Submitted Content, and before you submit any, we need you to
              agree to all of the following terms and conditions. By submitting any content including reviews, opinions,
              data, photographs, videos, statistics, ratings, rankings, postings, emails, messages, social media
              references, links, suggestions, ideas, complaints, sound recordings, code, apps, software, or any form of
              visual or audio communication or depiction (collectively,&nbsp;<strong>“Submitted Content”</strong>) to
              this Website, you agree to all of the following, individually and corporately:
            </p>
            <ol type="i">
              <li>
                You grant this Website, Loved Ones First and Loved Ones First’s affiliates a nonexclusive, royalty-free,
                perpetual, transferable, irrevocable and fully sub-licensable right to use, reproduce, modify, destroy,
                distribute, publish, comment upon, create derivative works from and publicly display and perform such
                Submitted Content throughout the world and universe in any media, now known or devised in the future,
                for any purpose, including commercial purposes, and to use the name that you submit in connection with
                such Submitted Content.
              </li>
              <li>
                You agree to let this Website choose whether or not to provide attribution of your Submitted Content, at
                our sole discretion.
              </li>
              <li>
                You grant Loved Ones First the right to pursue legal action against any person or entity who violates
                your or our rights in any Submitted Content by a breach of this Agreement; however, you acknowledge and
                agree that Loved Ones First shall not be responsible to take any legal action, or even enforce the terms
                and conditions of the Terms of Use, on your behalf against a another visitor, user, or third party
                supplier.
              </li>
              <li>
                You agree that any Submitted Content you share is non-confidential and non-proprietary, and that you own
                completely, or have been granted an appropriate and non-terminable license to use, all Submitted
                Content, and that you will indemnify Loved Ones First and its affiliates of any costs incurred by us in
                defense of any claims of others to the contrary.
              </li>
              <li>
                You expressly agree not to post, upload to, transmit, distribute, store, create or otherwise publish
                through this Website any Submitted Content that:
                <ol type="a">
                  <li>
                    is false, misleading, or tending to mislead in any way, with regard to the condition of, experience
                    with or at, or circumstances surrounding, any property listed or goods or services sold by or
                    provided by any business listed or described on this Website, or any customer of or visitor to any
                    such business;
                  </li>
                  <li>
                    we or another might find objectionable, including but not limited to anything that is false,
                    unlawful, defamatory, obscene, pornographic, indecent, suggestive, harassing, threatening, invasive
                    of privacy or publicity rights, abusive, fraudulent, or that a reasonable person simply might not
                    like;
                  </li>
                  <li>
                    might result in, lead to, or be the commission of a civil or criminal offense by you or anybody
                    else, or violates the rights of any party, or that might otherwise create civil or criminal
                    liability or violate any local, state, federal, or international law or treaty;
                  </li>
                  <li>
                    may infringe any patent, trademark, trade secret, copyright or other intellectual or proprietary
                    right of any party;
                  </li>
                  <li>
                    misrepresents or tends to mislead anyone with regard to your identity or affiliation with any person
                    or entity, public or private, including Loved Ones First;
                  </li>
                  <li>
                    consists of or informs visitors about promotions, political campaigning, advertising, contests,
                    raffles, or solicitations not elsewhere agreed to in writing between you and this Website;
                  </li>
                  <li>
                    contains the private information of any party that is not you, including but not limited to any
                    names, addresses, phone numbers, email addresses, Social Security numbers and credit card numbers;
                  </li>
                  <li>
                    is a virus, malware, worm, Trojan horse, set of corrupted data, or other harmful, disruptive,
                    destructive, or unwanted file; or
                  </li>
                  <li>
                    in the sole judgment of this Website is objectionable, distasteful, or which may expose Loved Ones
                    First or its affiliates or its users to any harm or liability of any type, no matter how likely or
                    unlikely the occurrence of such harm or liability may be.
                  </li>
                </ol>
              </li>
              <li>
                You agree to be responsible for maintaining your own copies of Submitted Content, and to hold harmless
                this Website for anything that happens to your Submitted Content that displeases you, including but not
                limited to the loss, destruction, modification, derivative works created from you Submitted Content, or
                insult, embarrassment, or any other unpleasant consequences of your Submitted Content’s being shared
                with or by this Website.
              </li>
              <li>
                You acknowledge, understand, and accept that on this Website you may view, hear, or otherwise experience
                others’ Submitted Content that you find offensive or injurious, and you agree to hold harmless this
                Website for any harm that you experience or perceive that you experience based on any mistakes,
                falsehoods, omissions, defamation, obscenity, pornography, profanity, insults, blasphemies, or any other
                unpleasant content that you may encounter.
              </li>
              <li>
                You agree and accept that this Website shall not be liable for any statements, representations or
                content provided by its users in any public or private forum, personal home page or other medium,
                whether on this Website or elsewhere. Although Loved Ones First has no obligation to screen, edit or
                monitor any of the Content posted to or distributed through this Website, Loved Ones First reserves the
                right, and has absolute discretion, to remove, screen, translate or edit without notice any Content
                posted or stored on this Website at any time and for any reason, or to have such actions performed by
                third parties on our behalf. Any failure or forbearance in this matter on our part shall not be taken as
                any indication that any content found on this Website reflects the opinion of Loved Ones First or its
                affiliates.
              </li>
              <li>
                forever waive and agree not to claim or assert any entitlement to any moral rights of an author in any
                of the Submitted Content; and you forever release Loved Ones First, and its licensees, successors and
                assigns, from any claims that you could otherwise assert against Loved Ones First by virtue of any such
                moral rights.
              </li>
              <li>
                You warrant that,&nbsp;
                <strong>
                  before sharing any Submitted Content, you will read and familiarize yourself with our Submission
                  Policy
                </strong>
                .
              </li>
            </ol>
          </li>
          <li>
            <h3>INTERACTING WITH THIRD PARTIES THROUGH Loved Ones First</h3>
            <p>
              Loved Ones First offers you the ability to search for, select, and contact third-party suppliers, often
              without leaving the Website. By interacting with third parties through this Website, as well as through
              the use of links to third party web sites, which Loved Ones First does not operate, control, or promise to
              review, you acknowledge that you accept the practices described in our&nbsp;
              <a href="/Loved Ones First/privacy-policy">Privacy Policy</a>, all of the terms and conditions in the
              Terms of Use, as well as any privacy policies and terms and conditions of third party websites that you
              choose to contact. You further acknowledge and agree that Loved Ones First is not responsible for any
              content of any website that we link to, or any links contained within that third party website.
            </p>
          </li>
          <li>
            <h3>THIRD-PARTY SUPPLIERS OF GOODS AND SERVICES</h3>
            <p>
              Loved Ones First does not provide or own any of the properties, goods, or services found on this Website,
              with the sole exception being that we provide the service of informing you about and placing you in
              contact with some of the businesses that do own and provide such properties, goods, and services. Although
              Loved Ones First displays information about properties owned by third-party suppliers and facilitates with
              certain suppliers and on certain affiliate sites, such actions are merely transactional in nature and do
              not in any way imply, suggest, or constitute our sponsorship of, approval of, relationship between, or
              affiliation with any third party.
            </p>
            <p>
              Although this Website’s visitors rate, review, and share their personal opinions regarding various goods,
              properties, and services found on this Website,&nbsp;
              <strong>
                Loved Ones First neither endorses, recommends, nor guarantees the quality of any products or services of
                any third-party supplier. YOU AGREE THAT Loved Ones First IS NOT RESPONSIBLE FOR THE ACCURACY OR
                COMPLETENESS OF INFORMATION IT OBTAINS FROM THIRD-PARTY SUPPLIERS AND DISPLAYS ON ITS SITES OR APPS, AND
                THAT NO SUCH INFORMATION NECESSARILY REFLECTS Loved Ones First’S OPINION OR POLICIES
              </strong>
              .
            </p>
          </li>
          <li>
            <h3>LIABILITY DISCLAIMER</h3>
            <p>
              <strong>
                PLEASE READ THIS SECTION CAREFULLY, AS IT LIMITS Loved Ones First’S POTENTIAL LIABILITY TO YOU. IF YOU
                DO NOT UNDERSTAND THE TERMS IN THIS SECTION OR ELSEWHERE IN THE TERMS OF USE, PLEASE CONSULT A LAWYER
                FOR CLARIFICATION BEFORE ACCESSING OR USING THIS WEBSITE
              </strong>
              .
            </p>
            <ol type="a">
              <li>
                THE INFORMATION PUBLISHED ON THIS WEBSITE MAY INCLUDE INACCURACIES OR ERRORS, INCLUDING INFORMATION
                ABOUT PRICING, PRODUCTS, SERVICES, OR PROPERTIES. Loved Ones First DOES NOT GUARANTEE THE ACCURACY OF,
                AND DISCLAIMS ALL LIABILITY FOR, ANY ERRORS OR OTHER INACCURACIES RELATING TO THE INFORMATION AND
                DESCRIPTION OF THE PRODUCTS, SERVICES, PROPERTIES, OR OTHER INFORMATION DISPLAYED ON THIS WEBSITE
                (INCLUDING BUT NOT LIMITED TO PRICING, AVAILABILITY, PHOTOGRAPHS, VIDEOS, MAPS, DEPICTIONS, PROPERTY
                AMENITIES, PRODUCT OR SERVICE DESCRIPTIONS, REVIEWS, RATINGS, HISTORY, AND SUITABILITY). IN ADDITION,
                Loved Ones First EXPRESSLY RESERVES THE RIGHT TO CORRECT ANY ERRORS ON OUR WEBSITE OR ON CONTRACTS MADE
                UNDER AN INCORRECT PRICE, WITH ANY DIFFERENCE RESULTING IN OUR FAVOR.
              </li>
              <li>
                THIS WEBSITE AND THE INFORMATION ON IT IS PRESENTED TO YOU ON AN “AS IS” BASIS, “AS AVAILABLE”, AND
                “WITH ALL FAULTS”. Loved Ones First MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY, FOR ANY PURPOSE, OF
                THE INFORMATION, PRODUCTS, AND SERVICES CONTAINED ON THIS WEBSITE, AND THE INCLUSION OR OFFERING OF ANY
                PRODUCTS OR SERVICES ON THIS WEBSITE DOES NOT CONSTITUTE ANY ENDORSEMENT OR RECOMMENDATION OF SUCH
                PRODUCTS OR SERVICES BY Loved Ones First. Loved Ones First HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR
                IMPLIED, FOUND ON THIS WEBSITE, INCLUDING BUT NOT LIMITED TO CONDITION AND QUALITY OF PRODUCTS,
                SERVICES, AND PROPERTIES, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS
                FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT. BY ACCESSING OR USING THIS WEBSITE, YOU AGREE TO
                GIVE Loved Ones First AND ITS AFFILIATES THE BENEFIT OF THE DOUBT REGARDING ANY INACCURACIES, UNLESS YOU
                CAN PROVE BY CLEAR AND CONVINCING EVIDENCE THAT Loved Ones First OR ITS AFFILIATES PURPOSELY PUBLISHED
                INACCURACIES WITH THE INTENT TO DECEIVE.
              </li>
              <li>
                THE THIRD-PARTY SUPPLIERS PROVIDING INFORMATION ABOUT PRODUCTS, SERVICES, OR PROPERTIES ON THIS WEBSITE
                ARE INDEPENDENT CONTRACTORS AND NOT AGENTS OR EMPLOYEES OF Loved Ones First OR ITS AFFILIATES. Loved
                Ones First IS NOT LIABLE FOR THE ACTS, ERRORS, OMISSIONS, REPRESENTATIONS, WARRANTIES, BREACHES OR
                NEGLIGENCE OF ANY SUCH SUPPLIERS OR FOR ANY PERSONAL INJURIES, DEATH, PROPERTY DAMAGE, OR OTHER DAMAGES
                OR EXPENSES RESULTING THEREFROM, INCLUDING CONTRACTUAL DAMAGES OR INJUNCTIVE RELIEF. MOREOVER, THE
                RIGHTS, PROTECTIONS, AND OBLIGATIONS ARISING FROM THE TERMS OF USE MAY NOT APPLY IN YOUR DEALINGS WITH
                THIRD PARTY SUPPLIERS.&nbsp;
                <strong>YOU MUST DO YOUR OWN DUE DILIGENCE IN DEALINGS WITH THIRD PARTY SUPPLIERS</strong>, INCLUDING
                FAMILIARIZING YOURSELF WITH ANY SUCH SUPPLIERS’ USER AGREEMENTS, TERMS OF USE, TERMS OF SERVICE, PRIVACY
                POLICIES, SALES OR BILLING POLICIES, OR ANY OTHER DOCUMENT OR POLICY RELATING TO YOUR INTERACTIONS OR
                TRANSACTIONS WITH THIRD PARTY SUPPLIERS.
              </li>
              <li>
                IN NO EVENT SHALL Loved Ones First BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR
                CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, BUSINESS INTERRUPTION, OR
                REPUTATIONAL HARM, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, YOUR ACCESS TO, DISPLAY OF OR USE OF
                THIS WEBSITE (INCLUDING, BUT NOT LIMITED TO, YOUR RELIANCE UPON REVIEWS AND OPINIONS APPEARING ON THIS
                WEBSITE; ANY COMPUTER VIRUSES, INFORMATION, SOFTWARE, LINKED SITES, PRODUCTS, AND SERVICES OBTAINED
                THROUGH THIS WEBSITE; OR OTHERWISE ARISING OUT OF THE ACCESS TO, DISPLAY OF OR USE OF THIS WEBSITE)
                WHETHER BASED ON A THEORY OF NEGLIGENCE, CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE.
              </li>
              <li>
                IF FOR ANY REASON Loved Ones First IS FOUND LIABLE FOR ANY LOSS OR DAMAGE THAT ARISES OUT OF OR IS IN
                ANY WAY CONNECTED WITH YOUR USE OF OUR WEBSITE OR SERVICES, THEN Loved Ones First’S LIABILITIES WILL IN
                NO EVENT EXCEED, IN THE AGGREGATE, THE GREATER OF (A) THE FEES YOU PAID TO Loved Ones First IN THE 12
                MONTHS PRIOR TO THE TRANSACTION OR TRANSACTIONS ON OR STEMMING FROM THE USE OF THIS WEBSITE THAT
                PRECIPITATE THE CLAIM, OR (B) ONE-HUNDRED UNITED STATES DOLLARS (US $100.00).
              </li>
              <li>
                BY USING THIS WEBSITE, YOU AGREE TO WAIVE YOUR RIGHT TO PURSUE A CLAIM IN COURT OR HAVE A JURY DECIDE
                THE CLAIM, AND YOU WAIVE THE RIGHT TO BRING OR PARTICIPATE IN ANY CLASS ACTION OR SIMILAR PROCEEDING IN
                COURT, INSTEAD, IN PURSUIT OF ANY CLAIM AGAINST US, YOU AGREE TO SUBMIT TO BINDING ARBITRATION AS
                ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION OR ITS SUCCESSOR OR ITS SUGGESTED ALTERNATIVE
                (THE&nbsp;
                <strong>“AAA”</strong>) AND CONDUCTED IN ACCORDANCE WITH THE AAA ARBITRATION RULES IN EFFECT AT THAT
                TIME. UNLESS WE AGREE OTHERWISE, THE AAA OR ITS ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S
                CLAIMS.
              </li>
              <li>
                IF FOR ANY REASON YOU ARE DISSATISFIED WITH THIS WEBSITE OR THE INFORMATION, PRODUCTS, SERVICES,
                PROPERTIES, SUBMITTED CONTENT FOUND HEREIN, OR IF YOU HAVE ANY OTHER GRIEVANCE, YOUR SOLE AND EXCLUSIVE
                RIGHT AND REMEDY SHALL BE TO TERMINATE THIS AGREEMENT, SUBJECT TO THE TERMS OF THE TERMS OF USE.
              </li>
              <li>
                <strong>
                  YOU FURTHERMORE AGREE TO INDEMNIFY AND HOLD HARMLESS Loved Ones First AND ITS ASSOCIATES FROM THE
                  COSTS, INCLUDING FINES, SANCTIONS, AND ATTORNEYS FEES, ASSOCIATED WITH THE DEFENSE OF ANY CLAIM OR
                  DEMAND PRECIPITATED BY YOUR USE OF THIS WEBSITE, OR THE VIOLATION OF ANY OF THE TERMS, CONDITIONS, OR
                  PROVISIONS OF THE TERMS OF USE
                </strong>
                .
              </li>
            </ol>
          </li>
          <li>
            <h3>MISCELLANEOUS</h3>
            <p>The following Sections also apply to the Terms of Use, as applicable:</p>
          </li>
          <li>
            <h3>NATURE OF RELATIONSHIP BETWEEN PARTIES</h3>
            <p>
              Nothing contained in the Terms of Use will be deemed to give rise to legal partnership or a joint venture
              between you, us, or any party or any party’s representatives or affiliates or to constitute any party or
              its representatives the agent or legal representative of any other party or to create any fiduciary
              relationship between the parties for any purpose whatsoever. Except as otherwise specifically provided in
              the Terms of Use, no party, nor any party’s representative, will have any authority to act for, bind,
              create, or assume any obligation or responsibility on behalf of the other party. If at any time we decide
              to waive partially this provision by offering a contractual relationship to any third party supplier, only
              the provision in this Section about the fiduciary relationship will be waived, and only within the scope
              specified in any contract between us and that third party supplier.
            </p>
          </li>
          <li>
            <h3>SEVERABILITY AND WAIVER</h3>
            <p>
              If any provision of the Terms of Use shall be found by any court, binding arbitration proceeding, or
              administrative body of competent jurisdiction to be invalid or unenforceable, such invalidity or on
              enforceability shall not affect the other provisions of the Terms of Use, which shall remain in force and
              effect. If any provision of the Terms of Use is accordingly found to be invalid or unenforceable but would
              be valid or enforceable if some part of the provision were deleted, the provision in question shall apply
              with such modifications as may be necessary to make it valid or enforceable. Any failure or forbearance on
              Loved Ones First’s part or behalf to exercise any right or enforce any provision in the Terms of Use shall
              not be deemed or construed as a waiver of any such right or provision, nor as a waiver of any other past,
              present, or future right.
            </p>
          </li>
          <li>
            <h3>ENTIRE TERMS OF USE</h3>
            <p>
              The Terms of Use, together with any specifically named or referenced by hyperlink agreements contained
              within the text of the Terms of Use, constitutes the entire agreement between you and us relating to the
              subject matter referenced in the Terms of Use, and as of today’s date supersedes all prior or
              contemporaneous representations, terms, conditions, and agreements, whether written or oral, with respect
              to such subject matter. You and we acknowledge and agree that in entering into this agreement, neither you
              nor we have relied upon, and shall have no remedy in respect of, any statement, representation,
              undertaking, or warranty, whether oral or in writing, save as expressly set out in the Terms of Use.
            </p>
          </li>
          <li>
            <h3>AMENDMENT</h3>
            <p>
              No amendment or modification of any provision of the Terms of Use will be effective unless the Terms of
              Use is republished, specifying the amended date of republication, on this Website, or unless such an
              amendment or modification is made in writing and signed by both you and us. Republication of the Terms of
              Use as referenced above shall be deemed effective even if no modification is made to any other document or
              agreement referenced within the Terms of Use.
            </p>
          </li>
          <li>
            <h3>ASSIGNMENT</h3>
            <p>
              No term, obligation, or right that arises under the Terms of Use may be assigned, transferred, or
              sublicensed by you absent Loved Ones First’s prior written consent; however, Loved Ones First and any of
              its affiliates, subsidiaries, successors, or purchasers may freely assign, transfer, or sublicense any or
              all such terms, obligations, or rights, without limit or restriction.
            </p>
          </li>
          <li>
            <h3>TRANSLATION</h3>
            <p>
              From time to time we may choose to translate the Terms of Use and any or all other texts referenced within
              it. This is solely for the convenience of our visitors; however, if any confusion arises because of, or
              variance is found between, this English version and any other translated version of the Terms of Use, this
              English version shall govern your relationship with us.
            </p>
          </li>
          <li>
            <h3>TITLES</h3>
            <p>
              All Section titles in the Terms of Use are for convenience only and have no contractual or other legal
              effect. Furthermore, defined terms should be understood and construed in their defined capacity only.
            </p>
          </li>
          <li>
            <h3>CHOICE OF LAW AND VENUE</h3>
            <p>
              The Terms of Use is to be construed in accordance with and governed by Arizona law, without regard to
              conflict of law provisions, for any claim, dispute, or cause of action (“Claim”) that may arise between
              you and us.&nbsp;
              <strong>
                IN THE RESOLUTION OF ANY CLAIM, REGARDLESS OF WHETHER WE OR YOU BRING IT, YOU AGREE TO SUBMIT YOURSELF
                TO AND CONSENT TO PERSONAL AND EXCLUSIVE JURISDICTION IN, AND TO BRING OR DEFEND YOUR CLAIM IN THE
                EXCLUSIVE VENUE OF, THE STATE AND FEDERAL COURTS WITHIN MARICOPA COUNTY, ARIZONA, USA
              </strong>
              .
            </p>
            <p>THANK YOU!</p>
            <p>
              You are the best for reading this far. Once again, welcome to LovedOnesFirst.com! Unless you’re a
              commercial or business user of LovedOnesFirst.com please feel free to enjoy the rest of the Website. If
              you are a commercial or business user, you must read the below paragraphs before using the Website.
            </p>
            <p>***IMPORTANT NOTE TO BUSINESS USERS OF LovedOnesFirst.com***</p>
            <p>
              If you have an interest in having your product, service, or property featured on the Website, you must
              read and agree to the following Addendum before you begin use of the Website.
            </p>
            <p>Addendum to Loved Ones First Terms of Use:</p>
            <p>Additional Terms and Conditions for Business Users</p>
          </li>
          <li>
            <h3>ADDENDUM INTRODUCTION</h3>
            <p>
              This Addendum incorporates and reiterates all terms, conditions, and provisions, in their entirety,
              contained within the text of above Sections 1, 3, and 5 through 21, inclusively, and you acknowledge and
              agree that you will be bound by all such Sections of the Terms of Use. This Addendum also includes a
              number of new terms, conditions, and provisions that apply additionally to Business Users of this Website,
              which you agree to abide by in all of your use of this Website.
            </p>
            <p>
              In this and the following Sections (22 through 24, inclusively), the terms&nbsp;<strong>“we”</strong>
              ,&nbsp;
              <strong>“us”</strong>,&nbsp;<strong>“our”</strong>&nbsp;and&nbsp;<strong>“Loved Ones First”</strong>
              &nbsp;still refer to Loved Ones First, LLC., an Arizona corporation, and our subsidiaries and affiliates.
              The terms&nbsp;<strong>“you”</strong>,&nbsp;<strong>“Business User”</strong>, and&nbsp;
              <strong>“Business Users”</strong>&nbsp;have been broadened to include any visitor to the Website who is a
              past, present, future, or potential commercial provider or supplier of goods, services, advertising, or
              any other form of business interaction, who visits the Website or contributes content to this Website for
              any reason other than noncommercial private use and enjoyment. The term&nbsp;<strong>“Affiliate”</strong>
              &nbsp;means, with respect to any party, any other corporation, partnership, or other entity that directly
              or indirectly controls, is controlled by, or is under common control with such party. The term&nbsp;
              <strong>“Business”</strong>&nbsp;means any goods produced or sold by, services rendered by or brokered by,
              or properties owned or managed by you, whether for profit or for charity, information about which is
              depicted on this Website.
            </p>
            <p>
              Your access and use as a Business User of this Website is offered solely on the condition that you accept
              and agree to be bound by all terms, conditions, and provisions in Sections 22 through 24, inclusively
              (collectively, the&nbsp;<strong>“Addendum”</strong>),&nbsp;
              <strong>
                IN ADDITION TO all terms, conditions, and provisions found in Sections 1, 3, and 5 through 21,
                inclusively, of the Terms of Use, (“Terms of Use”)
              </strong>
              . By using this Website in any manner, you agree to be bound by this Addendum and represent that you have
              read and understood its terms.&nbsp;
              <strong>
                Please read this Addendum carefully, as it contains information concerning your legal rights and
                limitations on these rights
              </strong>
              . If you do not accept all terms and conditions found within this Addendum, please leave this Website
              immediately.
            </p>
          </li>
          <li>
            <h3>LISTING OR DESCRIBING YOUR BUSINESS</h3>
            <p>
              One of the main appeals of LovedOnesFirst.com is that we provide a great amount of information, and will
              continue to provide ever more information, regarding accommodations and services for seniors. We aspire to
              be the largest, most information-filled, and otherwise best source of such information in the world.
              Accordingly, we welcome your input if you are the owner or operator of a Business that provides senior
              care or related goods or services. As you can imagine, however, it is of utmost importance that the
              information we have about you is timely and accurate. Therefore, to use this Website, you must agree to
              our listing guidelines, below.
            </p>
            <p>
              LovedOnesFirst.com uses a variety of methods to obtain information about the businesses and professionals
              found on our Website, including publicly available information such as that maintained by government
              licensing authorities, phone directories, and internet domain directories. We also sometimes obtain
              information by consulting official business websites, city directories, industry publications, third party
              compilations of industry information, and other sources. If you own or operate a Business listed on our
              Website, or which you would like to see listed on our Website, you may improve the accuracy, completeness,
              or attractiveness of your Business’s listing by creating a commercial account and providing your own
              Submitted Content, such as photographs, videos, price information, and descriptions of facilities, goods,
              services, staff, or other things which potential customers and clients might find useful.
            </p>
            <p>
              <strong>
                If you are a business or professional (or the authorized agent or employee of a business or
                professional) whose description on this Website contains an inaccuracy or omission, you agree to take
                full responsibility for correcting your business’s description, including logging onto your account and
                fixing the problem, if possible, or contacting this Website and documenting to our satisfaction the
                nature of the inaccuracy or omission and providing the accurate or complete information. Loved Ones
                First RESERVES THE RIGHT, AT OUR SOLE, PERSONAL, PRIVATELY REASONED AND FINAL DISCRETION, TO EITHER
                MODIFY OR LEAVE UNCHANGED ANY INFORMATION OR SUBMITTED CONTENT HAVING TO DO WITH YOUR BUSINESS THAT WE
                BELIEVE IS REASONABLY LIKELY TO BE TRUE, OR AT LEAST NOT MATERIALLY FALSE OR MISLEADING, IRRESPECTIVE OF
                SUCH INFORMATION’S OR CONTENT’S TRUTHFULNESS OR EFFECT.
              </strong>
            </p>
          </li>
          <li>
            <h3>BUSINESS USER SUBMITTED CONTENT GUIDELINES</h3>
            <p>
              All of your Submitted Content must conform to the following guidelines, in addition to all terms and
              conditions provided regarding Submitted Content in prior sections of the Terms of Use (please note that
              the following Business User guidelines are more strict than are the guidelines for non-commercial
              Submitted Content, for multiple legal and proprietary reasons):
            </p>
            <ol type="i">
              <li>
                <ol type="i">
                  <li>
                    VISUAL AND AUDIO CONTENT
                    <ol type="a">
                      <li>
                        All visual depictions of your Business, including photographs, drawings, graphic depictions,
                        blueprints, and videos must reasonably accurately portray your Business in its current state of
                        repair, and furnished with substantially the same amenities that are now there. Any visual
                        depiction that departs from this standard must be accompanied by a note or caption explaining
                        the material differences or that actual customers’ experience’s may vary.
                      </li>
                      <li>
                        Any visual depiction of a person or persons whose identity might be recognizable by someone who
                        knows that person may be submitted only if you have on file a written release from that person
                        or persons giving you an appropriate license to use their image or likeness commercially.
                      </li>
                      <li>
                        Similarly, any visual depictions containing commercial intellectual property not your own,
                        including but not limited to logos, slogans, mascots, or trademarked or otherwise protected
                        words, may be submitted only if you have on file a written release from the intellectual
                        property owner or its licensee or agent.
                      </li>
                      <li>
                        Any Submitted Content containing audible sound recordings, including videos with sound, may be
                        submitted only if you have on file a written release from any persons speaking in a nonpublic
                        setting, and if this sound contains any recognizable music or lyrics not in the public domain,
                        from the owner(s) or authorized representative(s) of the owner(s) who hold the rights to such
                        music or lyrics, permitting you to use their speech, music, or lyrics for commercial purposes,
                        including sharing it on this Website.
                      </li>
                    </ol>
                  </li>
                  <li>
                    YOUR DESCRIPTION OF YOUR BUSINESS
                    <ol type="a">
                      <li>
                        All Submitted Content describing your Business must be truthful and accurate, and relevant to
                        the conduct of your Business or to inquiries potential customers might have regarding your
                        Business. Although you are welcome to praise your own Business and describe what is so great
                        about it, in doing so you must not make any disparaging remarks about any competitors,
                        customers, reviewers, or Loved Ones First, its affiliates, or this website, whether by name or
                        by implication. You also must not make your praise for or description of your Business so
                        hyperbolic that it might have the effect of confusing or misleading visitors to this website;
                        similarly, joking, sarcasm, or play-acting should be readily apparent to any visitor to this
                        Website. If you mention any award your Business has received, the award must be relevant and it
                        must not have been awarded by any person or entity affiliated with your Business or to whom you
                        or your Business has paid to influence the awarder.
                      </li>
                      <li>
                        All Submitted Content you provide should be written in plain, easy-to-understand English, and
                        must remain within the scope of describing your Business, yourself, your staff, your history,
                        your community, and other similarly neutral and predictable content. You must not insert (1)
                        keywords, (2) buzzwords, (3) needless repetition, (4) redundant facts or claims, (5) EXCESSIVE
                        TEXT IN ALL CAPS, (6)***non-standard English 5P3LL1NG or punctuation!!!!***, or (7) anything
                        that gives us the sense that you are trying to be unnaturally manipulative in your content.
                      </li>
                      <li>
                        You may not promote your Business on this Website other than through providing attractive
                        descriptions of your Business and, if applicable, your satisfied customers. You may not mention
                        any discount, rebate, bonus, or any free product, free service, free upgrade in quality, or any
                        other incentive to potential clients or customers, unless we have agreed in writing to permit
                        you to do this, and only within the scope of our written permission. Nothing in this Addendum
                        shall be deemed to constitute such permission.
                      </li>
                    </ol>
                  </li>
                  <li>
                    REVIEWS OF AND COMMENTS ABOUT YOUR BUSINESS
                    <ol type="a">
                      <li>
                        <strong>
                          You may not leave or cause to be left on this Website ANY reviews for your own Business,
                          whether through a true, anonymous, or pseudonymous identity, or through an employee,
                          independent contractor, or any person or business who writes a review of your Business at your
                          compulsion or by your offering any form of reward or compensation, including discounts or free
                          items. If we learn or suspect that you are causing or knowingly permitting any such reviews to
                          be left on this website, we may, at our sole discretion, terminate this agreement without
                          warning or opportunity for recourse
                        </strong>
                        .
                      </li>
                      <li>
                        Just in case you missed it:&nbsp;<strong>NO PHONY OR FLUFF REVIEWS, OR IT’S OVER</strong>.
                      </li>
                      <li>
                        You also&nbsp;
                        <strong>
                          may not leave any review, or cause or knowingly permit another to leave any review, for any of
                          your competitors, even if you have purchased such competitor’s product or service. The same
                          penalty applies as in paragraph (h), above, and we get to decide whether someone else is your
                          competitor
                        </strong>
                        .
                      </li>
                      <li>
                        You may not attack, harass, disparage, discredit, embarrass, reveal personally identifiable
                        private information about, or otherwise behave rudely toward anyone who leaves a review of your
                        Business that you dislike or disagree with. If you wish to reply to a review or comment, keep it
                        professional, keep it to the facts, and focus on how reasonable you are, not on how unreasonable
                        the other is or was. If you cannot play nice, you may find yourself no longer allowed on the
                        playground.
                      </li>
                      <li>
                        If you choose to quote any Loved Ones First reviewer or commenter in your marketing materials,
                        you must first get permission from that reviewer or commenter and attribute his or her content
                        to him or her, and you must attribute Loved Ones First as the source. The quote or quotes used
                        must not be edited or abbreviated in any manner that obscures the original meaning of the review
                        or comment . You must not quote from any review or comment which this Website has flagged as
                        suspicious, unreliable, or to which we have applied any other potentially negative label, or
                        which we have removed. You alone are responsible for monitoring regularly your Business’s Loved
                        Ones First page to determine the status of any posted review or comment you use as a source.
                      </li>
                      <li>
                        If you believe someone has left a review or comment which defames you or your Business, or which
                        contains content which may be unlawful, do not respond to the review or comment by claiming
                        libel, defamation, or any tortious or criminal intent or content. If you do, and you wind up
                        accidentally defaming someone yourself, we won’t come to your help. If you wish, you may bring
                        the offensive content to our attention by contacting us directly. We may at our own discretion
                        take any action we choose, including but not limited to (1) removing or modifying some or all of
                        the content; (2) adding a comment, flag, or label of our own describing or warning about the
                        nature of the content, or (3) deciding to leave the content as it is; in any event we are under
                        no obligation to take any action with regard to the offending content.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>

        <h4>THANK YOU</h4>
        <p>
          As the soon-to-be future world leader, we trust, in connecting seniors with senior care businesses, we have to
          take precautions to guard both our and your businesses, and this includes having a lengthy and thorough Terms
          of Use. We promise you, however, that our chief concerns are doing a great job, being the best at what we do,
          and making all customers and businesses glad that they came to LovedOnesFirst.com. Thank you for helping us
          achieve our goals, and yours.
        </p>
      </LegalDocument>
    </PageContent>
  </Fragment>
);

export default TermsOfUsePage;
