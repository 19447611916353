import styled from 'styled-components';

export const TableCell = styled.td`
  padding: 12px 10px;
  vertical-align: top;
  line-height: 22px;
  font-size: 15px;
`;

export const TableRow = styled.tr`
  background-color: #fff;
`;

export const TableBody = styled.tbody`
  ${TableRow} {
    border-bottom: 1px solid ${props => props.theme.gray};
  }
`;

export const TableHead = styled.thead`
  text-transform: uppercase;
  font-weight: ${props => props.theme.fontWeightMedium};

  ${TableRow} {
    background-color: ${props => props.theme.grayLight};
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
