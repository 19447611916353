import styled, { css } from 'styled-components';
import { mapper, sizeMapper, colorMapper } from '../../styles';

const Text = styled.span`
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};

  ${props =>
    props.block &&
    css`
      display: block;
    `};

  ${props =>
    props.align &&
    css`
      text-align: ${props => mapper(props.align, { left: 'left', center: 'center', right: 'right' }, 'left')};
    `};

  ${props =>
    props.weight &&
    css`
      font-weight: ${props =>
        mapper(props.weight, {
          light: props.theme.fontWeightLight,
          normal: props.theme.fontWeightNormal,
          medium: props.theme.fontWeightMedium,
        })};
    `};

  ${props =>
    props.italic &&
    css`
      font-style: italic;
    `};

  ${props =>
    props.size &&
    css`
      font-size: ${mapper(props.size, { small: '12px', medium: '14px', large: '16px', xLarge: '18px' })};
    `};

  ${props =>
    props.color &&
    css`
      color: ${colorMapper(props)};
    `};
`;

export default Text;
