import styled, { css } from 'styled-components';
import { transparentize, darken } from 'polished';
import { mapper, media } from '../../../core/styles';
import { bareButtonStyle } from '../../../core/components/styled';
import { Icon } from '../../../core/components';

export const SearchSuggestionHighlight = styled.span`
  font-weight: ${props => props.theme.fontWeightMedium};
`;

export const SearchSuggestion = styled.button`
  ${bareButtonStyle};
  display: block;
  width: 100%;
  padding: 18px 10px;
  background-color: #fff;
  border-bottom: 1px solid ${props => props.theme.grayLight};
  text-align: left;
  font-size: 16px;

  ${props =>
    props.isActive &&
    css`
      background-color: ${props => props.theme.grayLight};
      color: ${props => props.theme.brandPrimary};
    `};

  ${media.phone`
    padding: 12px 8px;
    font-size: 14px;
  `};
`;

export const SearchSuggestions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  margin-top: 6px;
  box-shadow: 0 2px 4px ${transparentize(0.9, '#000')};
`;

export const SearchButtonIcon = styled(Icon).attrs({ icon: 'search' })`
  display: none;
  width: 14px;
  height: 14px;

  ${media.phone`
    display: inline-block;
  `};
`;

export const SearchButtonText = styled.span`
  text-transform: capitalize;
  letter-spacing: 0.4px;
  font-size: 18px;

  ${media.tablet`
    font-size: 16px;
  `};

  ${media.phone`
    display: none;
  `};
`;

export const SearchButton = styled.button`
  ${bareButtonStyle};
  margin: 5px;
  padding: 0 20px;
  background-color: ${props => props.theme.brandPrimary};
  border-radius: 6px;
  color: #fff;

  &:active {
    background-color: ${props => darken(0.05, props.theme.brandPrimary)};
  }

  ${media.phone`
    padding: 0 12px;
  `};
`;

export const SearchInput = styled.input`
  flex: 1;
  padding: 0 15px;
  border-radius: 6px;
  border: none;
  outline: none;
  line-height: 24px;
  font-size: 18px;

  ::placeholder {
    color: ${props => props.theme.grayDark};
  }

  ${media.tablet`
    padding: 0 12px;
    font-size: 17px;
  `};

  ${media.phone`
    padding: 0 8px;
    font-size: 15px;
  `};
`;

export const SearchArrow = styled.div`
  position: absolute;
  top: -25px;
  left: 0;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }

  ${props =>
    props.position &&
    css`
      left: ${mapper(props.position, { '1/3': '37.5%', '2/3': '62.5%', '3/3': '87.5%' })};
    `};
`;

export const SearchGroup = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  height: 58px;
  background-color: #fff;
  border-radius: 6px;

  ${media.tablet`
    height: 54px;
  `};

  ${media.phone`
    height: 48px;
    border-radius: 4px;
  `};
`;

export const SearchArea = styled.div`
  margin-bottom: 30px;
  padding: 12px;
  background-color: ${props => props.theme.grayLight};
  border-radius: 6px;
`;
