import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import { FormGroup, FormError, Input as FormInput } from './styled';

const FormTextarea = ({ field, form: { touched, errors }, placeholder, margin, ...props }) => (
  <FormGroup hasValue={!!field.value} margin={margin}>
    <FormInput as={TextareaAutosize} {...field} {...props} placeholder={placeholder} />

    {touched[field.name] && errors[field.name] && <FormError>{errors[field.name]}</FormError>}
  </FormGroup>
);

FormTextarea.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  margin: PropTypes.string,
};

FormTextarea.defaultProps = {
  placeholder: undefined,
  margin: undefined,
};

export default FormTextarea;
