import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { filter } from 'lodash-es';
import { Grid, GridColumn, Link } from '../../../core/components/styled';
import { PageMeta, PageContent, PageTitle, PageMetaContent } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import {
  FeaturedResourceCategories,
  FeaturedResourceCategoryGrid,
  FeaturedResourceCategoryGridItem,
  FeaturedResourceCategoryGridItemTitle,
  FeaturedResourceCategoryGridImageContainer,
  FeaturedResourceCategoryGridImage,
  FeaturedResourceCategoriesDisclosure,
} from '../styled';
import featuredResourceCategoryPlaceholderImage from '../../assets/img/featuredResourceCategoryPlaceholder.png';

const getFeaturedCategories = memoize(categories => filter(categories, category => !!category.isFeatured));

const FeaturedResourceCategoriesPage = ({ featuredResourceCategories }) => {
  const featuredCategories = getFeaturedCategories(featuredResourceCategories);

  return (
    <Fragment>
      <DocumentTitle>Featured Resources</DocumentTitle>
      <PageMeta>
        <PageMetaContent>
          <PageTitle>Featured Resources</PageTitle>
        </PageMetaContent>
      </PageMeta>

      <PageContent>
        <FeaturedResourceCategories>
          <Grid multiLine>
            <GridColumn size="12/12" padding="no medium no no" sizeDesktop="12/12" paddingDesktop="no">
              <FeaturedResourceCategoryGrid>
                {featuredCategories.map(featuredCategory => (
                  <FeaturedResourceCategoryGridItem
                    key={featuredCategory.name}
                    to={`/featured-resources/${featuredCategory.name}`}
                  >
                    <FeaturedResourceCategoryGridItemTitle>
                      {featuredCategory.name}
                    </FeaturedResourceCategoryGridItemTitle>
                    <FeaturedResourceCategoryGridImageContainer>
                      <FeaturedResourceCategoryGridImage
                        src={featuredCategory.image.originalSizeUrl || featuredResourceCategoryPlaceholderImage}
                      />
                    </FeaturedResourceCategoryGridImageContainer>
                  </FeaturedResourceCategoryGridItem>
                ))}
              </FeaturedResourceCategoryGrid>
            </GridColumn>
          </Grid>
        </FeaturedResourceCategories>

        <FeaturedResourceCategoriesDisclosure>
          The resources contained on <Link to="/">LovedOnesFirst.com</Link> (“Resources”) are for informational purposes
          only. The Resources are not intended to be a substitute for professional medical, legal or financial advice.{' '}
          <Link to="/">LovedOnesFirst.com</Link> does not provide medical diagnosis or treatment, or medical, legal, or
          financial advice. Reliance on any information provided by <Link to="/">LovedOnesFirst.com</Link> or its
          authors, editors, sponsors, or contributors, is solely at your own risk. See additional{' '}
          <Link to="/terms-of-use">Terms of Use</Link>.
        </FeaturedResourceCategoriesDisclosure>
      </PageContent>
    </Fragment>
  );
};

FeaturedResourceCategoriesPage.propTypes = {
  featuredResourceCategories: PropTypes.array.isRequired,
};

export default FeaturedResourceCategoriesPage;
