import styled from 'styled-components';
import { transparentize } from 'polished';
import { media } from '../../../core/styles';

export const RequestInformationDisclosure = styled.span`
  display: block;
  line-height: 18px;
  font-size: 12px;
  color: ${props => props.theme.grayDark};
`;

export const RequestInformationDescriptionLink = styled.a`
  font-weight: ${props => props.theme.fontWeightBold};
  color: ${props => props.theme.brandPrimary};
`;

export const RequestInformationDescription = styled.span`
  display: block;
  margin-bottom: 20px;
  line-height: 18px;
  font-size: 13px;
`;

export const RequestInformationTitle = styled.span`
  display: block;
  margin-bottom: 10px;
  line-height: 24px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 18px;
  color: ${props => props.theme.green};
`;

export const RequestInformation = styled.div`
  position: sticky;
  top: 150px;
  margin-bottom: 10px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid ${props => props.theme.grayLight};
  border-radius: 6px;
  box-shadow: 0 0 8px ${transparentize(0.9, '#000')};

  ${media.desktop`
    position: static;
    top: auto;
  `}
`;
