import {
  SIZE_AUTO,
  SIZE_NO,
  SIZE_XX_SMALL,
  SIZE_X_SMALL,
  SIZE_SMALL,
  SIZE_S_MEDIUM,
  SIZE_MEDIUM,
  SIZE_L_MEDIUM,
  SIZE_LARGE,
  SIZE_X_LARGE,
  SIZE_XX_LARGE,
  SIZE_GRANDE,
  SIZE_X_GRANDE,
  SIZE_XX_GRANDE,
} from '../constants';

import mapper from './mapper';

const SIZE_MAP = {
  [SIZE_AUTO]: 'auto',
  [SIZE_NO]: '0',
  [SIZE_XX_SMALL]: '5px',
  [SIZE_X_SMALL]: '10px',
  [SIZE_SMALL]: '15px',
  [SIZE_S_MEDIUM]: '20px',
  [SIZE_MEDIUM]: '30px',
  [SIZE_L_MEDIUM]: '40px',
  [SIZE_LARGE]: '50px',
  [SIZE_X_LARGE]: '60px',
  [SIZE_XX_LARGE]: '70px',
  [SIZE_GRANDE]: '80px',
  [SIZE_X_GRANDE]: '100px',
  [SIZE_XX_GRANDE]: '100px',
};

const sizeMapper = (size, defaultSize = SIZE_MEDIUM) => mapper(size, SIZE_MAP, defaultSize);

export default sizeMapper;
