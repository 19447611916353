import { LOCAL, DEVELOPMENT, QA, PRODUCTION } from './environments';

export const BASE_URL_MAP = {
  'lovedonesfirst.com': PRODUCTION,
  'www.lovedonesfirst.com': PRODUCTION,
  'qa.lovedonesfirst.com': QA,
  'lof-fe-dev.azurewebsites.net': DEVELOPMENT,
};

export const API_BASE_URL_MAP = {
  [PRODUCTION]: 'https://betadevapi.azurewebsites.net',
  [QA]: 'https://api.qa.lovedonesfirst.com',
  [DEVELOPMENT]: 'https://lof-api-dev.azurewebsites.net',
  [LOCAL]: 'https://localhost:5001',
};

export const BACKOFFICE_BASE_URL_MAP = {
  [PRODUCTION]: 'https://backoffice.lovedonesfirst.com',
  [QA]: 'https://lof-bo-qa.azurewebsites.net',
  [DEVELOPMENT]: 'https://lof-bo-dev.azurewebsites.net',
  [LOCAL]: 'http://localhost:3000',
};
