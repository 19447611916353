import { combineReducers } from 'redux';
import { reducer as scheduleConsultationReducer } from './scheduleConsultation';
import { reducer as contactAnnaReducer } from './contactAnna';
import { reducer as featuredResourceCategoriesReducer } from './featuredResourceCategories';
import { reducer as featuredResourcesReducer } from './featuredResources';

export { scheduleConsultation, resetScheduleConsultation } from './scheduleConsultation';
export { contactAnna, resetContactAnna } from './contactAnna';
export { loadFeaturedResourceCategories, resetFeaturedResourceCategories } from './featuredResourceCategories';
export { loadFeaturedResources, resetFeaturedResources } from './featuredResources';

export const reducer = combineReducers({
  scheduleConsultation: scheduleConsultationReducer,
  contactAnna: contactAnnaReducer,
  featuredResourceCategories: featuredResourceCategoriesReducer,
  featuredResources: featuredResourcesReducer,
});
