import React, { PureComponent } from 'react';
import RouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { Toggle } from '../../core/components';
import { IDX_URL } from '../constants';
import {
  Header as HeaderContainer,
  HeaderContent,
  HeaderLogo,
  HeaderNavigation,
  HeaderNavigationItem,
  HeaderNavigationMenu,
  HeaderNavigationMenuItem,
  HeaderButtonSet,
  HeaderMeta,
  HeaderCallConsultant,
  HeaderCallConsultantContent,
  HeaderCallConsultantText,
  HeaderCallConsultantIcon,
  HeaderCallConsultantPhoneNumber,
} from './styled';
import VerticalNavigationToggle from './VerticalNavigationToggle';
import VerticalNavigation from './VerticalNavigation';

class Header extends PureComponent {
  state = { isVerticalNavigationOpen: false };

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      this.closeVerticalNavigation();
    }
  }

  closeVerticalNavigation = () => {
    this.setState({ isVerticalNavigationOpen: false });
  };

  toggleVerticalNavigation = () => {
    this.setState(({ isVerticalNavigationOpen }) => ({
      isVerticalNavigationOpen: !isVerticalNavigationOpen,
    }));
  };

  render() {
    const { isVerticalNavigationOpen } = this.state;

    return (
      <HeaderContainer>
        <HeaderContent>
          <HeaderMeta>
            <HeaderCallConsultant>
              <HeaderCallConsultantContent>
                <HeaderCallConsultantText>Speak with your Lifelong Senior Care Consultant</HeaderCallConsultantText>
                <HeaderCallConsultantIcon />
                <HeaderCallConsultantPhoneNumber href="tel:888-888-3198">888-888-3198</HeaderCallConsultantPhoneNumber>
              </HeaderCallConsultantContent>
            </HeaderCallConsultant>
          </HeaderMeta>

          <HeaderLogo to="/" />

          <HeaderNavigation>
            <HeaderNavigationItem as="a" href={IDX_URL}>
              Active adult
            </HeaderNavigationItem>
            <HeaderNavigationItem to="/independent-living">Independent living</HeaderNavigationItem>
            <HeaderNavigationItem to="/assisted-living">Assisted living</HeaderNavigationItem>
            <HeaderNavigationItem to="/memory-care">Memory care</HeaderNavigationItem>
            <HeaderNavigationItem as="button" to="/resources">
              Resources
              <HeaderNavigationMenu>
                <HeaderNavigationMenuItem to="/about">About Loved Ones First</HeaderNavigationMenuItem>
                <HeaderNavigationMenuItem to="/our-process">Our process</HeaderNavigationMenuItem>
                <HeaderNavigationMenuItem to="/featured-resources">Featured resources</HeaderNavigationMenuItem>
                <HeaderNavigationMenuItem to="/featured-communities">Featured communities</HeaderNavigationMenuItem>
                <HeaderNavigationMenuItem to="/schedule-consultation">Schedule a consultation</HeaderNavigationMenuItem>
                <HeaderNavigationMenuItem to="/contact-us">Contact us</HeaderNavigationMenuItem>
              </HeaderNavigationMenu>
            </HeaderNavigationItem>
          </HeaderNavigation>

          <HeaderButtonSet>
            <VerticalNavigationToggle isOpen={isVerticalNavigationOpen} toggle={this.toggleVerticalNavigation} />
          </HeaderButtonSet>

          <Toggle isOpen={isVerticalNavigationOpen} closeDelay={300}>
            {(isOpening, isClosing) => (
              <VerticalNavigation isClosing={isClosing} close={this.toggleVerticalNavigation} />
            )}
          </Toggle>
        </HeaderContent>
      </HeaderContainer>
    );
  }
}

Header.propTypes = {
  location: RouterPropTypes.location.isRequired,
};

export default withRouter(Header);
