import produce from 'immer';
import { assign } from 'lodash-es';
import { contactAnna as doContactAnna } from '../services';

// Actions
const START_CONTACT = 'resources/contactAnna/START_CONTACT';
const COMPLETE_CONTACT = 'resources/contactAnna/COMPLETE_CONTACT';
const FAIL_CONTACT = 'resources/contactAnna/FAIL_CONTACT';
const RESET = 'resources/contactAnna/RESET';

// Initial state
const initialState = {
  isContacting: false,
  isContactCompleted: false,
  isContactFailed: false,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_CONTACT:
      return produce(state, nextState => {
        nextState.isContacting = true;
      });

    case COMPLETE_CONTACT:
      return produce(state, nextState => {
        nextState.isContacting = false;
        nextState.isContactCompleted = true;
        nextState.isContactFailed = false;
      });

    case FAIL_CONTACT:
      return produce(state, nextState => {
        nextState.isContacting = false;
        nextState.isContactCompleted = false;
        nextState.isContactFailed = true;
      });

    case RESET:
      return produce(state, nextState => {
        assign(nextState, initialState);
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_CONTACT,
});

const completeLoad = () => ({
  type: COMPLETE_CONTACT,
});

const failLoad = () => ({
  type: FAIL_CONTACT,
});

export const contactAnna = values => dispatch => {
  dispatch(startLoad());

  const contactAnnaPromise = doContactAnna(values);
  contactAnnaPromise.then(() => dispatch(completeLoad())).catch(() => dispatch(failLoad()));
  return contactAnnaPromise;
};

export const resetContactAnna = () => ({
  type: RESET,
});
