import styled from 'styled-components';
import { media } from '../../styles';
import { bareButtonStyle } from './Button';

export const ImageGalleryImage = styled.img`
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
`;

export const ImageGalleryButton = styled.button`
  ${bareButtonStyle};
  flex-basis: 19%;
  margin: 0.5%;
  padding-bottom: 12.66%;
  border: 1px solid ${props => props.theme.gray};

  ${media.desktop`
    flex-basis: 24%;
    margin: 0.4%;
    padding-bottom: 16%;
  `}

  ${media.tablet`
    flex-basis: 33%;
    margin: 0.16%;
    padding-bottom: 22%;
  `}

  ${media.phone`
    flex-basis: 100%;
    margin: 0 0 12px;
    padding-bottom: 66.66%;
  `}

  &:hover {
    opacity: 0.5;
  }
`;

export const ImageGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
