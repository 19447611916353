import styled, { css } from 'styled-components';
import { mapper } from '../../styles';

const Container = styled.div`
  ${props =>
    props.overflow &&
    css`
      overflow: ${mapper(props.overflow, { auto: 'auto', hidden: 'hidden', scroll: 'scroll' })};
    `}
`;

export default Container;
