import styled from 'styled-components';
import { media } from '../../../core/styles';

const ContactMap = styled.iframe`
  width: 100%;
  height: 40vh;
  margin-bottom: 30px;
  border: none;

  ${media.tablet`
    height: 50vh;
  `}
`;

export default ContactMap;
