import React from 'react';
import PropTypes from 'prop-types';
import { Search } from '../../common/components';
import { Intro as IntroContainer, IntroVideo, IntroContent, IntroSearch, IntroTitle } from './styled';
import TabList from './TabList';
import introVideo from '../assets/video/intro.mp4';

const Intro = ({ activeTab, setActiveTab }) => (
  <IntroContainer>
    <IntroVideo autoPlay loop muted playsInline>
      <source src={introVideo} type="video/mp4" />
    </IntroVideo>

    <IntroContent>
      <IntroSearch>
        <IntroTitle>Improving your senior living options</IntroTitle>
        <TabList activeTab={activeTab} setActiveTab={setActiveTab} />
        <Search showArrow serviceCategory={activeTab} />
      </IntroSearch>
    </IntroContent>
  </IntroContainer>
);

Intro.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default Intro;
