import styled from 'styled-components';
import { transparentize } from 'polished';
import { media, modernImage } from '../../../core/styles';
import introImage from '../../assets/img/intro.jpg';
import introImage2x from '../../assets/img/intro@2x.jpg';
import introImageWebP from '../../assets/img/intro.webp';
import introImageWebP2x from '../../assets/img/intro@2x.webp';

export const IntroTitle = styled.h1`
  margin: 0 0 30px;
  text-shadow: 2px 2px 4px ${transparentize(0.6, '#000')};
  text-align: center;
  font-weight: ${props => props.theme.fontWeightNormal};
  font-size: 36px;
  color: #fff;

  ${media.desktop`
    font-size: 32px;
  `};

  ${media.tablet`
    font-size: 28px;
  `};

  ${media.phone`
    margin-bottom: 20px;
    font-size: 20px;
  `};
`;

export const IntroSearch = styled.div`
  width: 100%;
  max-width: 820px;
`;

export const IntroContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 5% 10%;
  background-color: ${transparentize(0.8, '#000')};

  ${media.phone`
    padding: 0 2% 6%;
  `};
`;

export const IntroVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

export const Intro = styled.div`
  position: relative;
  width: 100%;
  height: 56.25vw;
  min-height: 220px;
  margin-bottom: 50px;
  ${modernImage(introImage, introImage2x, introImageWebP, introImageWebP2x, 'cover')};
  background-repeat: no-repeat;
  background-position: center center;

  ${media.tablet`
    margin-bottom: 40px;
  `};

  ${media.phone`
    margin-bottom: 30px;
  `};
`;
