import { createGlobalStyle } from 'styled-components';
import { transparentize } from 'polished';
import { THEME } from '../../constants';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    outline: none;
  }

  body {
    padding: 0;
    text-rendering: optimizeLegibility;
    background-color: ${THEME.bodyBackgroundColor};
    font-family: ${THEME.fontFamilyBase};
    font-size: ${THEME.fontSizeBase};
    color: ${THEME.brandDefault};
  }

  a {
    text-decoration: none;
    color: ${THEME.brandPrimary};
  }

  ul,
  ol {
    padding: 0;
  }

  ::selection {
    background-color: ${transparentize(0.8, THEME.brandPrimary)};
  }
`;

export default GlobalStyle;
