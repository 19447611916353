import React, { PureComponent } from 'react';
import { throttle } from 'lodash-es';
import { ScrollToTopButton as ScrollToTopButtonContainer, ScrollToTopButtonIcon } from './styled';

class ScrollToTopButton extends PureComponent {
  state = { isVisible: false };

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  onScroll = throttle(() => {
    const { isVisible } = this.state;
    const { pageYOffset, innerHeight } = window;

    if (!isVisible && pageYOffset > innerHeight / 2) {
      this.setState({ isVisible: true });
      return;
    }

    if (isVisible && pageYOffset < innerHeight / 2) {
      this.setState({ isVisible: false });
    }
  }, 50);

  scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  render() {
    const { isVisible } = this.state;
    return (
      <ScrollToTopButtonContainer isVisible={isVisible} onClick={this.scrollToTop}>
        <ScrollToTopButtonIcon />
      </ScrollToTopButtonContainer>
    );
  }
}

export default ScrollToTopButton;
