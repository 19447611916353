import PropTypes from 'prop-types';
import { PureComponent } from 'react';

class Toggle extends PureComponent {
  state = { isOpen: false, isClosing: false };

  componentDidUpdate(prevProps) {
    const { isOpen, closeDelay } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState({ isOpen: true, isClosing: false });
    }

    if (!isOpen && prevProps.isOpen) {
      this.setState({ isOpen: false, isClosing: true });

      setTimeout(() => {
        this.setState({ isClosing: false });
      }, closeDelay);
    }
  }

  render() {
    const { isOpen, isClosing } = this.state;
    const { children } = this.props;

    return isOpen || isClosing ? children(isOpen, isClosing) : null;
  }
}

Toggle.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired,
  closeDelay: PropTypes.number.isRequired,
};

export default Toggle;
