import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Collapsible } from '../../core/components';
import { IDX_URL } from '../constants';
import {
  VerticalNavigation as VerticalNavigationContainer,
  VerticalNavigationDismiss,
  VerticalNavigationContent,
  VerticalNavigationItems,
  VerticalNavigationItem,
  VerticalNavigationGroup,
  VerticalNavigationGroupIcon,
  VerticalNavigationGroupItems,
} from './styled';

class VerticalNavigation extends PureComponent {
  state = { isResourcesGroupOpen: false };

  componentDidMount() {
    document.body.style.overflowY = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflowY = '';
  }

  toggleResourcesGroup = () => {
    this.setState(({ isResourcesGroupOpen }) => ({ isResourcesGroupOpen: !isResourcesGroupOpen }));
  };

  render() {
    const { isResourcesGroupOpen } = this.state;
    const { isClosing, close } = this.props;

    return (
      <VerticalNavigationContainer isClosing={isClosing}>
        <VerticalNavigationDismiss onClick={close} />
        <VerticalNavigationContent>
          <VerticalNavigationItems>
            <VerticalNavigationItem as="a" href={IDX_URL}>
              Active adult
            </VerticalNavigationItem>
            <VerticalNavigationItem to="/independent-living">Independent living</VerticalNavigationItem>
            <VerticalNavigationItem to="/assisted-living">Assisted living</VerticalNavigationItem>
            <VerticalNavigationItem to="/memory-care">Memory care</VerticalNavigationItem>

            <VerticalNavigationGroup isOpen={isResourcesGroupOpen}>
              <VerticalNavigationItem as="button" onClick={this.toggleResourcesGroup}>
                Resources
                <VerticalNavigationGroupIcon />
              </VerticalNavigationItem>

              <Collapsible isOpen={isResourcesGroupOpen}>
                <VerticalNavigationGroupItems>
                  <VerticalNavigationItem to="/about">About Loved Ones First</VerticalNavigationItem>
                  <VerticalNavigationItem to="/our-process">Our process</VerticalNavigationItem>
                  <VerticalNavigationItem to="/featured-resources">Featured resources</VerticalNavigationItem>
                  <VerticalNavigationItem to="/featured-communities">Featured communities</VerticalNavigationItem>
                  <VerticalNavigationItem to="/schedule-consultation">Schedule a consultation</VerticalNavigationItem>
                  <VerticalNavigationItem to="/contact-us">Contact us</VerticalNavigationItem>
                </VerticalNavigationGroupItems>
              </Collapsible>
            </VerticalNavigationGroup>
          </VerticalNavigationItems>
        </VerticalNavigationContent>
      </VerticalNavigationContainer>
    );
  }
}

VerticalNavigation.propTypes = {
  isClosing: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default VerticalNavigation;
