import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  ExternalLink,
  Message,
} from '../../../core/components/styled';
import { PageMeta, PageContent, PageTitle, PageMetaContent } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';

const FeaturedResourcesPage = ({ match, featuredResources }) => {
  const { categoryName } = match.params;

  return (
    <Fragment>
      <DocumentTitle>{categoryName}</DocumentTitle>
      <PageMeta>
        <PageMetaContent>
          <PageTitle>{categoryName}</PageTitle>
        </PageMetaContent>
      </PageMeta>

      <PageContent>
        {!featuredResources.length && <Message>No resources found in this category.</Message>}

        {!!featuredResources.length && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="20%">Name</TableCell>
                <TableCell width="27%">Address</TableCell>
                <TableCell width="25%">Contact</TableCell>
                <TableCell width="15%">Phone</TableCell>
                <TableCell width="12%">Website</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {featuredResources.map((featuredResource, index) => (
                <TableRow key={index}>
                  <TableCell>{featuredResource.name}</TableCell>
                  <TableCell>{featuredResource.address ? featuredResource.address.formattedAddress : ''}</TableCell>
                  <TableCell>{featuredResource.contactName}</TableCell>
                  <TableCell>{featuredResource.phoneNumber}</TableCell>
                  <TableCell>
                    {featuredResource.websiteUrl && (
                      <ExternalLink href={featuredResource.websiteUrl} target="_blank">
                        Visit website
                      </ExternalLink>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </PageContent>
    </Fragment>
  );
};

FeaturedResourcesPage.propTypes = {
  match: RouterPropTypes.match.isRequired,
  featuredResources: PropTypes.array.isRequired,
};

export default withRouter(FeaturedResourcesPage);
