import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ASSISTED_LIVING } from '../../../common/constants';
import {
  PageMeta,
  PageContent,
  PageTitle,
  PageMetaContent,
  PageSection,
  PageSectionTitle,
  PageSectionDescription,
  PageSectionList,
  PageSectionListItem,
  SearchArea,
} from '../../../common/components/styled';
import {
  DocumentTitle,
  PageLoadingOverlay,
  Search,
  FeaturedCommunityList,
  CommunityList,
} from '../../../common/components';

class SearchPage extends PureComponent {
  render() {
    const { isSearching, isSearchCompleted, totalCount, results, featuredCommunities } = this.props;

    return (
      <Fragment>
        <DocumentTitle>Assisted Living</DocumentTitle>
        <PageMeta>
          <PageMetaContent>
            <PageTitle>Assisted Living</PageTitle>
          </PageMetaContent>
        </PageMeta>

        {isSearching && <PageLoadingOverlay />}

        <PageContent>
          <PageSection marginPhone="no no lMedium">
            <PageSectionTitle>What is Assisted Living?</PageSectionTitle>
            <PageSectionDescription>
              Assisted Living communities are designed for seniors who are no longer able to live safely on their own.
              Help with activities of daily living is provided in a way that promotes maximum independence.
              <PageSectionList>
                <PageSectionListItem>
                  Assistance with medications, activities of daily living, meals, and housekeeping are routinely
                  provided
                </PageSectionListItem>

                <PageSectionListItem>Three meals per day are provided in a central dining room</PageSectionListItem>
                <PageSectionListItem>Staff is available 24 hours per day for additional safety</PageSectionListItem>

                <PageSectionListItem>
                  Paying for assisted living may be a mix of private pay, VA Aid and Attendance or Medicaid
                </PageSectionListItem>

                <PageSectionListItem>
                  Assisted living costs vary by state and accommodations provided, some communities have all-inclusive
                  plans, others charge extra depending on the level of care
                </PageSectionListItem>
              </PageSectionList>
            </PageSectionDescription>
          </PageSection>

          <PageSection marginPhone="no no lMedium">
            <PageSectionTitle margin="no no sMedium">Search for Assisted Living communities</PageSectionTitle>
            <SearchArea>
              <Search serviceCategory={ASSISTED_LIVING.id} embed />
            </SearchArea>

            {isSearchCompleted && <CommunityList totalCount={totalCount} communities={results} />}
          </PageSection>

          {!!featuredCommunities.length && (
            <PageSection margin="no">
              <PageSectionTitle margin="no no sMedium">Featured Assisted Living Communities</PageSectionTitle>
              <FeaturedCommunityList communities={featuredCommunities} />
            </PageSection>
          )}
        </PageContent>
      </Fragment>
    );
  }
}

SearchPage.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  isSearchCompleted: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  results: PropTypes.array.isRequired,
  featuredCommunities: PropTypes.array.isRequired,
};

export default SearchPage;
