import styled, { css } from 'styled-components';
import { Input } from './Input';

export const selectStyle = `
  appearance: none;
  padding-right: 36px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjQTRBNEI0IiBmaWxsLXJ1bGU9Im5vbnplcm8iPjxwYXRoIGQ9Ik0uNSA0aDdMNCAwek00IDEybDMuNS00aC03eiIvPjwvZz48L3N2Zz4=');
  background-position:  right 8px center;
  background-repeat:  no-repeat;

  &:focus {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjNzE3MTc5IiBmaWxsLXJ1bGU9Im5vbnplcm8iPjxwYXRoIGQ9Ik0uNSA0aDdMNCAwek00IDEybDMuNS00aC03eiIvPjwvZz48L3N2Zz4=');
  }
`;

export const Select = styled(Input.withComponent('select'))`
  ${selectStyle};

  ${props =>
    props.isEmpty &&
    css`
      color: ${props.theme.grayDark};
    `};
`;
