import styled from 'styled-components';
import { media } from '../../../core/styles';

export const AmenityListItem = styled.li`
  margin: 0 0 8px 21px;
  line-height: 22px;
  font-size: 16px;

  ${media.phone`
    line-height: 20px;
    font-size: 14px;
  `};
`;

export const AmenityList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const AmenityTitle = styled.h2`
  margin: 0 0 18px;
  text-transform: uppercase;
  line-height: 26px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 20px;

  ${media.tablet`
    line-height: 24px;
    font-size: 18px;
  `};

  ${media.phone`
    line-height: 22px;
    font-size: 16px;
  `};
`;

export const Amenity = styled.div`
  margin-bottom: 50px;
  break-inside: avoid;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.tablet`
    margin-bottom: 40px;
  `};

  ${media.phone`
    margin-bottom: 30px;
  `};
`;

export const Amenities = styled.div`
  column-count: 2;
  column-gap: 0;

  ${media.tablet`
    column-count: 1;
  `};
`;
