import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as CheckboxContainer, CheckboxCheck, CheckboxInput, CheckboxText } from './styled';

const Checkbox = ({ field, label, size, block, disabled, margin, ...props }) => (
  <CheckboxContainer isDisabled={disabled} block={block} size={size} margin={margin}>
    <CheckboxInput type="checkbox" checked={field.value} {...field} {...props} />
    <CheckboxCheck />
    <CheckboxText>{label}</CheckboxText>
  </CheckboxContainer>
);

Checkbox.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
};

Checkbox.defaultProps = {
  size: undefined,
  block: false,
  disabled: false,
  margin: undefined,
};

export default Checkbox;
