import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { Redirect } from 'react-router-dom';
import { pick } from 'lodash-es';
import { connect } from 'react-redux';
import { getQueryParams } from '../../../core/helpers';
import { Resolver } from '../../../core/components';
import { PageLoading } from '../../../common/components';
import { search, resetSearch } from '../../../common/ducks';
import OwnersPage from './OwnersPage';

class OwnersPageResolver extends PureComponent {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      this.resolve();
    }
  }

  componentWillUnmount() {
    const { resetSearch } = this.props;
    resetSearch();
  }

  resolve = async () => {
    const { location, search, resetSearch } = this.props;
    const { type, term, page = 1 } = getQueryParams(location.search);

    if (term) {
      await search(undefined, type, term, Number(page));
    } else {
      resetSearch();
    }
  };

  areQueryParamsValid = () => {
    const { location } = this.props;
    const { term } = getQueryParams(location.search);

    return term === undefined || !!term;
  };

  render() {
    const successProps = pick(this.props, 'isSearching', 'isSearchCompleted', 'totalCount', 'results', 'location');
    if (!this.areQueryParamsValid()) return <Redirect to="/owners" />;

    return (
      <Resolver
        successComponent={OwnersPage}
        successProps={successProps}
        loadingComponent={PageLoading}
        resolve={this.resolve}
        redirectOnError="/owners"
      />
    );
  }
}
OwnersPageResolver.propTypes = {
  location: RouterPropTypes.location.isRequired,
  search: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isSearching: state.common.search.isSearching,
  isSearchCompleted: state.common.search.isSearchCompleted,
  totalCount: state.common.search.totalCount,
  results: state.common.search.results,
});

const mapDispatchToProps = {
  search,
  resetSearch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OwnersPageResolver);
