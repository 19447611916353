import { ALIGN_LEFT, ALIGN_CENTER, ALIGN_RIGHT } from '../constants';

const ALIGNMENT_MAP = {
  [ALIGN_LEFT]: 'flex-start',
  [ALIGN_CENTER]: 'center',
  [ALIGN_RIGHT]: 'flex-end',
};

const alignMapper = (props, defaultAlignment = ALIGN_LEFT) => ALIGNMENT_MAP[props.align] || defaultAlignment;

export default alignMapper;
