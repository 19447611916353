import React from 'react';
import {
  Footer as FooterContainer,
  FooterContent,
  FooterCopyright,
  FooterLinks,
  FooterTextLinks,
  FooterTextLink,
  FooterIconLinks,
  FooterIconLink,
  FooterIconLinkIcon,
} from './styled';
import { getBackofficeBaseURL } from '../../core/services/environment';

const Footer = () => (
  <FooterContainer>
    <FooterContent>
      <FooterCopyright>© 2019 Loved Ones First, LLC.</FooterCopyright>
      <FooterLinks>
        <FooterTextLinks>
          <FooterTextLink to="/owners">Claim community</FooterTextLink>
          <FooterTextLink as="a" href={getBackofficeBaseURL()}>
            Manage community
          </FooterTextLink>
          <FooterTextLink to="/terms-of-use">Terms of Use</FooterTextLink>
          <FooterTextLink to="/privacy-policy">Privacy Policy</FooterTextLink>
        </FooterTextLinks>

        <FooterIconLinks>
          <FooterIconLink href="https://www.facebook.com/LovedOnesFirst" target="blank">
            <FooterIconLinkIcon icon="facebook" />
          </FooterIconLink>

          <FooterIconLink href="https://twitter.com/LovedOnesFirst" target="blank">
            <FooterIconLinkIcon icon="twitter" />
          </FooterIconLink>

          <FooterIconLink href="https://www.linkedin.com/company/loved-ones-first-llc/about" target="blank">
            <FooterIconLinkIcon icon="linkedin" />
          </FooterIconLink>
        </FooterIconLinks>
      </FooterLinks>
    </FooterContent>
  </FooterContainer>
);

export default Footer;
