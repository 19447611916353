import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { history, createSuccessNotification } from '../../../core/services';
import {
  PageMeta,
  PageContent,
  PageSection,
  PageSubSection,
  PageTitle,
  PageMetaContent,
} from '../../../common/components/styled';
import { ContactDisclosure } from '../styled';
import { Link, Grid, GridColumn } from '../../../core/components/styled';
import { DocumentTitle, PageLoadingOverlay } from '../../../common/components';
import { contactAnna } from '../../ducks';
import { contactAnnaSchema } from '../../schemas';
import { ContactAnnaForm } from '../forms';

const formInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  relationshipToResident: '',
  residentFirstName: '',
  residentLastName: '',
  state: '',
  city: '',
  zipCode: '',
  communityType: '',
  paymentType: '',
  bestTimeToContact: '',
  howSoonForPlacement: '',
};

class ContactAnnaPage extends PureComponent {
  handleSubmit = async values => {
    const { contactAnna } = this.props;
    await contactAnna(values);
    createSuccessNotification('Thank you for getting in touch. Anna will reach out to you shortly.');
    history.push('/');
  };

  render() {
    const { isContacting } = this.props;

    return (
      <Fragment>
        <DocumentTitle>Contact Anna Ratiu</DocumentTitle>
        <PageMeta>
          <PageMetaContent>
            <PageTitle>Contact Anna Ratiu</PageTitle>
          </PageMetaContent>
        </PageMeta>

        <PageContent>
          <PageSection>
            <PageSubSection>After submitting this form, Anna will respond with personalized help.</PageSubSection>
            <Grid multiLine margin="no no medium">
              <GridColumn size="6/12" sizeTablet="12/12" padding="no medium no no" paddingTablet="no">
                <Formik
                  onSubmit={this.handleSubmit}
                  render={ContactAnnaForm}
                  initialValues={formInitialValues}
                  validationSchema={contactAnnaSchema}
                />
              </GridColumn>
            </Grid>
          </PageSection>

          <PageSection>
            <ContactDisclosure>
              <p>
                LovedOnesFirst.com maintains your privacy (view our {' '}
                <Link to="/privacy-policy" target="_blank">
                  privacy policy
                </Link>
                ). LovedOnesFirst.com does not distribute your information to any outside parties without your
                permission.
              </p>
              <p>
                The information on this page is aggregated from third-party sources and presented as-is for your
                convenience. It has not been verified or approved by the developer or homeowner’s association.
                LovedOnesFirst.com does not guarantee the accuracy or completeness of information or assume any
                liability for its use. LovedOnesFirst.com is not affiliated with the builder, developer, or HOA of any
                communities displayed on this website.
              </p>
              <p>
                The information you submit is distributed to a LovedOnesFirst.com Partner Agent, who is a licensed real
                estate agent. Please verify all information prior to purchasing. LovedOnesFirst.com is not responsible
                for any errors regarding the information displayed on this website.
              </p>
            </ContactDisclosure>
          </PageSection>
        </PageContent>
        {isContacting && <PageLoadingOverlay />}
      </Fragment>
    );
  }
}

ContactAnnaPage.propTypes = {
  isContacting: PropTypes.bool.isRequired,
  contactAnna: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isContacting: state.resources.contactAnna.isContacting,
});

const mapDispatchToProps = { contactAnna };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactAnnaPage);
