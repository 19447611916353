import styled, { css } from 'styled-components';
import { sizeMapper, alignMapper } from '../../styles';

const ButtonSet = styled.div`
  display: flex;
  justify-content: center;
  margin: ${props => sizeMapper(props.margin, 'medium no no')};

  ${props =>
    props.align &&
    css`
      justify-content: ${alignMapper(props)};
    `};
`;

export default ButtonSet;
