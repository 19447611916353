import produce from 'immer';
import { assign } from 'lodash-es';
import { search as doSearch } from '../services';

// Actions
const START_SEARCH = 'common/SEARCH/START_SEARCH';
const COMPLETE_SEARCH = 'common/SEARCH/COMPLETE_SEARCH';
const FAIL_SEARCH = 'common/SEARCH/FAIL_SEARCH';
const RESET = 'common/SEARCH/RESET';

// Initial state
const initialState = {
  isSearching: false,
  isSearchCompleted: false,
  isSearchFailed: false,
  totalCount: 0,
  results: [],
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_SEARCH:
      return produce(state, nextState => {
        nextState.isSearching = true;
      });

    case COMPLETE_SEARCH:
      return produce(state, nextState => {
        nextState.isSearching = false;
        nextState.isSearchCompleted = true;
        nextState.isSearchFailed = false;
        nextState.totalCount = action.totalCount;
        nextState.results = action.results;
      });

    case FAIL_SEARCH:
      return produce(state, nextState => {
        nextState.isSearching = false;
        nextState.isSearchCompleted = false;
        nextState.isSearchFailed = true;
        nextState.totalCount = 0;
        nextState.results = [];
      });

    case RESET:
      return produce(state, nextState => {
        assign(nextState, initialState);
      });

    default:
      return state;
  }
};

// Action creators
const startSearch = () => ({
  type: START_SEARCH,
});

const completeSearch = (totalCount, results) => ({
  type: COMPLETE_SEARCH,
  totalCount,
  results,
});

const failSearch = () => ({
  type: FAIL_SEARCH,
});

export const search = (category, type, term, page) => dispatch => {
  dispatch(startSearch());

  const searchPromise = doSearch(category, type, term, page);
  searchPromise
    .then(({ totalCount, results }) => dispatch(completeSearch(totalCount, results)))
    .catch(() => dispatch(failSearch()));

  return searchPromise;
};

export const resetSearch = () => ({
  type: RESET,
});
