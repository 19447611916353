import React from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Link } from 'react-router-dom';
import { Icon } from '..';
import { mapper, sizeMapper, colorMapper } from '../../styles';

export const bareButtonStyle = `
  position: relative;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: center;
  color: inherit;
  user-select: none;
`;

export const ButtonIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin: ${props => sizeMapper(props.margin, 'no no small no')};
  vertical-align: top;
`;

export const Button = styled.button`
  ${bareButtonStyle};
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => mapper(props.size, { small: '14px 16px', medium: '14px 20px', large: '14px 24px' })};
  background-color: ${colorMapper};
  border-radius: 6px;
  text-transform: capitalize;
  letter-spacing: 0.4px;
  font-size: ${props => mapper(props.size, { small: '12px', medium: '14px', large: '18px' })};
  color: #fff;

  &:active {
    background-color: ${props => darken(0.05, colorMapper(props))};
  }

  ${props =>
    props.isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};

  ${props =>
    props.fluid &&
    css`
      flex: 1;
    `};

  ${props =>
    props.text &&
    css`
      padding: 8px 0;
      background-color: transparent;
      color: ${colorMapper};

      &:active {
        background-color: transparent;
        color: ${props => darken(0.05, colorMapper(props))};
      }
    `};
`;

export const ButtonLink = Button.withComponent(({ margin, noPadding, color, fluid, line, text, ...props }) => (
  <Link {...props} />
));
