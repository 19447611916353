import { combineReducers } from 'redux';
import { reducer as searchReducer } from './search';
import { reducer as featuredCommunitiesReducer } from './featuredCommunities';

export { search, resetSearch } from './search';
export {
  loadFeaturedCommunities,
  resetFeaturedCommunities,
  getFeaturedIndependentLivingCommunities,
  getFeaturedAssistedLivingCommunities,
  getFeaturedMemoryCareCommunities,
} from './featuredCommunities';

export const reducer = combineReducers({
  search: searchReducer,
  featuredCommunities: featuredCommunitiesReducer,
});
